import React, { useState } from 'react'
import { FiatCurrency } from '../../../static'
import Confirm from './Confirm'
import Details from './Details'

interface BillPayProps {
  close: () => void
  handleBillpay: () => void
  amount: string
  fiatCurrency: FiatCurrency
}

const BillPay: React.FC<BillPayProps> = ({ close, handleBillpay, amount, fiatCurrency }: BillPayProps) => {
  const [modals, setModals] = useState({
    details: true,
    confirm: false
  })

  const handleClose = (modal?: string) => {
    if (modal) {
      setModals((s) => ({ ...s, [modal]: false }))
      return
    }
    setModals({ details: true, confirm: false })
    close()
  }

  const handleOpen = (modal: string) => {
    setModals((s) => ({ ...s, [modal]: true }))
  }

  const handleDetails = () => {
    handleClose('details')
    handleOpen('confirm')
  }

  const handleConfirm = () => {
    handleClose()
    handleBillpay()
  }

  return (
    <>
      <Details
        open={modals.details}
        onClose={() => handleClose()}
        handleDetails={handleDetails}
        amount={amount}
        fiatCurrency={fiatCurrency}
      />
      <Confirm open={modals.confirm} onClose={() => handleClose()} handleConfirm={handleConfirm} />
    </>
  )
}

export default BillPay
