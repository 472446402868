import React, { useState, useEffect } from 'react'
import { BankingCountry, ChainAsset, FiatCurrency, RequestType } from 'src/common/static'
import Confirm from './Confirm'
import Details from './Details'
import { getProfile } from 'src/common/api'
import { alertNotification } from 'src/ui'
import { BankDetailsModel } from 'src/common/api/contacts'

interface EftProps {
  close: () => void
  handleEft: (selectedBank: BankDetailsModel) => void
  amount: string
  type: RequestType
  asset: ChainAsset
  fiatCurrency: FiatCurrency
}

const Eft: React.FC<EftProps> = ({ close, handleEft, amount, type, asset, fiatCurrency }: EftProps) => {
  const [modals, setModals] = useState({
    details: true,
    confirm: false
  })
  const [bankDetails, setBankDetails] = useState<BankDetailsModel[]>([])
  const [selectedBank, setSelectedBank] = useState<BankDetailsModel | undefined>(undefined)
  const [loading, setLoading] = useState(true)
  const bankCountry = fiatCurrency === FiatCurrency.CAD ? BankingCountry.CAN : BankingCountry.US

  const handleClose = (modal?: string) => {
    if (modal) {
      setModals((s) => ({ ...s, [modal]: false }))
      return
    }
    setModals({ details: true, confirm: false })
    close()
  }

  const handleOpen = (modal: string) => {
    setModals((s) => ({ ...s, [modal]: true }))
  }

  const handleDetails = (selectedBank: BankDetailsModel) => {
    setSelectedBank(selectedBank)
    handleClose('details')
    handleOpen('confirm')
  }

  const handleConfirm = () => {
    if (selectedBank) {
      handleClose()
      handleEft(selectedBank)
    }
  }

  const getDetails = async () => {
    try {
      const response = await getProfile()
      const details = response.data.bankDetails.filter((bank) => bank.country === bankCountry)
      if (details.length) {
        setBankDetails(details)
        handleClose('details')
        handleOpen('confirm')
      }
      setLoading(false)
    } catch (err) {
      alertNotification('Fetching banking details failed. Please contact support.', 'error')
    }
  }

  useEffect(() => {
    getDetails()
  }, [])

  return (
    <>
      {!loading && (
        <Details
          open={modals.details}
          onClose={() => handleClose()}
          handleDetails={handleDetails}
          type={type}
          bankCountry={bankCountry}
        />
      )}
      <Confirm
        open={modals.confirm && Boolean(bankDetails.length || selectedBank)}
        onClose={() => handleClose()}
        handleConfirm={handleConfirm}
        bankDetails={bankDetails}
        selectedBank={selectedBank}
        setSelectedBank={setSelectedBank}
        amount={amount}
        type={type}
        asset={asset}
        fiatCurrency={fiatCurrency}
      />
    </>
  )
}

export default Eft
