import { Avatar, Button, IconButton, Link, LinkProps, Toolbar } from '@mui/material'
import { Box, styled, experimental_sx as sx, CSSObject } from '@mui/system'

export const NavToolbar = styled(Toolbar)({
  height: '75px'
})

export const LogoContainer = styled(Link)(({ theme }) => ({
  display: 'inline-flex',
  width: '126px',
  height: '100%',
  marginRight: '21px',
  [theme.breakpoints.down('md')]: {
    width: '24px',
    marginRight: '3%'
  }
}))

export const NavMenuContainer = styled(Box)({
  flexGrow: 1
})

export const NavLinksContainer = styled(Box)({
  flexGrow: 1,
  alignItems: 'center',
  height: '100%'
})

const linkBefore: CSSObject = {
  content: "''",
  position: 'absolute',
  bottom: 0,
  height: '4px',
  left: 0,
  right: 0,
  backgroundColor: 'primary.main'
}

export interface NavLinkProps extends LinkProps {
  active?: boolean
  component: string | undefined
}

export const NavLink = styled(Link, { shouldForwardProp: (prop) => prop !== 'active' })<NavLinkProps>(({ active }) =>
  sx({
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'text.primary',
    height: '100%',
    px: '21px',
    boxSizing: 'content-box',
    ':hover, :active': {
      fontWeight: 600
    },
    '::before': {
      ...linkBefore,
      opacity: 0,
      transition: 'opacity .05s'
    },
    ':active': {
      '::before': {
        opacity: 1
      }
    },
    ...(active && {
      fontWeight: 600,
      '::before': {
        ...linkBefore,
        opacity: 1
      }
    })
  })
)

export const AvatarIconButton = styled(IconButton)({
  padding: 0
})

export const AvatarInitials = styled(Avatar)(({ theme }) => ({
  backgroundColor: theme.palette.primary[99],
  color: theme.palette.primary.main,
  fontSize: '1rem',
  ':hover': {
    backgroundColor: theme.palette.primary[98]
  }
}))

export const AuthButton = styled(Button)(({ theme }) => ({
  fontSize: '1rem',
  ':nth-of-type(2)': {
    marginRight: '16px',
    [theme.breakpoints.down('sm')]: {
      marginRight: '4px'
    }
  }
}))
