import { SvgIcon, SvgIconProps } from '@mui/material'
import React from 'react'

export const USDCIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 6.10352e-05C15.523 6.10352e-05 20 4.47711 20 10.0001C20 15.5226 15.523 20.0001 10 20.0001C4.47705 20.0001 0 15.5226 0 10.0001C0 4.47711 4.47705 6.10352e-05 10 6.10352e-05Z"
        fill="#2775CA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.12557 16.9527C8.12557 17.188 7.94136 17.3216 7.71386 17.2506C4.69033 16.286 2.49817 13.4509 2.49817 10.1057C2.49817 6.76057 4.69033 3.92533 7.71386 2.96079C7.94136 2.89022 8.12557 3.0234 8.12557 3.25863V3.83931C8.12557 3.9959 8.0042 4.18022 7.855 4.23124C5.46283 5.10965 3.74919 7.40773 3.74919 10.1017C3.74919 12.7957 5.46283 15.0942 7.855 15.9722C8.0042 16.0272 8.12557 16.2074 8.12557 16.3646V16.9527Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6234 14.7919C10.6234 14.9646 10.4823 15.1057 10.3098 15.1057H9.68613C9.51363 15.1057 9.37249 14.9646 9.37249 14.7919V13.8038C8.00772 13.6196 7.34124 12.8547 7.16078 11.8156C7.12931 11.6389 7.27044 11.4782 7.4509 11.4782H8.16465C8.31363 11.4782 8.43919 11.5843 8.47056 11.733C8.60397 12.3527 8.96079 12.8272 10.0548 12.8272C10.8626 12.8272 11.4353 12.3762 11.4353 11.702C11.4353 11.0272 11.0979 10.7724 9.91363 10.5762C8.16465 10.341 7.33726 9.81141 7.33726 8.4389C7.33726 7.38015 8.13738 6.55674 9.37647 6.38015V5.41151C9.37647 5.2389 9.51772 5.09765 9.69011 5.09765H10.3137C10.4862 5.09765 10.6274 5.2389 10.6274 5.41151V6.40765C11.6352 6.58833 12.2744 7.16049 12.4824 8.10947C12.5215 8.29015 12.3803 8.45845 12.1961 8.45845H11.5372C11.396 8.45845 11.2784 8.36425 11.2353 8.22709C11.0589 7.62345 10.6274 7.3647 9.87829 7.3647C9.0509 7.3647 8.6234 7.76459 8.6234 8.32527C8.6234 8.91743 8.8667 9.21527 10.1374 9.39993C11.8548 9.63516 12.7412 10.1253 12.7412 11.5883C12.7412 12.698 11.9176 13.5961 10.6274 13.8038V14.7919H10.6234Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2863 17.2506C12.0591 17.3216 11.8746 17.188 11.8746 16.9528V16.3725C11.8746 16.1999 11.9805 16.0392 12.1452 15.9801C14.5374 15.1017 16.251 12.8037 16.251 10.1097C16.251 7.41569 14.5374 5.11762 12.1452 4.23921C11.9962 4.18421 11.8746 4.00398 11.8746 3.84682V3.26659C11.8746 3.03137 12.0591 2.89421 12.2863 2.9683C15.3099 3.93341 17.502 6.76853 17.502 10.1137C17.4983 13.4508 15.3099 16.2821 12.2863 17.2506Z"
        fill="white"
      />
    </SvgIcon>
  )
}
