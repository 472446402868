import { SvgIcon, SvgIconProps } from '@mui/material'
import React from 'react'

export const ConnectWalletIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="24" height="24" viewBox="0 0 19 18" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.25 4.67333V2.58333C17.25 1.575 16.425 0.75 15.4167 0.75H2.58333C1.56583 0.75 0.75 1.575 0.75 2.58333V15.4167C0.75 16.425 1.56583 17.25 2.58333 17.25H15.4167C16.425 17.25 17.25 16.425 17.25 15.4167V13.3267C17.7908 13.0058 18.1667 12.4283 18.1667 11.75V6.25C18.1667 5.57167 17.7908 4.99417 17.25 4.67333ZM16.3333 6.25V11.75H9.91667V6.25H16.3333ZM2.58333 15.4167V2.58333H15.4167V4.41667H9.91667C8.90834 4.41667 8.08334 5.24167 8.08334 6.25V11.75C8.08334 12.7583 8.90834 13.5833 9.91667 13.5833H15.4167V15.4167H2.58333Z" />
    </SvgIcon>
  )
}
