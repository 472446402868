import {
  Box,
  CircularProgress,
  Typography,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Divider
} from '@mui/material'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import React, { useEffect, useState } from 'react'
import * as S from './styles'
import { FlagIcon, NewContactIcon, USFlagIcon } from 'src/common/assets'
import { alertNotification, Modal } from 'src/ui'
import Contact from 'src/common/components/Contact'
import {
  BankDetailsModel,
  BlockchainDetailsModel,
  ContactDetailsModel,
  ContactModel,
  getContacts,
  deleteContact
} from '../../common/api/contacts'
import NewContact from '../../common/components/Contact/NewContact'
import { LoadingButton } from '@mui/lab'
import { DeleteContactIcon } from 'src/common/assets/DeleteContactIcon'

interface BankColumnProps {
  primaryBank: BankDetailsModel | undefined
}

const AddressBook: React.FC = () => {
  const [loading, setLoading] = useState(true)
  const [contacts, setContacts] = useState<(ContactDetailsModel | null)[]>([])
  const [selectedContact, setSelectedContact] = useState<ContactModel>()
  const [selectedContactBank, setSelectedContactBank] = useState<BankDetailsModel[]>()
  const [selectedContactBlockchain, setSelectedContactBlockchain] = useState<BlockchainDetailsModel[]>()

  const [modals, setModals] = useState({
    contactOpen: false,
    newContactOpen: false,
    deleteContactOpen: false
  })

  const handleOpen = (modal: string) => {
    setModals((s) => ({ ...s, [modal]: true }))
  }

  const handleClose = (modal: string) => {
    setModals((s) => ({ ...s, [modal]: false }))
  }

  const handleContactOpen = (
    contact: ContactModel,
    bankDetails: BankDetailsModel[],
    blockchainDetails: BlockchainDetailsModel[]
  ) => {
    setSelectedContact(contact)
    setSelectedContactBank(bankDetails)
    setSelectedContactBlockchain(blockchainDetails)
    handleOpen('contactOpen')
  }

  const handleNewContactOpen = () => {
    handleOpen('newContactOpen')
  }

  const handleDeleteContactOpen = (contact: ContactModel) => {
    setSelectedContact(contact)
    handleOpen('deleteContactOpen')
  }

  const handleCloseDeleteContact = () => {
    handleClose('deleteContactOpen')
  }

  const fetchContacts = async () => {
    try {
      setLoading(true)
      const response = await getContacts()
      setContacts(response.data)
      setLoading(false)
    } catch (err) {
      alertNotification('Fetching contacts failed. Please contact support.', 'error')
    }
  }

  const removeContact = async () => {
    try {
      if (!selectedContact) {
        throw new Error(`No contact selected`)
      }
      setLoading(true)
      const contactId = selectedContact.id
      const response = await deleteContact({ contactId: contactId })
      setContacts(contacts.filter((i) => i && i.contactDetails.id !== contactId))
      setLoading(false)
      handleCloseDeleteContact()
      if (response) {
        alertNotification('Recipient removed successfully.', 'success')
      }
    } catch {
      alertNotification('Deleting contact failed. Please contact support.', 'error')
    }
  }

  useEffect(() => {
    fetchContacts()
  }, [])

  useEffect(() => {
    fetchContacts()
  }, [modals.newContactOpen, modals.contactOpen])

  const RenderBankColumn = ({ primaryBank }: BankColumnProps) => {
    return (
      <ListItem sx={{ paddingLeft: '0px', paddingRight: '4px' }}>
        <ListItemIcon sx={{ minWidth: '30px' }}>
          {primaryBank && primaryBank.country === 'CAN' ? (
            <FlagIcon fontSize="small" />
          ) : (
            <USFlagIcon fontSize="small" />
          )}
        </ListItemIcon>
        <ListItemText>
          {primaryBank && primaryBank.name} ****
          {primaryBank && primaryBank.accountNum.substring(primaryBank.accountNum.length - 4)}
        </ListItemText>
      </ListItem>
    )
  }

  return (
    <S.StyledContainer maxWidth="xl" fixed>
      <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
        <Typography variant="h5" p="0 20px" mb="24px">
          Address Book
        </Typography>
        <S.StyledButton variant="outlined" startIcon={<NewContactIcon />} onClick={handleNewContactOpen}>
          Add a new recipient
        </S.StyledButton>
      </Stack>
      <Divider sx={{ marginBottom: '24px' }} />
      <S.StyledPaper variant="card">
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Company</TableCell>
                <TableCell>Primary Bank</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!loading &&
                Boolean(contacts.length) &&
                contacts.map(
                  (contact, idx) =>
                    contact && (
                      <TableRow key={idx} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell component="th" scope="row">
                          {contact.contactDetails.firstName} {contact.contactDetails.lastName}
                        </TableCell>
                        <TableCell>{contact.contactDetails.companyTitle}</TableCell>
                        <TableCell>
                          {contact.bankDetails.find((primaryBank) => primaryBank.isPrimary) && (
                            <RenderBankColumn
                              primaryBank={contact.bankDetails.find((primaryBank) => primaryBank.isPrimary)}
                            />
                          )}
                        </TableCell>
                        <TableCell align="right">
                          <S.StyledButton
                            variant="outlined"
                            size="small"
                            sx={{ width: '137px' }}
                            onClick={() =>
                              handleContactOpen(contact.contactDetails, contact.bankDetails, contact.blockchainDetails)
                            }
                          >
                            View Details
                          </S.StyledButton>
                        </TableCell>
                        <TableCell>
                          <IconButton
                            aria-label="delete"
                            onClick={() => handleDeleteContactOpen(contact.contactDetails)}
                          >
                            <DeleteOutlineOutlinedIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    )
                )}
            </TableBody>
          </Table>
        </TableContainer>
        {!(loading || Boolean(contacts.length)) && (
          <Box display="flex" justifyContent="center" mt={4}>
            <Typography variant="h6">No Recipients</Typography>
          </Box>
        )}
        {loading && (
          <Box display="flex" justifyContent="center" mt={4}>
            <CircularProgress disableShrink />
          </Box>
        )}
        <Modal
          open={modals.contactOpen}
          onClose={() => handleClose('contactOpen')}
          title="Beneficiary Details"
          maxWidth="lg"
          fullWidth
        >
          {selectedContact && selectedContactBank && selectedContactBlockchain && (
            <Contact
              contact={selectedContact}
              bankDetails={selectedContactBank}
              blockchainDetails={selectedContactBlockchain}
            />
          )}
        </Modal>
        <Modal
          open={modals.newContactOpen}
          onClose={() => handleClose('newContactOpen')}
          title="Add a New Recipient to Address Book"
          maxWidth="lg"
          fullWidth
          keepMounted
        >
          <NewContact handleClose={() => handleClose('newContactOpen')} />
        </Modal>
        <Modal
          open={modals.deleteContactOpen}
          onClose={handleCloseDeleteContact}
          title="Delete Recipient from Address Book"
          maxWidth="xs"
          fullWidth
          keepMounted
        >
          <Stack spacing={3}>
            <Box display="flex" justifyContent="center">
              <DeleteContactIcon sx={{ fontSize: '91px' }} />
            </Box>
            <Typography variant="subtitle2" textAlign="center">
              You are removing this Recipient from your address book. Once removed you will lose all associated contact
              details for this recipient. Please confirm by clicking &apos;Remove Recipient&apos;.
            </Typography>
            <LoadingButton variant="contained" loading={loading} size="large" onClick={removeContact}>
              Remove Recipient
            </LoadingButton>
            <LoadingButton loading={loading} size="large" onClick={handleCloseDeleteContact}>
              Cancel
            </LoadingButton>
          </Stack>
        </Modal>
      </S.StyledPaper>
    </S.StyledContainer>
  )
}

export default AddressBook
