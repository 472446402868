export enum OrderStatus {
  NEW = 'NEW',
  INITIATED = 'INITIATED',
  PROCESSED = 'PROCESSED',
  CANCELLED = 'CANCELLED',
  QUEUED = 'INITIATED',
  EXPIRED = 'EXPIRED',
  DECLINED = 'DECLINED',
  FAILED = 'FAILED'
}
