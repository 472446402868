import { NavigateNext } from '@mui/icons-material'
import {
  Button,
  Checkbox,
  DialogProps,
  Link,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  Typography
} from '@mui/material'
import React, { useState } from 'react'
import { BankDetailsModel } from 'src/common/api/contacts'
import { AddBankIcon, FlagIcon, USFlagIcon } from 'src/common/assets'
import { BankingCountry, ChainAsset, FiatCurrency, RequestType } from 'src/common/static'
import { Modal, SummaryTable } from 'src/ui'

interface ConfirmProps extends DialogProps {
  handleConfirm: () => void
  bankDetails: BankDetailsModel[]
  selectedBank?: BankDetailsModel
  setSelectedBank: React.Dispatch<React.SetStateAction<BankDetailsModel | undefined>>
  amount: string
  type: RequestType
  asset: ChainAsset
  fiatCurrency: FiatCurrency
}

const Confirm: React.FC<ConfirmProps> = ({
  handleConfirm,
  bankDetails,
  selectedBank,
  setSelectedBank,
  amount,
  type,
  asset,
  fiatCurrency,
  ...props
}: ConfirmProps) => {
  const [checked, setChecked] = useState(false)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked)
  }

  const isPurchase = type === RequestType.PURCHASE
  const isCanadaBank = selectedBank?.country === BankingCountry.CAN

  const displayDetails = () => {
    const details = [
      {
        key: 'Account Holder Name:',
        value: selectedBank?.accHolderName
      },
      {
        key: 'Bank Name:',
        value: selectedBank?.name
      },
      {
        key: isCanadaBank ? 'Institution Number:' : 'ACH Code:',
        value: isCanadaBank ? selectedBank?.routingNum.substring(0, 3) : selectedBank?.achCode
      },
      {
        key: isCanadaBank ? 'Transit Number:' : 'SWIFT/BIC Code:',
        value: isCanadaBank ? selectedBank?.routingNum.substring(4) : selectedBank?.swiftBicCode
      },
      {
        key: 'Account Number:',
        value: selectedBank?.accountNum
      },
      {
        key: `${isPurchase ? 'Purchase' : 'Redeem'} Amount:`,
        value: `$${amount} ${fiatCurrency}`
      },
      {
        key: 'Description:',
        value: `${isPurchase ? 'Purchase' : 'Redeem'} of ${asset}`
      }
    ]

    return (
      <SummaryTable
        data={details.map((detail) => ({
          key: <Typography variant="h6">{detail.key}</Typography>,
          value: <Typography>{detail.value || 'Pending'}</Typography>
        }))}
        padding="16px 0"
      />
    )
  }

  const displayBanks = () => {
    return (
      <Stack>
        {bankDetails.map((bank) => (
          <ListItem key={bank.id}>
            <ListItemButton onClick={() => setSelectedBank(bank)}>
              {bank.country === BankingCountry.CAN ? <FlagIcon fontSize="small" /> : <USFlagIcon fontSize="small" />}
              <ListItemText
                sx={{ ml: 1 }}
                primary={`${bank.name} ****${bank.accountNum.substring(bank.accountNum.length - 4)}`}
              />
              <NavigateNext />
            </ListItemButton>
          </ListItem>
        ))}
        <ListItem>
          <Link href="/profile" sx={{ width: '100%', textDecoration: 'none', color: '#000' }}>
            <ListItemButton>
              <AddBankIcon />
              <ListItemText sx={{ ml: 1 }} primary="Add another Bank Account" />
              <NavigateNext />
            </ListItemButton>
          </Link>
        </ListItem>
      </Stack>
    )
  }

  return (
    <Modal {...props} title={selectedBank ? 'Confirm Banking Details' : 'Choose Bank Account'}>
      <Stack spacing={3}>
        {selectedBank && displayDetails()}
        {!selectedBank && bankDetails.length && displayBanks()}
        <Stack direction="row" spacing={3} alignItems="flex-start">
          <Typography variant="subtitle1" fontWeight={700}>
            Please update your banking details from your <Link href="/profile">Profile</Link> if incorrect.
          </Typography>
        </Stack>
        {selectedBank && (
          <Stack direction="row" spacing={3} alignItems="flex-start">
            <Checkbox checked={checked} onChange={handleChange} />
            <Typography variant="subtitle2">
              By clicking the checkbox you agree to the{' '}
              <Link component="a" rel="noopener" target="_blank" href="https://apaylo.com/terms-of-use/">
                Apaylo terms & conditions
              </Link>{' '}
              to {isPurchase ? 'withdraw' : 'deposit'} the inputted amount {isPurchase ? 'to' : 'from'} Stablecorp.
            </Typography>
          </Stack>
        )}
        {selectedBank && (
          <Button variant="contained" disabled={!checked} size="large" onClick={handleConfirm} fullWidth>
            Next
          </Button>
        )}
      </Stack>
    </Modal>
  )
}

export default Confirm
