import { SvgIcon, SvgIconProps } from '@mui/material'
import React from 'react'

export const DollarIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.98953 18V16.3037C3.04712 16.0524 2.25654 15.6492 1.6178 15.0942C0.979057 14.5393 0.439791 13.7801 0 12.8168L1.60209 12.2827C1.8534 13.0785 2.29843 13.7068 2.93717 14.1675C3.57592 14.6283 4.30366 14.8586 5.12042 14.8586C5.95811 14.8586 6.65445 14.6545 7.20942 14.2461C7.7644 13.8377 8.04188 13.2984 8.04188 12.6283C8.04188 11.9372 7.7801 11.356 7.25654 10.8848C6.73298 10.4136 5.76963 9.88482 4.36649 9.29843C3.10995 8.77487 2.16754 8.20942 1.53927 7.60209C0.910994 6.99476 0.596858 6.17801 0.596858 5.15183C0.596858 4.23037 0.910994 3.44503 1.53927 2.79581C2.16754 2.1466 3.00523 1.74869 4.05235 1.60209V0H5.7801V1.60209C6.57591 1.68586 7.26701 1.93717 7.8534 2.35602C8.43979 2.77487 8.91099 3.35079 9.26701 4.08377L7.75916 4.80628C7.44502 4.2199 7.05759 3.78011 6.59686 3.48691C6.13613 3.19372 5.59162 3.04712 4.96335 3.04712C4.1466 3.04712 3.50262 3.2356 3.03141 3.61257C2.56021 3.98953 2.32461 4.50262 2.32461 5.15183C2.32461 5.82199 2.59686 6.35602 3.14136 6.75393C3.68586 7.15183 4.56544 7.6021 5.7801 8.10471C7.22513 8.71204 8.25131 9.35079 8.85864 10.0209C9.46597 10.6911 9.76963 11.5602 9.76963 12.6283C9.76963 13.1518 9.67539 13.6335 9.48691 14.0733C9.29843 14.5131 9.03141 14.8901 8.68586 15.2042C8.34031 15.5183 7.91623 15.7749 7.41361 15.9738C6.91099 16.1728 6.34555 16.3037 5.71728 16.3665V18H3.98953Z"
        fill="#4F4F4F"
      />
    </SvgIcon>
  )
}
