import { useEffect, useState } from 'react'
import { ChainName, WalletCustody, WalletType } from 'src/common/static'
import { alertDialog, alertNotification } from 'src/ui'
import { useAuth } from '..'
import { getWallets, WalletModel } from '../../api'

interface ConnectedWallets {
  metamask: {
    chain: ChainName.ETHEREUM
    address: string | null
  }
  myalgo: {
    chain: ChainName.ALGORAND
    address: string | null
  }
  custodial: {
    chain: ChainName.ETHEREUM
    address: string | null
  }
}

export interface WalletContext {
  loading: boolean
  wallets: WalletModel[]
  connectedWallets: ConnectedWallets
  selectedWallet: {
    chain: ChainName
    address: string | null
    walletType: WalletType
  }
  walletType: WalletType
  connectWallet: (walletType: WalletType, chain: ChainName, address: string | null) => void
  setWalletType: React.Dispatch<React.SetStateAction<WalletType>>
  fetchWallets: () => Promise<void>
  setIsSignMetamaskOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const initialConnectedWalletsState: ConnectedWallets = {
  metamask: {
    chain: ChainName.ETHEREUM,
    address: null
  },
  myalgo: {
    chain: ChainName.ALGORAND,
    address: null
  },
  custodial: {
    chain: ChainName.ETHEREUM,
    address: null
  }
}

const useProvideWallet = (): WalletContext => {
  const { user } = useAuth()
  const [walletsLoading, setWalletsLoading] = useState(true)
  const [wallets, setWallets] = useState<WalletModel[]>([])
  const [connectedWallets, setConnectedWallets] = useState<ConnectedWallets>({
    metamask: {
      chain: ChainName.ETHEREUM,
      address: null
    },
    myalgo: {
      chain: ChainName.ALGORAND,
      address: null
    },
    custodial: {
      chain: ChainName.ETHEREUM,
      address: null
    }
  })
  const [walletType, setWalletType] = useState<WalletType>(
    (localStorage.getItem('selectedWallet') as WalletType) || WalletType.METAMASK
  )
  const [isSignMetamaskOpen, setIsSignMetamaskOpen] = useState(false)

  const checkSignMetamaskPopup = (e: BeforeUnloadEvent) => {
    if (isSignMetamaskOpen) {
      e.preventDefault()
      alertDialog(
        'Metamask sign transaction is still open',
        'Please cancel or sign the transaction before closing the tab'
      )
      e.returnValue = 'metamask sign popup is open'
    }
  }

  useEffect(() => {
    window.addEventListener('beforeunload', checkSignMetamaskPopup)
    return () => {
      window.removeEventListener('beforeunload', checkSignMetamaskPopup)
    }
  }, [isSignMetamaskOpen])

  useEffect(() => {
    setWallets(wallets)
    setConnectedWallets(initialConnectedWalletsState)
    if (user) {
      fetchWallets()
    }
    setWalletsLoading(false)
  }, [user])

  useEffect(() => {
    if (
      walletType === WalletType.CUSTODIAL &&
      !walletsLoading &&
      !wallets.some((wallet) => wallet.type === WalletCustody.CUSTODIAL)
    ) {
      setWalletType(WalletType.METAMASK)
      localStorage.setItem('selectedWallet', WalletType.METAMASK)
    }
  }, [wallets])

  const fetchWallets = async () => {
    try {
      const response = await getWallets()
      setWallets(response.data)
      setWalletsLoading(false)
    } catch (err) {
      alertNotification('Fetching wallets failed. Please contact support.', 'error')
      setWallets(wallets)
      setWalletsLoading(false)
    }
  }

  const connectWallet = (walletType: WalletType, chain: ChainName, address: string | null) => {
    setConnectedWallets((s) => ({ ...s, [walletType]: { chain, address } }))
  }

  return {
    loading: walletsLoading,
    wallets: wallets,
    connectedWallets,
    selectedWallet: { ...connectedWallets[walletType], walletType },
    walletType,
    connectWallet,
    setWalletType,
    fetchWallets,
    setIsSignMetamaskOpen
  }
}

export default useProvideWallet
