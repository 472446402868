import React from 'react'
import { Box, Card, Stack, Typography } from '@mui/material'
import * as S from './styles'
import { NavigateNext } from '@mui/icons-material'

interface ActionItemProps {
  header: string
  subtitle: string
  link: string
  disabled?: boolean
}

const ActionItem: React.FC<ActionItemProps> = ({ header, subtitle, link, disabled }: ActionItemProps) => {
  return (
    <Card variant="outlined">
      <S.StyledActionArea href={link} disabled={disabled}>
        <S.StyledCardContent disabled={disabled}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Box mr="24px">
              <Typography fontWeight={500} mb="4px">
                {header}{' '}
                {disabled && (
                  <Typography component="span" variant="body2" display="inline">
                    (coming soon)
                  </Typography>
                )}
              </Typography>
              <Typography variant="body2" color="grey.600">
                {subtitle}
              </Typography>
            </Box>
            <NavigateNext />
          </Stack>
        </S.StyledCardContent>
      </S.StyledActionArea>
    </Card>
  )
}

export default ActionItem
