import { DialogProps, Stack, Typography, Alert, Snackbar, Box, Link, TextField, IconButton } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { getProfile, saveProfile } from 'src/common/api'
import { alertNotification, Modal, SummaryTable } from 'src/ui'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import ContentCopy from '@mui/icons-material/ContentCopy'
import { ApayloId, FiatCurrency } from '../../../../static'
import { InteracIcon } from 'src/common/assets'
import { LoadingButton } from '@mui/lab'
import { isEmailValid } from 'src/common/helpers'

interface DetailsProps extends DialogProps {
  handleRedirect: () => void
  handleEmail: () => void
  amount: string
  fiatCurrency: FiatCurrency
  loading: boolean
  isPurchase: boolean
}

const Details: React.FC<DetailsProps> = ({
  handleRedirect,
  handleEmail,
  amount,
  fiatCurrency,
  loading,
  isPurchase,
  ...props
}: DetailsProps) => {
  const [user, setUser] = useState({
    id: 'userId',
    email: 'userEmail',
    firstName: 'userFirstName',
    lastName: 'userLastName',
    accountId: 0,
    interacEmail: ''
  })
  const [open, setOpen] = useState(false)
  const [email, setEmail] = useState<string>('')
  const [success, setSuccess] = useState(false)

  const openDetails = success || (props.open && !!user.interacEmail)
  const openAddEmail = !success && props.open && !user.interacEmail

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value)
  }

  const copyToClipboard = (value: string) => {
    navigator.clipboard.writeText(value)
    alertNotification('Security answer successfully copied!', 'success')
  }

  const getUser = async () => {
    try {
      const response = await getProfile()
      setUser(response.data)
    } catch (err) {
      alertNotification('Fetching user failed. Please contact support.', 'error')
    }
  }

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }

  const handleAddEmail = async () => {
    if (!isEmailValid(email)) {
      setOpen(true)
      return
    }
    try {
      await saveProfile({
        interacEmail: email
      })
      setSuccess(true)
    } catch (err) {
      alertNotification('Adding interac email failed. Please contact support.', 'error')
    }
  }

  useEffect(() => {
    getUser()
  }, [])

  const displayDetails = () => {
    return (
      <Stack spacing={2}>
        <Box display="flex">
          <Typography fontSize="14px">
            <b>First Name:</b> {user.firstName}
          </Typography>
          <Typography fontSize="14px" ml={2}>
            <b>Last Name:</b> {user.lastName}
          </Typography>
        </Box>
        <Typography fontSize="14px">
          <b>Interac e-Transfer Email:</b> {email ? email : user.interacEmail}
        </Typography>
        {!isPurchase && (
          <>
            <Typography fontSize="14px">
              <b>Security Question:</b> What is the answer provided by Grapes?
            </Typography>
            <Box display="flex" alignItems="center" height="20px">
              <Typography fontSize="14px">
                <b>Security Answer: </b>
                {ApayloId.PREFIX}
                {user.accountId}
              </Typography>
              <IconButton aria-label="copy" onClick={() => copyToClipboard(`${ApayloId.PREFIX}${user.accountId}`)}>
                <ContentCopy sx={{ fontSize: '20px' }} />
              </IconButton>
            </Box>
          </>
        )}
        <Typography fontSize="14px">
          <b>Order Amount:</b> ${amount} {fiatCurrency}
        </Typography>
      </Stack>
    )
  }

  const displayAddEmail = () => {
    return (
      <SummaryTable
        data={[
          {
            key: (
              <Typography fontWeight={500} fontSize="14px">
                Interac e-Transfer Email
              </Typography>
            ),
            value: <TextField value={email} onChange={handleChange} name="email" placeholder="Interac Email" />
          }
        ]}
      />
    )
  }

  return (
    <>
      <Modal {...props} open={openDetails} title="Interac e-Transfer">
        <Stack spacing={3}>
          <Box display="flex" justifyContent="center">
            <InteracIcon sx={{ fontSize: '91px' }} />
          </Box>
          <Typography variant="subtitle2" textAlign="center">
            To complete this the order, please choose one of the following e-Transfer options:
          </Typography>
          {displayDetails()}
          <LoadingButton variant="contained" loading={loading} onClick={handleRedirect} size="large">
            {isPurchase ? 'Complete the e-Transfer Payment Now' : 'Confirm your Interac Details'}
          </LoadingButton>
          {isPurchase && (
            <LoadingButton loading={loading} onClick={handleEmail} size="large">
              Send the e-Transfer Request to my Email
            </LoadingButton>
          )}
          {isPurchase ? (
            <Typography variant="body2" textAlign="center" color="grey.500" fontSize="12px">
              Important: E-transfer payments must be received within 48 hours of completing the purchase or the order
              may be subject to a requoted FX rate. E-transfers are only accepted from a bank account in the same name
              as your Grapes account. Do not exceed your daily limits with Grapes. Your bank may have its own limits and
              fees. For more information please visit{' '}
              <Link component="a" href="https://grapesfinance.gitbook.io/grapes-academy" target="_blank">
                Grapes Academy
              </Link>
              .
            </Typography>
          ) : (
            <Typography variant="body2" textAlign="center" color="grey.500" fontSize="12px">
              Important: You are only required to enter a security answer if you do not have AutoDeposit enabled. All
              digital assets must be received within 24 hours before Grapes can process the e-transfer to your bank
              account. Please do not submit multiple e-transfer requests for the same Canadian dollar amounts as these
              will be considered duplicates and cancelled.
            </Typography>
          )}
        </Stack>
      </Modal>
      <Modal {...props} open={openAddEmail} title="Interac e-Transfer Email Required">
        <Stack spacing={3}>
          <Box display="flex" justifyContent="center">
            <InteracIcon sx={{ fontSize: '91px' }} />
          </Box>
          <Typography variant="subtitle2" textAlign="center">
            We do not have your e-Transfer email address yet to send you an e-Transfer request. Please fill in your
            e-Transfer email and click the &apos;Add Interac e-Transfer Email&apos; button to confirm.
          </Typography>
          <Snackbar
            open={open}
            autoHideDuration={5000}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          >
            <Alert
              onClose={handleClose}
              severity="error"
              sx={{ width: '100%' }}
              iconMapping={{
                success: <CheckCircleOutlineIcon fontSize="small" />
              }}
            >
              Please enter a valid email address.
            </Alert>
          </Snackbar>
          {displayAddEmail()}
          <LoadingButton variant="contained" loading={loading} onClick={handleAddEmail} size="large">
            Add Interac e-Transfer Email
          </LoadingButton>
        </Stack>
      </Modal>
    </>
  )
}

export default Details
