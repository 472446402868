import React from 'react'

export const CombineProviders = (providers: React.FC[]): React.FC => {
  return providers.reduce(
    (AccumulatedProviders, CurrentProvider) => {
      return Object.assign(
        ({ children }: React.ComponentProps<React.FC>): JSX.Element => {
          return (
            <AccumulatedProviders>
              <CurrentProvider>{children}</CurrentProvider>
            </AccumulatedProviders>
          )
        },
        { displayName: 'CombineProviders' }
      )
    },
    ({ children }) => <>{children}</>
  )
}
