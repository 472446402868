import { useCallback, useEffect, useRef } from 'react'
import axios from 'axios'

const useCancelToken = () => {
  const cancelSource = useRef(axios.CancelToken.source())
  const isRequestCancel = axios.isCancel

  const newCancelToken = useCallback(() => {
    cancelSource.current.cancel()
    cancelSource.current = axios.CancelToken.source()
    return cancelSource.current.token
  }, [])

  useEffect(
    () => () => {
      if (cancelSource.current) cancelSource.current.cancel()
    },
    []
  )

  return { newCancelToken, isRequestCancel, cancelSource }
}

export default useCancelToken
