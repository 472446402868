import React from 'react'
import { Chip, Link, Typography } from '@mui/material'
import { TabsUnstyled, TabPanelUnstyled } from '@mui/base'
import { OrderDetails } from 'src/common/api'
import { Box } from '@mui/system'
import * as S from './styles'
import { SummaryTable } from 'src/ui'
import { ChainName, CHAIN_NATIVE_TOKEN, EXPLORER, formatOrder, OrderStatus } from 'src/common/static'
import { formatDate } from 'src/common/helpers'

interface TransactionProps {
  order: OrderDetails
  expanded: boolean
  handleChange: (panel: number) => (event: React.SyntheticEvent, isExpanded: boolean) => void
}

const Order: React.FC<TransactionProps> = ({ order, expanded, handleChange }: TransactionProps) => {
  const isPurchase = order.type === 'PURCHASE'
  const chain = order.transactions && order.transactions[0] && (order.transactions[0].chain as ChainName)
  const { orderDetails, transferDetails, transactionDetails } = formatOrder(order)

  const displayDetails = (details: { key: string; value: string }[]) => {
    return (
      <SummaryTable
        data={details.map((detail) => ({
          key: (
            <Typography variant="body2" fontWeight={500}>
              {detail.key}
            </Typography>
          ),
          value:
            detail.key === 'Transaction ID' && detail.value ? (
              <Link component="a" href={`${EXPLORER[chain]}/tx/${detail.value}`} target="_blank" rel="noopener">
                {detail.value.slice(0, 15).concat('...')}
              </Link>
            ) : (
              <Typography variant="body2" color="grey.600">
                {detail.value || 'Pending'}
              </Typography>
            )
        }))}
        padding="6px 0"
      />
    )
  }

  return (
    <Box mt="8px">
      {order.transactions[0] && (
        <S.StyledAccordion variant="outlined" disableGutters expanded={expanded} onChange={handleChange(order.id)}>
          <S.Summary>
            <Box flexGrow={1}>
              <Typography variant="body2" fontWeight={500} mb="4px">
                {isPurchase ? 'Purchase' : 'Send'} {order.transactions[0].asset}
              </Typography>
              <Typography variant="body2" color="grey.600">
                {OrderStatus[order.status]} | {formatDate(order.createdAt)}
              </Typography>
            </Box>
            <Chip sx={{ m: '0 40px' }} label={CHAIN_NATIVE_TOKEN[chain]} />
            <Box textAlign="right">
              <Typography variant="body2" fontWeight={500} mb="4px">
                +{' '}
                {isPurchase
                  ? `${order.cryptoAmount} ${order.transactions[0].asset}`
                  : `$${order.fiatAmount} ${order.fiatCurrency}`}
              </Typography>
              <Typography variant="body2" color="grey.600">
                - {isPurchase ? `$${order.fiatAmount}` : `${order.cryptoAmount}`}{' '}
                <Typography variant="caption">
                  {isPurchase ? `${order.fiatCurrency}` : order.transactions[0].asset}
                </Typography>
              </Typography>
            </Box>
          </S.Summary>
          <S.Details>
            <TabsUnstyled defaultValue={0}>
              <S.TabsList>
                <S.Tab>Order Details</S.Tab>
                <S.Tab>Transfer Details</S.Tab>
                <S.Tab>Transaction Details</S.Tab>
              </S.TabsList>
              <S.InfoContainer>
                <TabPanelUnstyled value={0}>{displayDetails(orderDetails)}</TabPanelUnstyled>
                <TabPanelUnstyled value={1}>{displayDetails(transferDetails)}</TabPanelUnstyled>
                <TabPanelUnstyled value={2}>{displayDetails(transactionDetails)}</TabPanelUnstyled>
              </S.InfoContainer>
            </TabsUnstyled>
          </S.Details>
        </S.StyledAccordion>
      )}
    </Box>
  )
}

export default Order
