import { Typography } from '@mui/material'
import React from 'react'
import { LogoSecondary } from 'src/common/assets'
import * as S from './styles'

const PageNotFound: React.FC = () => {
  return (
    <S.Container>
      <LogoSecondary width={70} height={70} />
      <Typography variant="h3" fontWeight={700} m="40px 0 16px">
        OOPS!
      </Typography>
      <Typography variant="h5" mb="40px">
        Looks like the page you are trying to access doesn&apos;t exist :(
      </Typography>
      <S.HomeButton variant="contained" size="large" href="/">
        Home
      </S.HomeButton>
    </S.Container>
  )
}

export default PageNotFound
