import { SvgIcon, SvgIconProps } from '@mui/material'
import React from 'react'

export const AddBankIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.5 16C1.1 16 0.75 15.85 0.45 15.55C0.15 15.25 0 14.9 0 14.5V1.5C0 1.1 0.15 0.75 0.45 0.45C0.75 0.15 1.1 0 1.5 0H18.5C18.9 0 19.25 0.15 19.55 0.45C19.85 0.75 20 1.1 20 1.5V7.475H1.5V14.5H11.475V16H1.5ZM1.5 4H18.5V1.5H1.5V4ZM17.5 18V15H14.5V13.5H17.5V10.5H19V13.5H22V15H19V18H17.5Z"
        fill="#4F4F4F"
      />
    </SvgIcon>
  )
}
