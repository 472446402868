import React, { useEffect, useState } from 'react'
import { Typography } from '@mui/material'
import * as S from './styles'
import { Container } from 'src/ui'
import { Formik, Field, Form } from 'formik'
import { TextField } from 'formik-mui'
import useLogin, { initialValues, validate } from './Login.State'
import ForgotPassword from './ForgotPassword'
import MessageSent from './MessageSent'
import MobileDesktopNotification from '../MobileDesktopNotification'
import SetupMfa from '../Mfa/SetupMfa'
import flagsmith from 'flagsmith'
import { DISPLAY_SIGN_UP_FLAG } from 'src/common/api/flagsmith/grapesFeatureFlags'

const Login: React.FC = () => {
  const { state, onSubmit } = useLogin()

  const [modals, setModals] = useState({
    forgotPassword: false,
    messageSent: false
  })

  const handleClose = (modal: string) => {
    setModals((s) => ({ ...s, [modal]: false }))
  }

  const handleOpen = (modal: string) => {
    setModals((s) => ({ ...s, [modal]: true }))
  }

  const handleKeyDown = (event: KeyboardEvent, submitForm: () => Promise<void>) => {
    if (event.key === 'Enter') {
      submitForm()
    }
  }

  const [isFlagEnabled, setIsFlagEnabled] = useState(false)

  const getDisplaySignUpFlag = async () => {
    const flag = flagsmith.getAllFlags()[DISPLAY_SIGN_UP_FLAG]
    if (flag.enabled) {
      setIsFlagEnabled(flag.enabled)
    }
  }

  useEffect(() => {
    getDisplaySignUpFlag()
  }, [])

  // check if user is on a mobile device
  const details = navigator.userAgent
  const regexp = /android|iphone|kindle|ipad/i
  const isMobileDevice = regexp.test(details)

  if (isMobileDevice) {
    return <MobileDesktopNotification />
  } else {
    return (
      <>
        <Formik
          initialValues={initialValues}
          validate={validate}
          validateOnBlur={false}
          validateOnChange={false}
          onSubmit={onSubmit}
        >
          {({ submitForm, isSubmitting }) => (
            <Form>
              <Container title="Log in to your account" error={state.error && state.error}>
                <S.Row>
                  <Typography variant="body2">Please fill the required fields *</Typography>
                </S.Row>
                <S.Row>
                  <S.InputLabel>Email *</S.InputLabel>
                  <Field
                    component={TextField}
                    name="email"
                    placeholder="Email"
                    fullWidth
                    onKeyDown={(e: KeyboardEvent) => handleKeyDown(e, submitForm)}
                  />
                </S.Row>
                <S.InputLabel>Password *</S.InputLabel>
                <Field
                  component={TextField}
                  name="password"
                  type="password"
                  placeholder="Password"
                  fullWidth
                  onKeyDown={(e: KeyboardEvent) => handleKeyDown(e, submitForm)}
                />
                <S.ActionsRow>
                  <S.StyledLink component="a" onClick={() => handleOpen('forgotPassword')}>
                    Forgot Password?
                  </S.StyledLink>
                  <S.Button
                    variant="contained"
                    // disabled={!(dirty && isValid)}
                    loading={isSubmitting}
                    onClick={submitForm}
                  >
                    Log In
                  </S.Button>
                </S.ActionsRow>
                <S.Splitter />
                {isFlagEnabled && (
                  <Typography variant="subtitle1" textAlign="center">
                    Don&apos;t have an account yet? <S.StyledLink href="/signup">Sign Up!</S.StyledLink>
                  </Typography>
                )}
              </Container>
            </Form>
          )}
        </Formik>
        <ForgotPassword open={modals.forgotPassword} handleClose={handleClose} handleOpen={handleOpen} />
        <MessageSent open={modals.messageSent} handleClose={handleClose} />
        {state.preferredMfa === 'NOMFA' && <SetupMfa open={state.preferredMfa === 'NOMFA'} />}
      </>
    )
  }
}

export default Login
