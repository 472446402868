import { SvgIcon, SvgIconProps } from '@mui/material'
import React from 'react'

export const NewContactIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.25 9.99998V6.74998H14V5.24998H17.25V1.99998H18.75V5.24998H22V6.74998H18.75V9.99998H17.25ZM8 7.97498C6.9 7.97498 6 7.62498 5.3 6.92498C4.6 6.22498 4.25 5.32498 4.25 4.22498C4.25 3.12498 4.6 2.22498 5.3 1.52498C6 0.824976 6.9 0.474976 8 0.474976C9.1 0.474976 10 0.824976 10.7 1.52498C11.4 2.22498 11.75 3.12498 11.75 4.22498C11.75 5.32498 11.4 6.22498 10.7 6.92498C10 7.62498 9.1 7.97498 8 7.97498ZM0 16V13.65C0 13.0666 0.145833 12.5375 0.4375 12.0625C0.729167 11.5875 1.15 11.2333 1.7 11C2.95 10.45 4.06115 10.0625 5.03345 9.83748C6.00577 9.61248 6.99327 9.49998 7.99595 9.49998C8.99865 9.49998 9.98333 9.61248 10.95 9.83748C11.9167 10.0625 13.025 10.45 14.275 11C14.825 11.25 15.25 11.6083 15.55 12.075C15.85 12.5416 16 13.0666 16 13.65V16H0ZM1.5 14.5H14.5V13.65C14.5 13.3833 14.425 13.1291 14.275 12.8875C14.125 12.6458 13.925 12.4666 13.675 12.35C12.4917 11.8 11.4917 11.4375 10.675 11.2625C9.85833 11.0875 8.96667 11 8 11C7.03333 11 6.1375 11.0875 5.3125 11.2625C4.4875 11.4375 3.48333 11.8 2.3 12.35C2.05 12.4666 1.85417 12.6458 1.7125 12.8875C1.57083 13.1291 1.5 13.3833 1.5 13.65V14.5ZM8 6.47498C8.65 6.47498 9.1875 6.26248 9.6125 5.83748C10.0375 5.41248 10.25 4.87498 10.25 4.22498C10.25 3.57498 10.0375 3.03748 9.6125 2.61248C9.1875 2.18748 8.65 1.97498 8 1.97498C7.35 1.97498 6.8125 2.18748 6.3875 2.61248C5.9625 3.03748 5.75 3.57498 5.75 4.22498C5.75 4.87498 5.9625 5.41248 6.3875 5.83748C6.8125 6.26248 7.35 6.47498 8 6.47498Z"
        fill="#4F4F4F"
      />
    </SvgIcon>
  )
}
