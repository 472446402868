import axios, { CancelToken } from 'axios'
import httpClient from 'src/common/axios'
import { CheckEligibilityDTO, CreateOrderDTO, UpdateRedeemDTO } from './dto'
import {
  OrderModel,
  OrdersModel,
  EligibilityModel,
  ExchangeRatesModel,
  GraphModel,
  FxRateObservation,
  OrderLimitsModel,
  OrderDetails
} from './models'

export const createPurchaseOrder = (params: CreateOrderDTO) => httpClient.post<OrderModel>('/orders/purchase', params)

export const createRedeemOrder = (params: CreateOrderDTO) => httpClient.post<OrderModel>('/orders/redeem', params)

export const updateRedeemOrder = (params: UpdateRedeemDTO) => httpClient.put('/orders/redeem', params)

interface FilterParams {
  type?: string
  status?: string
  chain?: string
  asset?: string
  fiatCurrency?: string
  sortBy?: string
  page?: number
}

export const getOrders = (params?: FilterParams, cancelToken?: CancelToken) =>
  httpClient.get<OrdersModel>('/orders', { params, cancelToken })

export const checkOrderEligibility = (params: CheckEligibilityDTO) =>
  httpClient.post<EligibilityModel>('/orders/check-eligibility', params)

export const getFxRate = () =>
  httpClient.get<ExchangeRatesModel>('/orders/rate', {
    params: {
      currency: ['USD', 'ETH']
    }
  })

export const getGraphOrders = () => httpClient.get<GraphModel>('/orders/analytics')

export const getFxRateObs = () =>
  axios.get<{ observations: FxRateObservation[] }>('https://www.bankofcanada.ca/valet/observations/FXUSDCAD/json')

export const getOrderLimits = () => httpClient.get<OrderLimitsModel>('/orders/limits')

export const getInteracRequestLink = (params: number) =>
  httpClient.get<string>('/orders/interaclink', {
    params: {
      id: params
    }
  })

export const getOrder = (orderId: number) =>
  httpClient.get<OrderDetails>('/orders/order', {
    params: {
      orderId: orderId
    }
  })
