import { useEffect, useState } from 'react'
import { useMetaMask } from 'metamask-react'
import { useWallet } from 'src/common/context'
import { ChainName, WalletCustody, WalletType } from 'src/common/static'
import { createWallet } from 'src/common/api'
import MyAlgoConnect from '@randlabs/myalgo-connect'
import { alertNotification } from 'src/ui'

const myAlgoWallet = new MyAlgoConnect()

const LOCAL_STORAGE_MYALGO = 'myAlgoWallet'
const LOCAL_STORAGE_CUSTODIAL = 'custodialWallet'

const useConnectWallet = () => {
  const {
    walletType,
    setWalletType,
    selectedWallet,
    connectWallet,
    fetchWallets,
    wallets,
    loading: fetchWalletsLoading
  } = useWallet()

  const { status, connect, account } = useMetaMask()

  const [loading, setLoading] = useState(false)

  const [fetchedWallet, setFetchedWallet] = useState({
    address: '',
    name: '',
    chain: ChainName.ALGORAND,
    walletType: WalletType.MYALGO
  })

  const [custodialExists, setCustodialExists] = useState(false)

  const [modals, setModals] = useState({ attestation: false, custodyNetwork: false })

  const openModal = (modal: string) => {
    setModals((s) => ({ ...s, [modal]: true }))
  }

  const closeModal = (modal: string) => {
    setModals((s) => ({ ...s, [modal]: false }))
  }

  const walletExists = (address: string | null, chain: ChainName) =>
    wallets.some((wallet) => wallet.address === address && wallet.chain === chain)

  const metamaskLoading =
    walletType === WalletType.METAMASK &&
    (status === 'initializing' ||
      status === 'connecting' ||
      // convert address to lowercase to make metamask and brave consistent (metamask gives address in lowercase while brave doesn't)
      (status === 'connected' && !walletExists(account.toLowerCase(), ChainName.ETHEREUM)))

  useEffect(() => {
    if (!fetchWalletsLoading) {
      const myAlgoAdress = localStorage.getItem(LOCAL_STORAGE_MYALGO)
      // check if wallet exists
      if (walletExists(myAlgoAdress, ChainName.ALGORAND)) {
        connectWallet(WalletType.MYALGO, ChainName.ALGORAND, localStorage.getItem(LOCAL_STORAGE_MYALGO))
      } else {
        localStorage.removeItem(LOCAL_STORAGE_MYALGO)
      }
      // check if a custodial wallet exists
      if (wallets.some((wallet) => wallet.type === WalletCustody.CUSTODIAL)) {
        setCustodialExists(true)
        connectWallet(WalletType.CUSTODIAL, ChainName.ETHEREUM, localStorage.getItem(LOCAL_STORAGE_CUSTODIAL))
      } else {
        localStorage.removeItem(LOCAL_STORAGE_CUSTODIAL)
      }
    }
  }, [fetchWalletsLoading, wallets])

  const handleWalletConnection = (address: string, walletType: WalletType, chain: ChainName) => {
    if (walletType === WalletType.MYALGO) {
      localStorage.setItem(LOCAL_STORAGE_MYALGO, address)
    }
    if (walletType === WalletType.CUSTODIAL) {
      localStorage.setItem(LOCAL_STORAGE_CUSTODIAL, address)
    }
    connectWallet(walletType, chain, address)
  }

  const openAttestation = (address: string, name: string, chain: ChainName, walletType: WalletType) => {
    if (!walletExists(address, chain)) {
      setFetchedWallet({ address, name, chain, walletType })
      openModal('attestation')
    } else {
      handleWalletConnection(address, walletType, chain)
      setLoading(false)
    }
  }

  const closeAttestation = () => {
    setLoading(false)
    closeModal('attestation')
  }

  const storeWallet = async () => {
    closeModal('attestation')
    try {
      await createWallet({ ...fetchedWallet })
      handleWalletConnection(fetchedWallet.address, fetchedWallet.walletType, fetchedWallet.chain)
      fetchWallets()
    } catch (err) {
      alertNotification('Creating wallet failed. Please contact support.', 'error')
    }
    setLoading(false)
  }

  const connectToMyAlgo = async () => {
    setLoading(true)
    try {
      const accounts = await myAlgoWallet.connect({ shouldSelectOneAccount: true, openManager: true })
      const addresses = accounts.map((account) => account.address)
      const names = accounts.map((account) => account.name)
      openAttestation(addresses[0], names[0], ChainName.ALGORAND, WalletType.MYALGO)
    } catch (err) {
      alertNotification('Connecting MyAlgo wallet failed. Please contact support.', 'error')
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!fetchWalletsLoading) {
      if (status === 'connected') {
        openAttestation(account.toLowerCase(), 'Eth Wallet', ChainName.ETHEREUM, WalletType.METAMASK)
      } else {
        connectWallet(WalletType.METAMASK, ChainName.ETHEREUM, null)
      }
    }
  }, [fetchWalletsLoading, status, account])

  const connectToMetamask = () => {
    if (status === 'unavailable') {
      window.open('https://metamask.io')
    }
    if (status === 'notConnected') {
      connect()
    }
  }

  const chooseChain = (chain: ChainName) => {
    const wallet = wallets.find((wallet) => wallet.type === WalletCustody.CUSTODIAL && wallet.chain === chain)
    if (wallet) {
      handleWalletConnection(wallet.address, WalletType.CUSTODIAL, wallet.chain)
    } else {
      alertNotification("Wallet couldn't be found")
    }
    closeModal('custodyNetwork')
  }

  const connectToCustodial = () => {
    openModal('custodyNetwork')
  }

  const handleConnectWallet = () => {
    switch (walletType) {
      case WalletType.MYALGO:
        connectToMyAlgo()
        break
      case WalletType.METAMASK:
        connectToMetamask()
        break
      case WalletType.CUSTODIAL:
        connectToCustodial()
        break
    }
  }

  const [anchorElWallet, setAnchorElWallet] = useState<null | HTMLElement>(null)

  const handleOpenWalletMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElWallet(event.currentTarget)
  }

  const handleCloseWalletMenu = () => {
    setAnchorElWallet(null)
  }

  const chooseWalletType = (type: WalletType) => {
    setWalletType(type)
    localStorage.setItem('selectedWallet', type)
    handleCloseWalletMenu()
  }

  return {
    selectedWallet,
    walletType,
    status,
    fetchWalletsLoading,
    custodialExists,
    loading,
    modals,
    fetchedWallet,
    closeModal,
    handleOpenWalletMenu,
    anchorElWallet,
    handleCloseWalletMenu,
    chooseWalletType,
    handleConnectWallet,
    chooseChain,
    storeWallet,
    closeAttestation,
    metamaskLoading
  }
}

export default useConnectWallet
