import { Container, Paper, Button } from '@mui/material'
import { styled } from '@mui/system'

export const StyledContainer = styled(Container)({
  marginTop: 60,
  paddingBottom: 60
})

export const StyledPaper = styled(Paper)({
  padding: 40
})

export const StyledButton = styled(Button)({
  boxSizing: 'border-box',
  height: '32px',
  border: '1px solid #7B7B7B',
  borderRadius: '8px',
  color: '#4F4F4F',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '13px',
  lineHeight: '16px'
})
