import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { useAuth } from 'src/common/context'

type AuthType = 'guest' | 'protected'

interface RequireAuthProps {
  children: JSX.Element
  authType: AuthType
}

const RequireAuth: React.FC<RequireAuthProps> = ({ authType, children }: RequireAuthProps) => {
  const { loading, user } = useAuth()
  const location = useLocation()

  const proceed = (): boolean => {
    switch (authType) {
      case 'protected':
        return user ? true : false

      case 'guest':
        return user ? false : true

      default:
        return true
    }
  }

  if (loading) {
    return <></>
  } else if (proceed()) {
    return children
  } else if (authType === 'guest') {
    return <Navigate to="/" state={{ from: location }} />
  } else {
    // if auThype === 'protected'
    return <Navigate to="/login" state={{ from: location }} />
  }
}

export default RequireAuth
