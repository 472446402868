import React, { createContext, useContext } from 'react'
import useProvideAuth, { AuthContext } from './ProvideAuth.State'

interface ProvideAuthProps {
  children: React.ReactNode
}

const authContext = createContext<AuthContext | null>(null)

export const ProvideAuth: React.FC<ProvideAuthProps> = ({ children }: ProvideAuthProps) => {
  const auth = useProvideAuth()

  return <authContext.Provider value={auth}>{!auth.loading && children}</authContext.Provider>
}

export const useAuth = (): AuthContext => {
  const context = useContext(authContext) as AuthContext
  if (!context) {
    throw new Error('useAuth must be used within a AuthContext')
  }
  return context
}
