import { CheckCircleOutlined } from '@mui/icons-material'
import { Typography, Link } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { useAuth } from 'src/common/context'
import { Container } from 'src/ui'

interface VerifyEmailProps {
  email: string
}

const VerifyEmail: React.FC<VerifyEmailProps> = ({ email }: VerifyEmailProps) => {
  const { resendEmail } = useAuth()

  return (
    <Container title="Verify your email">
      <Box textAlign="center">
        <CheckCircleOutlined color="primary" sx={{ fontSize: '8rem' }} />
        <Typography variant="h6" fontWeight={400} padding="0 16px">
          We sent you a verification to <b>{email}</b>. Click the “Verify account” link to get started!
        </Typography>
        <Typography variant="h6" fontWeight={400} p="0 16px" mt="40px">
          Didn’t receive an email yet?{' '}
          <Link component="a" fontWeight={600} sx={{ cursor: 'pointer' }} onClick={() => resendEmail(email)}>
            Resend email
          </Link>
        </Typography>
      </Box>
    </Container>
  )
}

export default VerifyEmail
