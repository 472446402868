import { Circle } from '@mui/icons-material'
import { styled } from '@mui/system'

interface CircleIconProps {
  connected: boolean
}

export const CircleIcon = styled(Circle, { shouldForwardProp: (prop) => prop !== 'connected' })<CircleIconProps>(
  ({ theme, connected }) => ({
    color: connected ? theme.palette.success.light : theme.palette.error.main,
    fontSize: '0.75rem'
  })
)
