import React from 'react'
import { Stack, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import * as S from './styles'
import { Container, PasswordInputField } from 'src/ui'
import { Formik, Form } from 'formik'
import useSignup, { initialValues, validate } from './ResetPassword.State'
import { Box } from '@mui/system'
import { Navigate } from 'react-router-dom'

const ResetPassword: React.FC = () => {
  const { state, setState, onSubmit, invalidURL } = useSignup()

  if (invalidURL) {
    return <Navigate to="/login" replace />
  }

  return !state.success ? (
    <Formik
      initialValues={initialValues}
      validate={(values) => {
        const { errors, bar } = validate(values)
        setState({ ...state, bar })
        return errors
      }}
      onSubmit={onSubmit}
    >
      {({ submitForm, isSubmitting }) => (
        <Form>
          <Container title="Reset your password" error={state.error}>
            <Stack spacing={3}>
              <Typography variant="body2">Please enter your new password down below.</Typography>
              <Box>
                <PasswordInputField state={{ strengthColor: state.bar.strengthColor, value: state.bar.value }} />
                <S.PasswordHelper variant="subtitle2">
                  Your password should have at least 8 characters, one uppercase, one lowercase, one number and one
                  special character
                </S.PasswordHelper>
              </Box>
              <LoadingButton
                variant="contained"
                size="large"
                // disabled={!(dirty && isValid)}
                loading={isSubmitting}
                onClick={submitForm}
                fullWidth
              >
                Reset password
              </LoadingButton>
            </Stack>
          </Container>
        </Form>
      )}
    </Formik>
  ) : (
    <></>
    // <VerifyEmail email={state.success} />
  )
}

export default ResetPassword
