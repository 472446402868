import { ChainAsset, FiatCurrency, OrderAction, OrderStatus } from 'src/common/static'

export interface OrderModel {
  id: number
  status: OrderStatus
  createdAt: string
  fiatAmount: string
  fiatCurrency: FiatCurrency
  cryptoAmount: string
  txId?: string
}

export interface OrderDetails {
  id: number
  type: 'PURCHASE' | 'REDEEM'
  fiatAmount: string
  fiatCurrency: FiatCurrency
  cryptoAmount: string
  fxRate: number
  status: OrderStatus
  paymentMethod: 'BILL_PAY' | 'EFT' | 'FIAT_OTHER'
  createdAt: string
  transfers: {
    id: number
    amount: string
    fiatCurrency: FiatCurrency
    status: string
    referenceId: string
    createdAt: string
  }[]
  transactions: {
    id: number
    asset: string
    amount: string
    status: string
    address: string
    chain: string
    referenceId: string
    createdAt: string
  }[]
}

export interface OrdersModel {
  numOfPages: number
  orders: OrderDetails[]
}

export enum KYC_STATUS {
  UNINITIATED = 'Uninitiated',
  NEW = 'New',
  PENDING = 'Pending',
  REVIEW = 'Review',
  ESCALATED = 'Escalated',
  APPROVED = 'Approved',
  REJECTED = 'Rejected'
}

export interface EligibilityModel {
  walletScreening: 'fail' | 'success'
  kycStatus: KYC_STATUS
}

export interface ExchangeRatesModel {
  eth: number
  usd: number
}

export interface GraphOrderModel {
  type: OrderAction
  cryptoAmount: string
  asset: ChainAsset
  createdAt: string
}

export interface GraphModel {
  accountCreation: string
  orders: GraphOrderModel[]
}

export interface FxRateObservation {
  d: string
  FXUSDCAD: {
    v: string
  }
}

export interface OrderLimitsModel {
  availableDailyLimit: number
  availableWeeklyLimit: number
}
