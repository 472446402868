import { Box } from '@mui/material'
import { styled } from '@mui/system'

export const Row = styled(Box)({
  marginBottom: 24
})

export const Container = styled('div')({
  padding: '60px 25px 84px',
  textAlign: 'center'
})
