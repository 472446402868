import { OpenInNew } from '@mui/icons-material'
import { Button, DialogProps, Divider, Stack, Typography } from '@mui/material'
import React from 'react'
import { Modal } from 'src/ui'

const ThirdPartyForm: React.FC<DialogProps> = (props: DialogProps) => {
  return (
    <Modal {...props} title="Send">
      <Stack spacing={3}>
        <Typography variant="h6">Transfer Request Form - Digital Currency to Fiat Payout</Typography>
        <Typography variant="body2">
          Please submit the following form to initiate a fiat payout (3rd party beneficiary) from your digital wallet.
        </Typography>
        <Divider />
        <Button
          component="a"
          endIcon={<OpenInNew />}
          size="large"
          target="_blank"
          rel="noopener"
          href="https://powerforms.docusign.net/f56f454b-dee5-4f9d-ae4f-f42ab71b410c?env=ca&acct=f800d0cf-bb39-47af-a3a2-7a03226b0b43&accountId=f800d0cf-bb39-47af-a3a2-7a03226b0b43"
        >
          Fiat Payout Form
        </Button>
        <Button variant="contained" size="large" href="/">
          Return to Dashboard
        </Button>
      </Stack>
    </Modal>
  )
}

export default ThirdPartyForm
