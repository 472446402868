import { Button, Divider, IconButton, Stack, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useState, useEffect } from 'react'
import { useAuth } from 'src/common/context'
import * as S from './styles'
import { Modal, alertNotification } from 'src/ui'
import SetupMfa from '../Mfa/SetupMfa'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { calculatePasswordStrength } from 'src/common/helpers'

const Security: React.FC = () => {
  const { user, getPreferredMfa, changePassword } = useAuth()
  const [mfaType, setMfaType] = useState('')
  const isMfaEnabled = mfaType === 'SOFTWARE_TOKEN_MFA'

  const [modals, setModals] = useState({
    setupMfa: false,
    changePw: false
  })
  const [loading, setLoading] = useState(false)
  const [passwords, setPasswords] = useState({
    oldPassword: '',
    newPassword: ''
  })
  const [visible, setVisible] = useState(false)

  const handleOpen = (modal: string) => {
    setModals((s) => ({ ...s, [modal]: true }))
  }

  const handleClose = (modal: string) => {
    setModals((s) => ({ ...s, [modal]: false }))
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPasswords({
      ...passwords,
      [event.target.name]: event.target.value
    })
  }

  const handleRequest = async () => {
    const { error } = calculatePasswordStrength(passwords.newPassword)
    if (error) {
      alertNotification(error)
      return
    }
    try {
      setLoading(true)
      await changePassword(passwords.oldPassword, passwords.newPassword)
      setPasswords({
        oldPassword: '',
        newPassword: ''
      })
      alertNotification('Password changed successfully!', 'success')
    } catch (e) {
      alertNotification((e as Error).message)
    }
    setLoading(false)
  }

  useEffect(() => {
    const getMfaType = async (): Promise<void> => {
      try {
        if (!user) return
        const preferredMfa: string = await getPreferredMfa()
        setMfaType(preferredMfa)
      } catch (error) {
        alertNotification()
      }
    }
    getMfaType()
  }, [user])

  return (
    <>
      <Typography variant="h5" fontWeight={500} my="48px">
        Security
      </Typography>
      <Divider />
      <S.Flex>
        <Box>
          <Typography fontWeight={500}>Two factor authentication</Typography>
          {isMfaEnabled ? (
            <S.TierChip variant="outlined" color="success" label="2-FA enabled" />
          ) : (
            <S.TierChipError variant="outlined" color="error" label="2-FA not set" />
          )}
        </Box>
        <Button size="small" disabled={isMfaEnabled} onClick={() => handleOpen('setupMfa')}>
          Enable 2-factor authentication
        </Button>
      </S.Flex>
      <Divider />
      <S.Flex>
        <Box>
          <Typography fontWeight={500}>Password</Typography>
          <Typography color="grey.600">
            Remember not to store your password in your email or cloud and don&apos;t share it with anyone.
          </Typography>
        </Box>
        <Button size="small" onClick={() => handleOpen('changePw')}>
          Change password
        </Button>
      </S.Flex>
      <Divider />
      {modals.setupMfa && <SetupMfa open={modals.setupMfa} onClose={() => handleClose('setupMfa')} />}
      <Modal
        open={modals.changePw}
        onClose={() => handleClose('changePw')}
        title="Change Password"
        maxWidth="xs"
        fullWidth
      >
        <Stack spacing={3}>
          <Box>
            <Typography mb={1}>Current Password</Typography>
            <TextField
              value={passwords.oldPassword}
              onChange={handleChange}
              size="small"
              fullWidth
              name="oldPassword"
              placeholder="Current Password"
              type={visible ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <S.StyledInputAdornment position="end">
                    <IconButton onClick={() => setVisible(!visible)}>
                      {visible ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </S.StyledInputAdornment>
                )
              }}
            />
          </Box>
          <Box>
            <Typography mb={1}>New Password</Typography>
            <TextField
              value={passwords.newPassword}
              onChange={handleChange}
              size="small"
              fullWidth
              name="newPassword"
              placeholder="New Password"
              type={visible ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <S.StyledInputAdornment position="end">
                    <IconButton onClick={() => setVisible(!visible)}>
                      {visible ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </S.StyledInputAdornment>
                )
              }}
            />
          </Box>
          <Typography variant="subtitle2">
            Password should contain at least 1 lowercase, 1 uppercase, 1 number and 1 special character, with a minimum
            length of 8 characters.
          </Typography>
          <LoadingButton
            variant="contained"
            size="large"
            loading={loading}
            fullWidth
            onClick={handleRequest}
            disabled={!passwords.oldPassword || !passwords.newPassword}
          >
            Change Password
          </LoadingButton>
        </Stack>
      </Modal>
    </>
  )
}

export default Security
