import { ChainName } from '../static'
import Web3 from 'web3'
import algosdk from 'algosdk'
import Ajv from 'ajv'
import addFormats from 'ajv-formats'

const ajv = new Ajv({ allErrors: true })
addFormats(ajv)

export const calculatePasswordStrength = (
  password: string
): {
  error: string | null
  bar: { strengthColor: string; value: number }
} => {
  let conditionsFulfilled = 0
  conditionsFulfilled += password.length > 7 ? 1 : 0 // at least 8 chars
  conditionsFulfilled += /[a-z]/g.test(password) ? 1 : 0 // at least one lowercase
  conditionsFulfilled += /[A-Z]/g.test(password) ? 1 : 0 // at least one uppercase
  conditionsFulfilled += /[0-9]/g.test(password) ? 1 : 0 // at least one number
  conditionsFulfilled += /[\^$*.[\]{}()?\-"!@#%&/,><’:;|_~`]/g.test(password) ? 1 : 0 // at least one of  the special characters listed by cognito

  let error: string | null = null
  if (conditionsFulfilled < 5) {
    error =
      'Your password should have at least 8 characters, one uppercase, one lowercase, one number and one special character'
  }

  const bar = {
    strengthColor: 'disabled',
    value: 0
  }
  if (conditionsFulfilled > 0) {
    if (conditionsFulfilled > 2) {
      if (conditionsFulfilled > 4) {
        ;[bar.strengthColor, bar.value] = ['strong', 100]
      } else {
        ;[bar.strengthColor, bar.value] = ['medium', 66]
      }
    } else {
      ;[bar.strengthColor, bar.value] = ['weak', 33]
    }
  }

  return { error, bar }
}

export const isEmailValid = (email: string): boolean => {
  const emailSchema = {
    type: 'string',
    format: 'email'
  }

  const emailValidator = ajv.compile(emailSchema)
  const isValid = emailValidator(email)
  return isValid
}

// 3 digits
export const isInstitutionNumValid = (institutionNum: string): boolean => {
  const institutionNumSchema = {
    type: 'string',
    pattern: '^[0-9]{3}$'
  }

  const validateInstitutionNum = ajv.compile(institutionNumSchema)
  const isValid = validateInstitutionNum(institutionNum)
  return isValid
}

// 5 digits
export const isTransitNumValid = (transitNum: string): boolean => {
  const transitNumSchema = {
    type: 'string',
    pattern: '^[0-9]{5}$'
  }

  const validateTransitNum = ajv.compile(transitNumSchema)
  const isValid = validateTransitNum(transitNum)
  return isValid
}

export const isAchCodeValid = (achCode: string): boolean => {
  const achCodeSchema = {
    type: 'string',
    minLength: 9,
    maxLength: 9
  }

  const validateAchCode = ajv.compile(achCodeSchema)
  const isValid = validateAchCode(achCode)
  return isValid
}

export const isSwiftBicCodeValid = (swiftBicCode: string): boolean => {
  const swiftBicCodeSchema = {
    type: 'string',
    minLength: 8,
    maxLength: 11
  }

  const validateSwiftBicCode = ajv.compile(swiftBicCodeSchema)
  const isValid = validateSwiftBicCode(swiftBicCode)
  return isValid
}
// 7 or 12 digits
export const isCanadaAcctNumValid = (accountNum: string): boolean => {
  const canadaAcctNumSchema = {
    type: 'string',
    pattern: '^\\d{7}$|^\\d{12}$'
  }

  const validateCanadaAcctNum = ajv.compile(canadaAcctNumSchema)
  const isValid = validateCanadaAcctNum(accountNum)
  return isValid
}

// 6-17 digits
export const isUSAcctNumValid = (accountNum: string): boolean => {
  const usAcctNumSchema = {
    type: 'string',
    minLength: 6,
    maxLength: 17
  }

  const validateUSAcctNum = ajv.compile(usAcctNumSchema)
  const isValid = validateUSAcctNum(accountNum)
  return isValid
}

export const isBlockchainAddressValid = (address: string, chain: ChainName): boolean => {
  const web3 = new Web3()
  if (chain === ChainName.ETHEREUM && !web3.utils.isAddress(address)) {
    return false
  }
  if (chain === ChainName.ALGORAND && !algosdk.isValidAddress(address)) {
    return false
  }
  return true
}

export class ValidationError extends Error {
  constructor(msg: string) {
    super(msg)
    Object.setPrototypeOf(this, ValidationError.prototype)
  }
}
