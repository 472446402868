import { styled } from '@mui/system'

interface ContainerProps {
  padding?: number | string
}

export const Container = styled('div', { shouldForwardProp: (prop) => prop !== 'padding' })<ContainerProps>(
  ({ padding }) => ({
    padding: padding || '32px 25px'
  })
)

export const Head = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '24px'
})
