import React, { useEffect, useState } from 'react'
import { Alert, AlertTitle, Box, Stack, Typography } from '@mui/material'
import { ActionItem, Container } from 'src/ui'
import { useWallet } from 'src/common/context'
import { WalletType } from 'src/common/static'
import flagsmith from 'flagsmith'
import { DISPLAY_ADD_FUNDS_ALERT_FLAG, ENABLE_PURCHASE_FLOW } from 'src/common/api/flagsmith/grapesFeatureFlags'
import { AlertFlag } from 'src/common/api/flagsmith/flagInterface'

const AddFunds: React.FC = () => {
  const { walletType } = useWallet()
  const [isAddFundsAlertsEnabled, setIsAddFundsAlertsEnabled] = useState(false)
  const [addFundsAlertsFlagValue, setAddFundsAlertsFlagValue] = useState<AlertFlag | null | undefined>(null)
  const [isPurchaseFlowEnabled, setIsPurchaseFlowEnabled] = React.useState(false)

  const getAddFundsAlertFlag = async () => {
    const flag = flagsmith.getAllFlags()[DISPLAY_ADD_FUNDS_ALERT_FLAG]
    if (flag.enabled) {
      setIsAddFundsAlertsEnabled(flag.enabled)
      setAddFundsAlertsFlagValue(JSON.parse(flag.value as string))
    }
  }

  const getEnablePurchaseBillpayFlag = async () => {
    const flag = flagsmith.getAllFlags()[ENABLE_PURCHASE_FLOW]
    if (flag.enabled) {
      setIsPurchaseFlowEnabled(flag.enabled)
    }
  }

  const actions = [
    {
      header: 'Fund your wallet using fiat currency',
      subtitle: 'Initiate a purchase from your bank account',
      link: '/add-funds/digital-assets-to-my-wallet',
      comingSoon: !isPurchaseFlowEnabled
    },
    {
      header: 'Fund your wallet using digital assets',
      subtitle: 'Transfer funds from a blockchain wallet',
      link: '/add-funds/transfer-digital-assets-from-blockchain-wallet',
      comingSoon: false
    }
  ]

  useEffect(() => {
    getAddFundsAlertFlag()
    getEnablePurchaseBillpayFlag()
  })

  const displayWalletName = (type: WalletType) => {
    return {
      myalgo: 'MyAlgo',
      metamask: 'MetaMask',
      custodial: 'Balance'
    }[type]
  }

  return (
    <Container>
      <Box paddingBottom={'10px'}>
        {isAddFundsAlertsEnabled && (
          <Alert severity={addFundsAlertsFlagValue?.alertType}>
            {' '}
            <AlertTitle> {addFundsAlertsFlagValue?.alertTitle}</AlertTitle>
            {addFundsAlertsFlagValue?.alertMessage && (
              <Typography variant="body1" dangerouslySetInnerHTML={{ __html: addFundsAlertsFlagValue.alertMessage }} />
            )}
          </Alert>
        )}
      </Box>
      <Box mb="32px">
        <Typography variant="h5" fontWeight={600}>
          How would you like to fund your {displayWalletName(walletType)} wallet?
        </Typography>
      </Box>
      <Stack spacing={3}>
        {actions.map(({ header, subtitle, link, comingSoon }, i) => (
          <ActionItem key={i} header={header} subtitle={subtitle} link={link} disabled={comingSoon} />
        ))}
      </Stack>
    </Container>
  )
}

export default AddFunds
