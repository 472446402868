import React, { useEffect, useState } from 'react'
import { Button, DialogProps, Link, Stack, Typography } from '@mui/material'
import { Modal } from 'src/ui'
import { BankIcon, InteracIcon } from 'src/common/assets'
import { FiatCurrency, OrderLimit } from 'src/common/static'
import { ENABLE_PURCHASE_BILLPAY } from 'src/common/api/flagsmith/grapesFeatureFlags'
import flagsmith from 'flagsmith'

interface ChoosePaymentProps extends DialogProps {
  choosePayment: (method: string) => void
  type: string
  fiatCurrency: string
  fiatAmount: number
}

const ChoosePayment: React.FC<ChoosePaymentProps> = ({
  choosePayment,
  type,
  fiatCurrency,
  fiatAmount,
  ...props
}: ChoosePaymentProps) => {
  const isPurchase = type === 'Purchase'
  const isRedeem = type === 'Redeem'
  const exceedEtransferLimit =
    (isPurchase && fiatAmount > OrderLimit.E_TRANSFER_PURCHASE_LIMIT) ||
    (isRedeem && fiatAmount > OrderLimit.E_TRANSFER_REDEEM_LIMIT)
  const underEtransferLimit = fiatAmount < OrderLimit.E_TRANSFER_MINIMUM_LIMIT
  const isUSD = fiatCurrency === FiatCurrency.USD

  const [isFlagEnabled, setIsFlagEnabled] = useState(false)

  const getEnablePurchaseBillpayFlag = async () => {
    const flag = flagsmith.getAllFlags()[ENABLE_PURCHASE_BILLPAY]
    if (flag.enabled) {
      setIsFlagEnabled(flag.enabled)
    }
  }

  useEffect(() => {
    getEnablePurchaseBillpayFlag()
  }, [])

  return (
    <Modal {...props} title={isPurchase ? 'Payment Method' : 'Payout Method'}>
      <Stack spacing={2}>
        <Button
          variant="modal"
          onClick={() => choosePayment('interac')}
          endIcon={<InteracIcon />}
          fullWidth
          disabled={underEtransferLimit || exceedEtransferLimit || isUSD}
        >
          Interac e-Transfer
        </Button>
        <Button
          variant="modal"
          onClick={() => choosePayment('billpay')}
          endIcon={<BankIcon />}
          fullWidth
          disabled={type === 'Redeem' || !isFlagEnabled}
        >
          BillPay
        </Button>
        <Button variant="modal" onClick={() => choosePayment('eft')} endIcon={<BankIcon />} fullWidth>
          Electronic Funds Transfer
        </Button>
        {underEtransferLimit && (
          <Typography variant="subtitle2" textAlign="center">
            The minimum limit for e-Transfer {type} is ${OrderLimit.E_TRANSFER_MINIMUM_LIMIT}CAD.
          </Typography>
        )}
        {exceedEtransferLimit && (
          <Typography variant="subtitle2" textAlign="center">
            The limit for e-Transfer {type} is ${isPurchase && OrderLimit.E_TRANSFER_PURCHASE_LIMIT}
            {isRedeem && OrderLimit.E_TRANSFER_REDEEM_LIMIT} CAD.
          </Typography>
        )}
        <Typography variant="subtitle2" textAlign="center">
          Note: for other payment methods, please reach out to our support team at{' '}
          <Link component="a" href="mailto:payments@grapesfinance.com">
            payments@grapesfinance.com
          </Link>
        </Typography>
      </Stack>
    </Modal>
  )
}

export default ChoosePayment
