import React, { useEffect, useState } from 'react'
import { Box, Typography, Link, CircularProgress, Alert, AlertTitle } from '@mui/material'
import { useAuth, useWallet } from 'src/common/context'
import * as S from './styles'
import { alertNotification, Container } from 'src/ui'
import { Order } from 'src/common/components'
import { getOrders, OrderDetails, WalletModel } from 'src/common/api'
import TotalHoldingsOverview from './TotalHoldingsOverview'
import WalletCard from './WalletCard'
import FxRateChart from './FxRateChart'
import flagsmith from 'flagsmith'
import { DISPLAY_DASHBOARD_ALERT_FLAG } from 'src/common/api/flagsmith/grapesFeatureFlags'
import { AlertFlag } from 'src/common/api/flagsmith/flagInterface'
import KohoCard from 'src/common/components/KohoCard/KohoCard'

const Dashboard: React.FC = () => {
  const { wallets, loading, selectedWallet, fetchWallets } = useWallet()
  const { user, socket } = useAuth()
  const [fetching, setFetching] = useState(true)
  const [orders, setOrders] = useState<OrderDetails[]>([])
  const [expanded, setExpanded] = useState<number | false>(false)
  const [sortedWallets, setSortedWallets] = useState<WalletModel[]>([])
  const [isFlagEnabled, setIsFlagEnabled] = useState(false)
  const [flagValue, setFlagValue] = useState<AlertFlag | null | undefined>(null)

  const fetchOrders = async () => {
    try {
      const response = await getOrders()
      setOrders(response.data.orders)
      setFetching(false)
    } catch (err) {
      alertNotification('Fetching orders failed. Please contact support.', 'error')
      setFetching(false)
    }
  }

  const getDashboardAlertFlag = async () => {
    const flag = flagsmith.getAllFlags()[DISPLAY_DASHBOARD_ALERT_FLAG]
    if (flag.enabled) {
      setIsFlagEnabled(flag.enabled)
      setFlagValue(JSON.parse(flag.value as string))
    }
  }
  useEffect(() => {
    fetchOrders()
    getDashboardAlertFlag()
    if (!socket) return

    socket.on('order', async () => {
      // TO-DO: could be optimized to fetch order by id, backend socket returns orderId
      await fetchOrders()
    })

    socket.on('balances', async () => {
      // TO-DO: could be optimized to fetch wallet balance by id, backend socket returns walletId
      await fetchWallets()
    })

    return () => {
      socket.off('order')
      socket.off('balances')
    }
  }, [])

  const handleChange = (panel: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
  }

  const isWalletSelected = (address: string) => selectedWallet.address === address

  const updateSortedWallets = () => {
    const updatedWallets = wallets.reduce((acc: WalletModel[], wallet) => {
      isWalletSelected(wallet.address) ? acc.unshift(wallet) : acc.push(wallet)
      return acc
    }, [])
    setSortedWallets(updatedWallets)
  }

  useEffect(() => {
    updateSortedWallets()
  }, [wallets])

  return (
    <S.DashboardContainer notification={!(wallets.length || loading)}>
      <S.LeftSection>
        <S.SummaryContainer>
          <Box paddingTop={'30px'}>
            {isFlagEnabled && (
              <Alert severity={flagValue?.alertType}>
                {' '}
                <AlertTitle> {flagValue?.alertTitle}</AlertTitle>
                {flagValue?.alertMessage && (
                  <Typography variant="body1" dangerouslySetInnerHTML={{ __html: flagValue.alertMessage }} />
                )}
              </Alert>
            )}
          </Box>
          <Container maxWidth="100%" paperPadding={30}>
            <TotalHoldingsOverview firstName={user?.given_name} />
          </Container>
          <Container maxWidth="100%" marginTop="60px" paperPadding="40px 40px 40px">
            <FxRateChart />
          </Container>
          <Container maxWidth="100%" marginTop="60px" marginBottom="90px" paperPadding={40}>
            <Box display="flex" mb="32px" alignItems="center" justifyContent="space-between">
              <Typography variant="h6" fontWeight={400}>
                Recent Orders
              </Typography>
              <Link href="/orders">view more</Link>
            </Box>
            {fetching && (
              <Box display="flex" justifyContent="center">
                <CircularProgress disableShrink />
              </Box>
            )}
            {!fetching &&
              (orders.length ? (
                orders.map((order) => (
                  <Order key={order.id} order={order} expanded={expanded === order.id} handleChange={handleChange} />
                ))
              ) : (
                <Box display="flex" justifyContent="center" alignItems="center" height="100px">
                  <Typography variant="h6">No Orders</Typography>
                </Box>
              ))}
          </Container>
        </S.SummaryContainer>
      </S.LeftSection>
      <S.WalletsSideBar variant="card" square>
        <Typography variant="h5">Wallet Balances</Typography>
        <KohoCard />
        {sortedWallets.length > 0 ? (
          sortedWallets.map((wallet) => <WalletCard key={wallet.id} wallet={wallet} />)
        ) : (
          <S.EmptyWallets>
            <Typography variant="h6">You have no connected wallets</Typography>
            <Typography>
              Connect a wallet to begin purchasing digital currencies and viewing your wallet balance
            </Typography>
          </S.EmptyWallets>
        )}
      </S.WalletsSideBar>
    </S.DashboardContainer>
  )
}

export default Dashboard
