import { Button } from '@mui/material'
import { styled } from '@mui/system'

export const Container = styled('div')({
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  padding: '16px'
})

export const HomeButton = styled(Button)({
  borderRadius: 99
})
