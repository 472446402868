import React, { useEffect, useState } from 'react'
import { AppBar, IconButton, Typography, Menu, Tooltip, MenuItem, useMediaQuery, Theme, Box, Link } from '@mui/material'
import { Menu as MenuIcon } from '@mui/icons-material'
import { Logo, LogoSecondary } from 'src/common/assets'
import { useAuth, useWallet } from 'src/common/context'
import * as S from './styles'
import ConnectWalletNotification from './ConnectWalletNotification'
import { To, useMatch, useResolvedPath } from 'react-router-dom'
import useNavbar from './Navbar.state'
import ConnectWallet from './ConnectWallet'
import { DISPLAY_SIGN_UP_FLAG } from 'src/common/api/flagsmith/grapesFeatureFlags'
import flagsmith from 'flagsmith'

const pagesLoggedIn = [
  {
    link: 'Dashboard',
    path: '/'
  },
  {
    link: 'Add Funds',
    path: '/add-funds'
  },
  {
    link: 'Send',
    path: '/send'
  },
  {
    link: 'Address Book',
    path: '/address-book'
  }
]
const pagesLoggedOut = [
  {
    link: 'QCAD',
    path: 'https://www.grapesfinance.com/#qcad'
  },
  {
    link: 'Learn',
    path: 'https://grapesfinance.gitbook.io/grapes-academy/'
  },
  {
    link: 'Company',
    path: 'https://www.stablecorp.ca/'
  }
]
const settings = [
  {
    item: 'Profile',
    path: '/profile'
  },
  {
    item: 'Wallets',
    path: '/wallets'
  }
  // {
  //   item: 'Payment',
  //   path: '/payment'
  // }
  // {
  //   item: 'Security',
  //   path: '/security'
  // }
]

const Navbar: React.FC = () => {
  const { user, logout } = useAuth()
  const mobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const { wallets, loading } = useWallet()

  const [isFlagEnabled, setIsFlagEnabled] = useState(false)

  const getDisplaySignUpFlag = async () => {
    const flag = flagsmith.getAllFlags()[DISPLAY_SIGN_UP_FLAG]
    if (flag.enabled) {
      setIsFlagEnabled(flag.enabled)
    }
  }

  const { anchorElNav, anchorElUser, handleOpenNavMenu, handleOpenUserMenu, handleCloseNavMenu, handleCloseUserMenu } =
    useNavbar()

  useEffect(() => {
    getDisplaySignUpFlag()
  }, [])

  return (
    <>
      <AppBar color="inherit">
        <S.NavToolbar>
          <S.LogoContainer href="/">
            {mobile ? <LogoSecondary width="100%" height="100%" /> : <Logo width="100%" height="100%" />}
          </S.LogoContainer>
          <S.NavMenuContainer sx={{ display: { xs: 'flex', lg: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{ display: { xs: 'block', lg: 'none' } }}
            >
              {(user ? pagesLoggedIn : pagesLoggedOut).map(({ link, path }, i) => (
                <MenuItem key={i} component={user ? Link : 'a'} onClick={handleCloseNavMenu} href={path}>
                  <Typography>{link}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </S.NavMenuContainer>
          <S.NavLinksContainer sx={{ display: { xs: 'none', lg: 'inline-flex' } }}>
            {(user ? pagesLoggedIn : pagesLoggedOut).map((page) => (
              <CustomLink
                key={page.link}
                component={user ? undefined : 'a'}
                href={page.path}
                target={user ? undefined : '_blank'}
                rel={user ? undefined : 'noopener'}
                underline="none"
                onClick={handleCloseNavMenu}
              >
                {page.link}
              </CustomLink>
            ))}
          </S.NavLinksContainer>
          {user ? (
            <>
              <ConnectWallet />
              <Tooltip title="Account">
                <S.AvatarIconButton onClick={handleOpenUserMenu}>
                  <S.AvatarInitials alt="user-initials">
                    {user.given_name.charAt(0) + user.family_name.charAt(0)}
                  </S.AvatarInitials>
                </S.AvatarIconButton>
              </Tooltip>
              <Menu
                sx={{ mt: '48px' }}
                id="menu-account"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map(({ item, path }, i) => (
                  <MenuItem key={i} component={Link} onClick={handleCloseUserMenu} href={path}>
                    <Typography>{item}</Typography>
                  </MenuItem>
                ))}
                <MenuItem
                  onClick={() => {
                    logout()
                    handleCloseUserMenu()
                  }}
                >
                  Logout
                </MenuItem>
              </Menu>
            </>
          ) : (
            <>
              <S.AuthButton href="/login" size="small">
                Log In
              </S.AuthButton>
              {isFlagEnabled && (
                <S.AuthButton href="/signup" variant="contained" size="small">
                  Sign Up
                </S.AuthButton>
              )}
            </>
          )}
        </S.NavToolbar>
        {user && !loading && wallets.length === 0 && <ConnectWalletNotification />}
      </AppBar>
      <Box height={75} />
      {user && !loading && wallets.length === 0 && <Box height={55} />}
    </>
  )
}

// weirdly spreading props throws a TS error hence the individual prop assigment
const CustomLink: React.FC<S.NavLinkProps> = ({
  component,
  href,
  target,
  rel,
  underline,
  onClick,
  children
}: S.NavLinkProps) => {
  const resolved = useResolvedPath(href as To)
  const match = useMatch({ path: resolved.pathname, end: href === '/' })

  return (
    <S.NavLink
      component={component}
      href={href}
      target={target}
      rel={rel}
      underline={underline}
      onClick={onClick}
      active={Boolean(match)}
    >
      {children}
    </S.NavLink>
  )
}

export default Navbar
