import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

export const Row = styled('div')({
  marginBottom: '24px'
})

export const InputLabel = styled(Typography)({
  marginBottom: '12px',
  fontWeight: 600
})

export const PasswordHelper = styled(Typography)(({ theme }) => ({
  marginTop: '12px',
  color: theme.palette.grey[600]
}))
