import { LoadingButton } from '@mui/lab'
import { Divider, Link, LinkProps, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

export const Row = styled('div')({
  marginBottom: '24px'
})

export const ActionsRow = styled('div')({
  marginTop: 40,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
})

export const InputLabel = styled(Typography)({
  marginBottom: '12px',
  fontWeight: 600
})

export const Button = styled(LoadingButton)({
  width: '150px',
  marginLeft: '12px'
})

export const Splitter = styled(Divider)({
  margin: '32px 0'
})

interface StyledLinkProps extends LinkProps {
  component?: string
}

export const StyledLink = styled(Link)<StyledLinkProps>({
  fontWeight: 600,
  cursor: 'pointer'
})
