import React, { useState } from 'react'
import { Typography, Box } from '@mui/material'
import * as S from './styles'
import { alertNotification, Container } from '../../ui'
import { Formik, Form } from 'formik'
import { initialValues, validate } from './Mfa.State'
import { MuiOtpInput } from 'mui-one-time-password-input'
import { useAuth } from '../../common/context/auth'

const LoginMfa: React.FC = () => {
  const [code, setCode] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const { tempUser, mfaLogin } = useAuth()

  const handleCodeChange = (value: string): void => {
    const regex = /^([0-9]){0,6}$/i
    if (value === '' || regex.test(value)) {
      setCode(value)
    }
    if (value.length === 6) {
      handleValidateCode(value)
    }
  }

  const handleValidateCode = async (code: string): Promise<void> => {
    try {
      if (isLoading || !tempUser) return
      setIsLoading(true)
      await mfaLogin(tempUser, code)
      alertNotification('Login successful', 'success')
    } catch (error) {
      alertNotification((error as Error).message, 'error')
      setCode('')
      setIsLoading(false)
    }
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        validate={validate}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={() => {
          console.log('empty expression lint error')
        }}
      >
        {() => (
          <Form>
            <Container title="Authenticate Your Identity">
              <S.Row>
                <Typography variant="body2">
                  Please enter the pin that has been shown on <br /> your authenticator app.
                </Typography>
              </S.Row>
              <Box sx={{ minHeight: '10vh' }}>
                <MuiOtpInput length={6} value={code} onChange={handleCodeChange} />
              </Box>
            </Container>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default LoginMfa
