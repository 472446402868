import { CardActionArea, CardActionAreaProps, CardContent, CardContentProps } from '@mui/material'
import { styled } from '@mui/system'

interface StyledCardActionAreaProps extends CardActionAreaProps {
  href?: string
}

export const StyledActionArea = styled(CardActionArea)<StyledCardActionAreaProps>(({ disabled }) => ({
  '&.Mui-focusVisible, :hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.12)'
  },
  '& .MuiCardActionArea-focusHighlight': {
    opacity: '0 !important'
  },
  ...(disabled && { backgroundColor: 'rgba(0, 0, 0, 0.12)' })
}))

interface StyledCardContentProps extends CardContentProps {
  disabled?: boolean
}

export const StyledCardContent = styled(CardContent, {
  shouldForwardProp: (prop) => prop !== 'active'
})<StyledCardContentProps>(({ disabled }) => ({
  ...(disabled && { opacity: 0.38 })
}))
