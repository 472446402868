import { SvgIcon, SvgIconProps } from '@mui/material'
import React from 'react'

export const TransferIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.05 16L0 11.95L4.05 7.9L5.1 8.95L2.85 11.2H10V12.7H2.85L5.1 14.95L4.05 16ZM12.25 12.7C12.05 12.7 11.875 12.625 11.725 12.475C11.575 12.325 11.5 12.15 11.5 11.95C11.5 11.7333 11.575 11.5542 11.725 11.4125C11.875 11.2708 12.05 11.2 12.25 11.2C12.4667 11.2 12.6458 11.2708 12.7875 11.4125C12.9292 11.5542 13 11.7333 13 11.95C13 12.15 12.9292 12.325 12.7875 12.475C12.6458 12.625 12.4667 12.7 12.25 12.7ZM15.25 12.7C15.05 12.7 14.875 12.625 14.725 12.475C14.575 12.325 14.5 12.15 14.5 11.95C14.5 11.7333 14.575 11.5542 14.725 11.4125C14.875 11.2708 15.05 11.2 15.25 11.2C15.4667 11.2 15.6458 11.2708 15.7875 11.4125C15.9292 11.5542 16 11.7333 16 11.95C16 12.15 15.9292 12.325 15.7875 12.475C15.6458 12.625 15.4667 12.7 15.25 12.7ZM13.95 8.1L12.9 7.05L15.15 4.8H8V3.3H15.15L12.9 1.05L13.95 0L18 4.05L13.95 8.1ZM2.75 4.8C2.55 4.8 2.375 4.725 2.225 4.575C2.075 4.425 2 4.25 2 4.05C2 3.83333 2.075 3.65417 2.225 3.5125C2.375 3.37083 2.55 3.3 2.75 3.3C2.96667 3.3 3.14583 3.37083 3.2875 3.5125C3.42917 3.65417 3.5 3.83333 3.5 4.05C3.5 4.25 3.42917 4.425 3.2875 4.575C3.14583 4.725 2.96667 4.8 2.75 4.8ZM5.75 4.8C5.55 4.8 5.375 4.725 5.225 4.575C5.075 4.425 5 4.25 5 4.05C5 3.83333 5.075 3.65417 5.225 3.5125C5.375 3.37083 5.55 3.3 5.75 3.3C5.96667 3.3 6.14583 3.37083 6.2875 3.5125C6.42917 3.65417 6.5 3.83333 6.5 4.05C6.5 4.25 6.42917 4.425 6.2875 4.575C6.14583 4.725 5.96667 4.8 5.75 4.8Z"
        fill="#4F4F4F"
      />
    </SvgIcon>
  )
}
