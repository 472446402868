import React, { useState } from 'react'
import { Button, Link, Typography } from '@mui/material'
import { OrderModel } from 'src/common/api'
import { Container, SummaryTable } from 'src/ui'
import { formatDate, formatTime, formatNumber, trimId } from 'src/common/helpers'
import * as S from './styles'
import { CheckCircleOutlined } from '@mui/icons-material'
import { ChainAsset, ChainName, EXPLORER, OrderStatus, RequestType, PaymentMethod } from 'src/common/static'
import ThirdPartyForm from './ThirdPartyForm'
import { GrapesToWine } from '../../../assets/GrapesToWine'
import { InteracIcon } from 'src/common/assets'

interface Order extends OrderModel {
  asset: ChainAsset
  chain: ChainName
}

interface RequestInitiatedProps {
  order: Order
  type: RequestType
  paymentMethod: 'BILL_PAY' | 'EFT' | 'E_TRANSFER' | 'FIAT_OTHER'
  redirectLink: string
  completeLater: boolean
}

const RequestInitiated: React.FC<RequestInitiatedProps> = ({
  order,
  type,
  paymentMethod,
  redirectLink,
  completeLater
}: RequestInitiatedProps) => {
  const isPurchase = type === RequestType.PURCHASE
  const isSend = type === RequestType.SEND
  const isSendOther = type === RequestType.SEND_THIRD_PARTY
  const isEtransfer = paymentMethod === 'E_TRANSFER'

  const [formModal, setFormModal] = useState(false)

  return (
    <Container>
      <S.Row>
        <Typography variant="h5" fontWeight={600}>
          {isPurchase ? 'Purchase' : 'Send'} Initiated
        </Typography>
      </S.Row>
      <S.Row textAlign="center">
        {isSendOther ? (
          <GrapesToWine sx={{ width: '300px', height: '150px' }} />
        ) : (
          <CheckCircleOutlined color="primary" sx={{ fontSize: '10rem' }} />
        )}
      </S.Row>
      <S.Row>
        <S.Summary>Summary</S.Summary>
        <S.Row>
          <SummaryTable
            data={[
              {
                key: 'Status',
                value: OrderStatus[order.status]
              },
              {
                key: 'Date & Time (UTC)',
                value: `${formatDate(order.createdAt)} ${formatTime(order.createdAt)}`
              },
              {
                key: 'Order Number',
                value: order.id
              },
              {
                key: isPurchase ? 'Payment Method' : 'Transaction ID',
                value: isPurchase ? (
                  PaymentMethod[paymentMethod]
                ) : (
                  <Link component="a" href={`${EXPLORER[order.chain]}/tx/${order.txId}`} target="_blank" rel="noopener">
                    {trimId(order.txId)}
                  </Link>
                )
              }
            ]}
          />
        </S.Row>
        <SummaryTable
          data={[
            {
              key: 'Amount',
              value: (
                <b>
                  {isPurchase && `$${formatNumber(order.fiatAmount) || '0.00'} ${order.fiatCurrency}`}
                  {(isSend || isSendOther) && `${formatNumber(order.cryptoAmount) || '0.00'} ${order.asset}`}
                </b>
              )
            },
            {
              key: 'You Receive',
              value: (
                <b>
                  {isPurchase && `${formatNumber(order.cryptoAmount) || '0.00'} ${order.asset}`}
                  {(isSend || isSendOther) && `$${formatNumber(order.fiatAmount) || '0.00'} ${order.fiatCurrency}`}
                </b>
              )
            }
          ]}
        />
      </S.Row>
      <S.Row>
        {isPurchase && isEtransfer && !completeLater && (
          <Button
            LinkComponent="a"
            href={`${redirectLink}`}
            target="_blank"
            fullWidth
            size="large"
            startIcon={<InteracIcon />}
            sx={{ mb: 2 }}
          >
            Complete your Payment through Interac
          </Button>
        )}
        <Button href="/" variant="contained" fullWidth size="large">
          Return to Dashboard
        </Button>
      </S.Row>
      {isPurchase && isEtransfer && !completeLater && (
        <Typography variant="subtitle2" textAlign="center">
          Please click the Interac button to complete your payment if you are not automatically redirected. Allow popups
          for Grapes for a more seamless user experience.
        </Typography>
      )}
      <ThirdPartyForm open={formModal} onClose={() => setFormModal(false)} />
    </Container>
  )
}

export default RequestInitiated
