import { Box, Typography } from '@mui/material'
import React from 'react'
import { ReactComponent as KohoCardSVG } from '../../assets/KohoCard.svg'

const KohoCard = () => {
  const signUpTypoStyle: React.CSSProperties = {
    fontFamily: 'courier new, sans-serif',
    zIndex: 10,
    position: 'absolute',
    bottom: 50,
    left: 27,
    color: 'white',
    whiteSpace: 'normal',
    padding: 3,
    marginBottom: 10
  }

  const videoTypoStyle: React.CSSProperties = {
    fontFamily: 'courier new, sans-serif',
    zIndex: 10,
    position: 'absolute',
    bottom: 23,
    left: 27,
    color: 'white',
    whiteSpace: 'normal',
    padding: 3
  }

  const svgStyle: React.CSSProperties = {
    position: 'relative',
    borderRadius: 14,
    zIndex: 5
  }

  const boxStyle: React.CSSProperties = {
    position: 'relative',
    width: '100%',
    padding: 0,
    objectFit: 'contain'
  }

  return (
    <Box mt="24px" style={boxStyle}>
      <a href="https://kohofinancial.pxf.io/c/4796239/1804517/15676" target="_blank" rel="noreferrer">
        <div style={signUpTypoStyle}>SIGN UP FOR A QCAD KOHO CARD AND GET $20 CASH BACK!</div>
      </a>
      <a
        href="https://medium.com/@AlexMStablecorp/save-90-on-bringing-money-into-canada-with-qcad-heres-how-in-5-easy-steps-5c67f4e94026"
        target="_blank"
        rel="noreferrer"
      >
        <Typography variant="body2" style={videoTypoStyle}>
          LEARN MORE HERE
        </Typography>
      </a>
      <KohoCardSVG style={svgStyle} viewBox="0 0 370 204" height="100%" width="100%" />
    </Box>
  )
}

export default KohoCard
