import React from 'react'
import ReactDOM from 'react-dom'
import AppRoutes from 'src/routes/AppRoutes'
import { Amplify } from 'aws-amplify'
import flagsmith from 'flagsmith'

Amplify.configure({
  Auth: {
    mandatorySignId: true,
    region: process.env.REACT_APP_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID
  }
})

flagsmith.init({
  environmentID: process.env.REACT_APP_FLAGSMITH_ENV_KEY as string,
  cacheFlags: true
})

ReactDOM.render(
  <React.StrictMode>
    <AppRoutes />
  </React.StrictMode>,
  document.getElementById('root')
)
