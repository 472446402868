import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const StellarIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="103" height="102" viewBox="0 0 103 102" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3683_4630)">
        <path
          d="M102.19 50.9994C102.19 79.1827 79.3492 102.024 51.1659 102.024C22.9827 102.024 0.141602 79.1827 0.141602 50.9994C0.141602 22.8162 22.9827 -0.0249023 51.1659 -0.0249023C79.3492 -0.0249023 102.19 22.8162 102.19 50.9994Z"
          fill="black"
        />
        <path
          d="M37.8532 24.885C47.6735 19.8714 59.4774 20.7962 68.3972 27.2822L67.6792 27.6473L62.8968 30.0811C55.4616 25.9315 46.3958 26.0288 39.0579 30.3245C31.72 34.6323 27.2054 42.4934 27.1932 50.9995C27.1932 52.046 27.2662 53.0925 27.4001 54.1391L67.6914 33.6101L76.0393 29.3631L85.8231 24.3739V30.361L75.9541 35.3867L71.1839 37.8205L28.7021 59.4691L26.3414 60.6738L23.9441 61.9028L16.5332 65.6874V59.7003L19.0522 58.4104C20.9749 57.4368 22.1066 55.4046 21.9484 53.2507C21.8875 52.5084 21.8632 51.7661 21.8632 51.0238C21.8632 39.9988 28.045 29.9107 37.8653 24.8971L37.8532 24.885Z"
          fill="white"
        />
        <path
          d="M85.823 36.348V42.323L83.3041 43.6129C81.3935 44.5864 80.2497 46.6186 80.4079 48.7725C80.4687 49.5148 80.493 50.2693 80.493 51.0238C80.493 62.0488 74.2991 72.1369 64.4787 77.1383C54.6584 82.1397 42.8545 81.2149 33.9469 74.741L39.1308 72.1004L39.4229 71.9422C46.8581 76.1039 55.9361 76.0187 63.2862 71.7231C70.6362 67.4153 75.163 59.542 75.163 51.0238C75.163 49.9651 75.09 48.9185 74.9562 47.872L34.6162 68.4253L26.2683 72.6723L16.5088 77.6372V71.6501L26.3291 66.6487L31.0993 64.2149L85.8109 36.3602L85.823 36.348Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_3683_4630">
          <rect width="102" height="102" fill="white" transform="translate(0.166504)" />
        </clipPath>
      </defs>
    </SvgIcon>
  )
}
