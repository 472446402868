import { Divider, Typography } from '@mui/material'
import React from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import * as S from './styles'
import { PersonIcon, WalletIcon, SecurityIcon } from 'src/common/assets'
import { Box } from '@mui/system'
// import { Payment } from '@mui/icons-material'

const tabs = [
  {
    icon: PersonIcon,
    path: '/profile',
    text: 'Profile'
  },
  {
    icon: WalletIcon,
    path: '/wallets',
    text: 'Linked Wallets'
  },
  // {
  //   icon: Payment,
  //   path: '/payment',
  //   text: 'Payment Methods'
  // }
  {
    icon: SecurityIcon,
    path: '/security',
    text: 'Account Security'
  }
]

const UserManagement: React.FC = () => {
  const { pathname } = useLocation()

  return (
    <S.StyledContainer maxWidth="xl" fixed>
      <Typography variant="h5" p="0 40px" mb="24px">
        User Management
      </Typography>
      <S.StyledPaper variant="card">
        <Box mb="48px">
          {tabs.map(({ icon: Icon, path, text }, i) => (
            <S.Tab
              key={i}
              startIcon={<Icon />}
              href={path}
              active={pathname === path}
              variant="contained"
              color="inherit"
              size="large"
            >
              {text}
            </S.Tab>
          ))}
        </Box>
        <Divider />
        <Outlet />
      </S.StyledPaper>
    </S.StyledContainer>
  )
}

export default UserManagement
