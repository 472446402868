import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const AlgoIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="24" height="24" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0C4.92487 0 0 4.92487 0 11C0 17.0751 4.92487 22 11 22Z"
        fill="black"
      />
      <path
        d="M7.39063 15.7548L8.97394 13.0144L10.5559 10.2836L12.1289 7.54325L12.3901 7.10875L12.5049 7.54325L12.9876 9.34725L12.4472 10.2836L10.8653 13.0144L9.29225 15.7548H11.1829L12.7655 13.0144L13.5857 11.5961L13.9721 13.0144L14.7049 15.7548H16.4031L15.6702 13.0144L14.9366 10.2836L14.7441 9.57894L15.9211 7.54325H14.2038L14.1453 7.34044L13.5472 5.10194L13.4702 4.8125H11.8202L11.7817 4.87025L10.2376 7.54325L8.65494 10.2836L7.08263 13.0144L5.5 15.7548H7.39063Z"
        fill="white"
      />
    </SvgIcon>
  )
}
