import { Button, Chip } from '@mui/material'
import { Box, styled } from '@mui/system'
import { QRCodeCanvas } from 'qrcode.react'

export const Flex = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between'
})

export const TierChip = styled(Chip)(({ theme }) => ({
  borderWidth: 2,
  borderColor: theme.palette.grey['300'],
  fontWeight: 600,
  color: theme.palette.success.light,
  marginRight: 12
}))

export const TierChipError = styled(Chip)(({ theme }) => ({
  borderWidth: 2,
  borderColor: theme.palette.grey['300'],
  fontWeight: 600,
  color: theme.palette.error.light,
  marginRight: 12
}))

export const StyledButton = styled(Button)({
  boxSizing: 'border-box',
  height: '32px',
  border: '1px solid #7B7B7B',
  borderRadius: '8px',
  color: '#4F4F4F',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '13px',
  lineHeight: '16px'
})

export const StyledButton2 = styled(Button)({
  boxSizing: 'border-box',
  height: '32px',
  border: '1px solid #FFFFFF',
  borderRadius: '8px',
  color: '#4F4F4F',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '13px',
  lineHeight: '16px',
  padding: '8px'
})

export const CustomQRCode = styled(QRCodeCanvas)({
  margin: '50px'
})
