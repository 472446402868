import { Chip, InputAdornment } from '@mui/material'
import { Box, styled } from '@mui/system'

export const Flex = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '20px 0'
})

export const TierChip = styled(Chip)(({ theme }) => ({
  borderWidth: 2,
  borderColor: theme.palette.grey['300'],
  fontWeight: 600,
  color: theme.palette.success.light,
  marginTop: 4
}))

export const TierChipError = styled(Chip)(({ theme }) => ({
  borderWidth: 2,
  borderColor: theme.palette.grey['300'],
  fontWeight: 600,
  color: theme.palette.error.light,
  marginTop: 4
}))

export const StyledInputAdornment = styled(InputAdornment)({
  color: 'inherit',
  fontSize: '1rem',
  fontWeight: 600,
  '& > .MuiSvgIcon-root': {
    marginRight: 8
  }
})
