import { Card, DialogProps, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { AlgoIcon, EthIcon, StellarIcon } from 'src/common/assets'
import { ChainName } from 'src/common/static'
import { Modal } from 'src/ui'
import * as S from './styles'
import flagsmith from 'flagsmith'
import { ENABLE_STELLAR_FLAG } from 'src/common/api/flagsmith/grapesFeatureFlags'

interface ChooseCustodyNetworkProps extends DialogProps {
  chooseChain: (chain: ChainName) => void
}

const networks = [
  {
    Icon: AlgoIcon,
    network: 'Algorand',
    chainName: ChainName.ALGORAND
  },
  {
    Icon: EthIcon,
    network: 'Ethereum',
    chainName: ChainName.ETHEREUM
  },
  {
    Icon: StellarIcon,
    network: 'Stellar',
    chainName: ChainName.STELLAR
  }
]

const ChooseCustodyNetwork: React.FC<ChooseCustodyNetworkProps> = ({
  chooseChain,
  ...props
}: ChooseCustodyNetworkProps) => {
  const [isFlagEnabled, setIsFlagEnabled] = useState(false)

  //TODO: Remove this safeguard flag when stellar release is stable
  const getEnableStellarFlag = async () => {
    const flag = flagsmith.getAllFlags()[ENABLE_STELLAR_FLAG]
    if (flag.enabled) {
      setIsFlagEnabled(flag.enabled)
    }
  }

  useEffect(() => {
    getEnableStellarFlag()
  }, [])

  return (
    <Modal {...props} title="Select a Network" maxWidth="sm">
      <Stack direction="row" justifyContent="stretch" spacing={1}>
        {networks.map(({ Icon, network, chainName }) => (
          <Card key={network} variant="outlined" sx={{ flexGrow: 1 }}>
            <S.StyledActionArea
              disabled={isFlagEnabled ? network == 'Algorand' : network != 'Ethereum'} // Flag enabled = stellar features are live, else disable algorand and stellar
              onClick={() => chooseChain(chainName)}
            >
              <S.StyledCardContent
                disabled={isFlagEnabled ? network == 'Algorand' : network != 'Ethereum'} // Flag enabled = stellar features are live, else disable algorand and stellar
              >
                <Stack justifyContent="center" alignItems="center" spacing={2}>
                  <Icon sx={{ fontSize: '6rem' }} />
                  <Typography>{network}</Typography>
                </Stack>
              </S.StyledCardContent>
            </S.StyledActionArea>
          </Card>
        ))}
      </Stack>
    </Modal>
  )
}

export default ChooseCustodyNetwork
