import { CheckCircleOutlined } from '@mui/icons-material'
import { Box, Link, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Navigate, useSearchParams } from 'react-router-dom'
import { useAuth } from 'src/common/context'
import { Container } from 'src/ui'
import { WarningAmber } from '@mui/icons-material'

const ConfirmEmailVerification: React.FC = () => {
  const { verifyEmail } = useAuth()

  const [searchParams] = useSearchParams()

  const [loading, setLoading] = useState(true)
  const [error, setError] = useState('')

  const username = searchParams.get('user_name')
  const confirmationCode = searchParams.get('confirmation_code')

  if (!(username && confirmationCode)) {
    return <Navigate to="/login" replace />
  }

  const confirmVerification = async () => {
    try {
      await verifyEmail(username, confirmationCode)
      setLoading(false)
    } catch (err) {
      setError((err as Error).message)
      setLoading(false)
    }
  }

  useEffect(() => {
    confirmVerification()
  }, [])

  if (loading) {
    return <></>
  }

  return error ? (
    <Container>
      <Box textAlign="center">
        <WarningAmber color="primary" sx={{ fontSize: '8rem' }} />
        <Typography variant="h6" fontWeight={400} padding="0 16px">
          {error}
        </Typography>
      </Box>
    </Container>
  ) : (
    <Container>
      <Box textAlign="center">
        <CheckCircleOutlined color="primary" sx={{ fontSize: '8rem' }} />
        <Typography variant="h6" fontWeight={400} padding="0 16px">
          Congrats! Your email was verified. Login <Link href="/login">here</Link>
        </Typography>
      </Box>
    </Container>
  )
}

export default ConfirmEmailVerification
