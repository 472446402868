import { Typography, Divider, Box, Link, Button, IconButton, Alert } from '@mui/material'
import React, { Fragment, useEffect, useState } from 'react'
import { WalletModel } from 'src/common/api'
import { useWallet } from 'src/common/context'
import { EXPLORER, WALLET_ICON, ChainName, WalletCustody } from 'src/common/static'
import { Modal } from 'src/ui'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import { BalanceIcon, QRCodeIcon } from 'src/common/assets'
import * as S from './styles'

const Wallets: React.FC = () => {
  const { wallets, connectedWallets } = useWallet()

  interface WalletDetails {
    address: string
    name: string
    chain: ChainName
  }

  const [open, setOpen] = useState(false)
  const [copied, setCopied] = useState(false)
  const [walletDetails, setWalletDetails] = useState<WalletDetails>({
    name: '',
    address: '',
    chain: ChainName.ALGORAND
  })

  const isWalletConnected = (address: string) =>
    connectedWallets.metamask.address === address ||
    connectedWallets.myalgo.address === address ||
    connectedWallets.custodial.address === address

  const sortedWallets = wallets.reduce((acc: WalletModel[], wallet) => {
    isWalletConnected(wallet.address) ? acc.unshift(wallet) : acc.push(wallet)
    return acc
  }, [])

  const copyToClipboard = (address: string) => {
    navigator.clipboard.writeText(address)
    setCopied(true)
  }

  const handleQROpen = (name: string, address: string, chain: ChainName) => {
    setWalletDetails({
      name: name,
      address: address,
      chain: chain
    })
    setOpen(true)
  }

  const handleQRClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    setTimeout(() => {
      setCopied(false)
    }, 5000)
  }, [copied])

  return (
    <>
      <S.Flex my="48px">
        <Typography variant="h5" fontWeight={500}>
          My Wallets
        </Typography>
        {/* <Button size="small">Edit</Button> */}
      </S.Flex>
      {sortedWallets.map(({ id, name, address, chain, type }) => {
        let Icon
        if (type === WalletCustody.CUSTODIAL) {
          Icon = BalanceIcon
        } else {
          Icon = WALLET_ICON[chain]
        }
        return (
          <>
            <Fragment key={id}>
              <Divider />
              <S.Flex py="20px">
                <Box display="flex" alignItems="center">
                  <Icon />
                  <Box ml="12px">
                    <Typography>{name}</Typography>
                    {isWalletConnected(address) ? (
                      <Typography color="success.light">Connected</Typography>
                    ) : (
                      <Typography color="error.light">Not Connected</Typography>
                    )}
                  </Box>
                </Box>
                <Box display="flex" alignItems="center">
                  <Link
                    component="a"
                    href={
                      chain != ChainName.STELLAR
                        ? `${EXPLORER[chain]}/address/${address}`
                        : `${EXPLORER[chain]}/account/${address}`
                    }
                    target="_blank"
                    rel="noopener"
                    mx="10px"
                  >
                    {address
                      .slice(0, 15)
                      .concat('...')
                      .concat(address.slice(address.length - 4))}
                  </Link>
                  <IconButton aria-label="qrcode" onClick={() => handleQROpen(name, address, chain)}>
                    <QRCodeIcon />
                  </IconButton>
                </Box>
              </S.Flex>
            </Fragment>
          </>
        )
      })}
      <Modal open={open} onClose={() => handleQRClose()} title={`${walletDetails.name} address`} keepMounted>
        <Typography variant="subtitle2" fontWeight={400} mb={2}>
          To transfer digital assets from an external wallet, please use the public address of your receiving wallet. To
          avoid errors, copy and paste the address or scan the QR code.
        </Typography>
        <Divider />
        <Box display="flex" justifyContent="center">
          {walletDetails.address && <S.CustomQRCode value={walletDetails.address} size={200} />}
        </Box>
        <Box display="flex" alignItems="baseline" justifyContent="space-between" pb="14px">
          <Typography fontWeight={500}>{walletDetails.chain === 'ALGORAND' ? 'ALGO' : 'ETH'} address:</Typography>
          {copied && (
            <Alert
              iconMapping={{
                success: <CheckCircleOutlineIcon fontSize="small" />
              }}
            >
              Copied!
            </Alert>
          )}
          {!copied && (
            <IconButton aria-label="copy" onClick={() => copyToClipboard(walletDetails.address)}>
              <ContentCopyIcon />
            </IconButton>
          )}
        </Box>
        <Box>
          <S.StyledWalletAddress mb={2}>{walletDetails.address}</S.StyledWalletAddress>
        </Box>
        <Button
          variant="contained"
          fullWidth
          component="a"
          href={
            walletDetails.chain != ChainName.STELLAR
              ? `${EXPLORER[walletDetails.chain]}/address/${walletDetails.address}`
              : `${EXPLORER[walletDetails.chain]}/account/${walletDetails.address}`
          }
          target="_blank"
          rel="noopener"
        >
          View on Explorer
        </Button>
      </Modal>
      <Divider />
    </>
  )
}

export default Wallets
