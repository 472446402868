import { CognitoUserAttribute } from 'amazon-cognito-identity-js'

export interface UserAttributes {
  sub: string
  email: string
  given_name: string
  family_name: string
  email_verified: string
  business_name?: string
}

export const convertUserAttributesListToLabels = (attributes: CognitoUserAttribute[]): UserAttributes =>
  attributes.reduce(
    (obj, item) =>
      Object.assign(obj, { [item.Name === 'custom:businessName' ? 'business_name' : item.Name]: item.Value }),
    {} as UserAttributes
  )
