import React from 'react'
import { ExpandMore } from '@mui/icons-material'
import { AccordionSummaryProps, AccordionSummary, AccordionDetails, Link, Accordion } from '@mui/material'
import { TabsListUnstyled, TabUnstyled, TabUnstyledProps } from '@mui/base'
import { styled } from '@mui/system'

export const StyledAccordion = styled(Accordion)({
  borderRadius: '10px !important'
})

export const Summary = styled((props: AccordionSummaryProps) => (
  <AccordionSummary expandIcon={<ExpandMore sx={{ fontSize: '20px' }} />} {...props} />
))({
  alignItems: 'center',
  '& .MuiAccordionSummary-content': {
    marginRight: '8px',
    alignItems: 'center'
  }
})

export const Details = styled(AccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  paddingRight: `calc(${theme.spacing(2)} + 28px)`
}))

export const TabsList = styled(TabsListUnstyled)({
  textAlign: 'right',
  marginBottom: '16px'
})

export const Tab = styled((props: TabUnstyledProps) => (
  <TabUnstyled component={Link} href="#" underline="none" color="inherit" {...props} />
))(({ theme }) => ({
  marginLeft: theme.spacing(4),
  ':hover': {
    color: theme.palette.primary.main
  },
  '&.Mui-selected': {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    fontWeight: 500
  }
}))

export const InfoContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
  paddingRight: 0,
  position: 'relative',
  '::before': {
    content: "''",
    position: 'absolute',
    backgroundColor: theme.palette.primary.main,
    left: 0,
    top: 0,
    bottom: 0,
    width: '3px'
  }
}))
