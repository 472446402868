import React, { useEffect, useState } from 'react'
import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import { ResponsiveLine } from '@nivo/line'
import * as S from './styles'
import { FxRateObservation, getFxRateObs } from 'src/common/api'
import { alertNotification } from 'src/ui'

type TimeRange = 'week' | 'month' | 'year'

interface Point {
  x: string
  y: number
}

const timeRangeStartingDate = (timeRange: TimeRange): Date => {
  let today = new Date()
  today = new Date(today.getFullYear(), today.getMonth(), today.getDate())
  const priorDate = {
    year: () => today.setFullYear(today.getFullYear() - 1),
    month: () => today.setDate(today.getDate() - 28),
    week: () => today.setDate(today.getDate() - 7)
  }
  return new Date(priorDate[timeRange]())
}

const FxRateChart: React.FC = () => {
  const [currentFilter, setCurrentFilter] = useState<TimeRange | 'all'>('week')
  const [chartData, setChartData] = useState<Point[]>([])
  const [observations, setObservations] = useState<FxRateObservation[]>([])

  const fetchOrders = async () => {
    try {
      const response = await getFxRateObs()
      setObservations(response.data.observations)
    } catch (err) {
      alertNotification('Fetching FX rates for graph failed. Please try again later.', 'error')
    }
  }

  useEffect(() => {
    fetchOrders()
  }, [])

  useEffect(() => {
    if (observations.length) {
      changeFilter('month')
    }
  }, [observations])

  const changeFilter = (timeRange: TimeRange | 'all') => {
    let filteredObservations = observations
    let priorDate: Date | null = null

    if (timeRange !== 'all') {
      priorDate = timeRangeStartingDate(timeRange)
      filteredObservations = filteredObservations.filter(
        (observation) => new Date(observation.d.replaceAll('-', '/')) > (priorDate as Date)
      )
    }

    const data = filteredObservations.map(({ FXUSDCAD, d }) => ({ x: d.replaceAll('-', '/'), y: Number(FXUSDCAD.v) }))

    setCurrentFilter(timeRange)
    setChartData(data)
  }

  return (
    <>
      <Box display="flex" mb="40px" alignItems="center" justifyContent="space-between">
        <Typography variant="h6" fontWeight={400}>
          CAD / USD Chart
        </Typography>
        <Box>
          {['Week', 'Month', 'Year', 'All'].map((timeRange) => (
            <S.Filter
              key={timeRange}
              label={timeRange}
              active={currentFilter === timeRange.toLowerCase()}
              onClick={() => changeFilter(timeRange.toLowerCase() as TimeRange | 'all')}
            />
          ))}
        </Box>
      </Box>
      <Box height="400px" display="flex" justifyContent="center" alignItems="center" flexDirection="column">
        {chartData.length && <Graph data={chartData} timeRange={currentFilter} />}
      </Box>
    </>
  )
}

interface GraphProps {
  data: Point[]
  timeRange: TimeRange | 'all'
}

const Graph: React.FC<GraphProps> = ({ data, timeRange }: GraphProps) => {
  const getDateFormat = (timeRange: TimeRange | 'all') => {
    const isMonthly = () => {
      return '%b %Y'
    }
    const isDaily = () => {
      return '%b %d'
    }
    const formats = {
      all: isMonthly,
      year: isMonthly,
      month: isDaily,
      week: isDaily
    }
    return formats[timeRange]()
  }

  return (
    <ResponsiveLine
      data={[
        {
          id: 'total holdings',
          data
        }
      ]}
      colors="#BC19ff"
      enableArea
      areaOpacity={0.1}
      margin={{ top: 40, left: 58, bottom: 10 }}
      xScale={{
        type: 'time',
        format: '%Y/%m/%d',
        useUTC: false,
        precision: 'day'
      }}
      xFormat="time:%b %d, %Y"
      yScale={{
        type: 'linear',
        min: 'auto'
      }}
      // yFormat=" >-,.4f"
      axisBottom={null}
      axisTop={{
        format: getDateFormat(timeRange),
        tickValues: 5,
        tickSize: 0,
        tickPadding: 24
      }}
      axisLeft={{
        tickSize: 0,
        tickPadding: 24,
        format: (value) => Number(value).toLocaleString('en', { minimumFractionDigits: 3, maximumFractionDigits: 3 })
      }}
      enablePoints={false}
      useMesh={true}
      enableGridX={false}
      enableGridY={false}
      tooltip={({
        point: {
          data: { xFormatted, yFormatted }
        }
      }) => (
        <S.Tooltip elevation={2} sx={{ padding: '5px 9px' }}>
          <Typography fontWeight={500}>1 USD = {yFormatted} CAD</Typography>
          <Typography variant="body2" color="grey.600">
            {xFormatted}
          </Typography>
        </S.Tooltip>
      )}
    />
  )
}

export default FxRateChart
