import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const InteracIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      x="0px"
      y="0px"
      viewBox="0 0 530.28 530.28"
      enableBackground="new 0 0 530.28 530.28"
    >
      <g>
        <path
          className="st0"
          d="M530.28,447.71c0,45.6-36.97,82.57-82.57,82.57H81.91C36.67,530.28,0,493.61,0,448.38V78.96
    C0,35.35,35.35,0,78.96,0h372.36c43.61,0,78.96,35.35,78.96,78.95V447.71z"
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#ffb92a"
        ></path>
        <path
          className="st1"
          d="M50.03,206.39v134.76l-24.05,5.4V211.79L50.03,206.39z M113.7,223.77c-14.84,3.33-23.95,20.04-23.95,20.04
    v-12.56l-23.34,5.09v80.4c2.85-1.09,5.94-1.72,9.17-1.72c5.2,0,10.03,1.56,14.07,4.22v-39.25c0.18-18.8,5.57-29.78,16.83-32.31
    c9.89-2.22,13.6,5.15,13.44,16.53v61.39l22.91-5.17l0.16-61.2C143.19,229.26,131.1,219.86,113.7,223.77z M148.38,217.93l13.13-2.95
    v-18.86l23.22-5.17v18.82l16.53-3.72l-0.23,20.38l-16.55,3.72l-0.12,51.03c0.11,6.23,1.2,10.09,8.19,8.52
    c2.73-0.61,4.91-1.3,7.4-2.25l0.06,19.14c-5.8,2.75-9.3,3.86-14.07,4.93c-13.28,3.07-24.74-5.57-24.74-23.22l0.08-52.94
    l-13.14,2.95L148.38,217.93z M229.24,256.52c0.01,2.15-0.05,3.91,0.3,5.98c1.74,10.34,6.82,18.37,17.27,16.03
    c9.6-2.15,12.79-8.53,14.63-15.57l24.33-5.46c-2.54,15.59-10.97,35.24-37.53,41.19c-23.82,5.34-38.22-8.41-42.17-31.91
    c-4.33-25.76,2.38-64.14,37.68-72.06c26.05-5.84,38.24,9.57,41.59,29.5c1.33,7.9,1.25,13.77,0.79,19.53L229.24,256.52z
      M263.04,231.19c0.06-1.77-0.19-3.27-0.38-4.4c-1.42-8.46-6.08-15.03-16.36-12.72c-10.63,2.38-15.87,11.36-16.84,24.65
    L263.04,231.19z M343.59,201.57c-16.17,3.83-23.97,15.1-24.06,37.2l-0.21,42l-23.2,5.21V184.74l22.39-5.02
    c0.29,2.48,0.82,9.98,0.78,18.2c3.77-12.96,8.94-19.62,24.29-23.06V201.57z M426,256.08l-0.13,0.03l-23.29,5.26
    c-1.6-2.91-3.21-7.53-3.91-10.78c-3.3,6.99-7.82,15.04-24.2,18.72c-17.4,3.91-27.29-7.94-29.45-19.38
    c-5.11-26.99,14.26-40.1,34.08-45.15c7.13-1.82,13.13-2.89,19.9-4.6l-0.34-6.07c-0.15-7.39-1.86-16.39-14.31-13.59
    c-11.09,2.49-11.67,9.51-12.66,14.62l-23.71,5.32c0.3-16.29,7.41-32.01,34.87-38.18c21.49-4.83,39.36,1.55,39.2,27.97v48.02
    c0,0,0.57,8.1,1.49,12C424.47,254.23,426,256.08,426,256.08z M398.64,216.59c-5.8,1.3-10.68,2.79-15.17,4.39
    c-12.54,5.16-16.77,11-15.21,19.24c1.06,5.62,5.12,9.21,12.63,7.52c16.03-3.6,17.43-18.77,17.7-29.57L398.64,216.59z M509.69,203.7
    c-1.3,19.44-10.37,37.31-36.47,43.17c-22.35,5.02-36.85-6.77-41.25-30.02c-5.32-28.12,2.72-66.47,35.47-73.82
    c28.66-6.44,40.04,14.27,41.19,26.91l-22.52,5.06c-1.59-7.46-5.26-14.65-15.67-12.31c-16.38,3.68-17.75,32.13-14.81,47.69
    c2.55,13.5,8.64,18.38,16.99,16.51c7.68-1.72,12.08-7.6,13.85-17.96L509.69,203.7z M128.7,375.8l-32.84-22.35
    c0,0-0.01,52.44-0.01,54.85c0,1.68-1.37,3.05-3.04,3.05c-1.68,0-3.03-1.37-3.03-3.05c0-0.59-0.07-59.97-0.07-67.22
    c0-7.77-6.31-14.1-14.08-14.1c-7.8,0-14.1,6.33-14.1,14.1l0.02,117.11c0,21.02,17.11,38.16,38.14,38.16l2.52,0
    c20.74,0,37.39-16.81,37.39-37.56c0,0,0.02-61.18,0.02-61.8C139.62,388.86,135.13,380.73,128.7,375.8z M33.41,358.34
    c-5.81,1.4-9.4,7.24-8,13.05s7.24,9.4,13.05,8l16.41-3.94v-22.27L33.41,358.34z M33.41,387.03c-5.81,1.4-9.4,7.24-8,13.05
    s7.24,9.4,13.05,8l16.41-3.94v-22.27L33.41,387.03z M25.41,428.78c1.39,5.81,7.24,9.4,13.05,8l16.41-3.94v-22.27l-21.46,5.15
    C27.6,417.12,24.02,422.97,25.41,428.78z"
          fill="#2d2926"
        ></path>
      </g>
    </SvgIcon>
  )
}
