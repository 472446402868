import { useState } from 'react'
import { isEmailValid } from 'src/common/helpers'
import { useNavigate } from 'react-router-dom'
import { FormikHelpers } from 'formik'
import { useAuth } from 'src/common/context'

interface Values {
  email: string
  password: string
}

interface State {
  error?: string
  success?: string
  preferredMfa?: string
}

export const initialValues: Values = {
  email: '',
  password: ''
}

export const validate = (values: Values) => {
  const errors: Partial<Values> = {}

  if (!values.email) {
    errors.email = 'Required'
  } else if (!isEmailValid(values.email)) {
    errors.email = 'Invalid email address'
  }
  if (!values.password) {
    errors.password = 'Required'
  }

  return errors
}

const useLogin = () => {
  const navigate = useNavigate()
  const mfaPath = { pathname: '/mfa' }

  const { login, getPreferredMfa, forgotPassword, resendEmail } = useAuth()

  const [state, setState] = useState<State>({
    error: undefined,
    success: undefined,
    preferredMfa: undefined
  })

  const onSubmit = (values: Values, { setSubmitting, resetForm }: FormikHelpers<Values>) => {
    const { email, password } = values
    login(email.toLowerCase(), password, async (authSuccess, response) => {
      if (authSuccess) {
        setState({ ...state })
        resetForm()
        setSubmitting(false)
        if (
          response !== 'SOFTWARE_TOKEN_MFA' &&
          (process.env.REACT_APP_API_URL?.includes('staging') || process.env.REACT_APP_API_URL?.includes('localhost'))
        ) {
          navigate({ pathname: '/' }, { replace: true })
        } else if (response === 'SOFTWARE_TOKEN_MFA') {
          navigate(mfaPath, { replace: true })
        } else {
          const preferredMfa = await getPreferredMfa()
          // opens modal for setup mfa
          setState({ ...state, preferredMfa })
        }
      } else {
        if (response == 'User is not confirmed.') {
          resendEmail(email.toLowerCase())
          setState({
            error: response + ' A verification link has been sent to your email address.',
            success: undefined
          })
        } else if (response == 'Password reset required for the user') {
          forgotPassword(email.toLowerCase())
          setState({
            error: 'Please reset your password. A password reset email has just been sent to your inbox.',
            success: undefined
          })
        } else {
          setState({
            error: 'Invalid email or password',
            success: undefined
          })
        }
        setSubmitting(false)
      }
    })
  }

  return { state, setState, onSubmit }
}

export default useLogin
