import { Button } from '@mui/material'
import { styled } from '@mui/system'
import { QRCodeCanvas } from 'qrcode.react'

export const StyledButton = styled(Button)({
  boxSizing: 'border-box',
  height: '32px',
  border: '1px solid #7B7B7B',
  borderRadius: '8px',
  color: '#4F4F4F',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '13px',
  lineHeight: '16px'
})

export const StyledButton2 = styled(Button)({
  boxSizing: 'border-box',
  height: '32px',
  border: '1px solid #FFFFFF',
  borderRadius: '8px',
  color: '#4F4F4F',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '13px',
  lineHeight: '16px',
  padding: '8px'
})

export const CustomQRCode = styled(QRCodeCanvas)({
  margin: '50px'
})
