import {
  Typography,
  TextField,
  InputBaseComponentProps,
  Button,
  Menu,
  MenuItem,
  ListItemIcon,
  SvgIconProps,
  Alert,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Box,
  Stack,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton
} from '@mui/material'
import React from 'react'
import { Container, Modal, SummaryTable } from 'src/ui'
import * as S from './styles'
import { LoadingButton } from '@mui/lab'
import useRequest from './Request.State'
import NumberFormat from 'react-number-format'
import { formatNumber } from 'src/common/helpers'
import RequestInitiated from './RequestInitiated'
import ConfirmRequest from './ConfirmRequest'
import RequestLoading from './RequestLoading'
import { FlagIcon, FromToIcon, NewContactIcon, QCADIcon, USDCIcon, USFlagIcon } from 'src/common/assets'
import { ExpandMore, NavigateNext, RemoveCircleOutline } from '@mui/icons-material'
import { BankingCountry, ChainAsset, ChainName, FiatCurrency, RequestType, WalletType } from 'src/common/static'
import ChoosePayment from './ChoosePayment'
import Compliance from './Compliance'
import Billpay from './Billpay'
import Eft from './Eft'
import Interac from './Interac'
import { ENABLE_PURCHASE_FLOW } from 'src/common/api/flagsmith/grapesFeatureFlags'
import flagsmith from 'flagsmith'

interface Asset {
  name: ChainAsset
  Icon: React.FC<SvgIconProps<'svg', Record<string, unknown>>>
}

interface Currency {
  name: FiatCurrency
  Icon: React.FC<SvgIconProps<'svg', Record<string, unknown>>>
}

const assets = [
  {
    name: ChainAsset.QCAD,
    Icon: QCADIcon
  },
  {
    name: ChainAsset.USDC,
    Icon: USDCIcon
  }
]

const fiatCurrencies = [
  {
    name: FiatCurrency.CAD,
    Icon: FlagIcon
  },
  {
    name: FiatCurrency.USD,
    Icon: USFlagIcon
  }
]

interface RequestProps {
  type: RequestType
}

const Request: React.FC<RequestProps> = ({ type }: RequestProps) => {
  const {
    value,
    asset,
    errors,
    modals,
    showIcomply,
    order,
    exchangeRatesTimestamp,
    paymentMethod,
    selectedWalletBalance,
    isOptedIn,
    loading,
    anchorElAsset,
    selectedWallet,
    fiatCurrency,
    anchorElFiatCurrency,
    interacLink,
    completeLater,
    search,
    filteredList,
    recipient,
    recipientBank,
    handleRequest,
    showIcomplyWidget,
    setShowIcomply,
    choosePayment,
    handleBillpay,
    handleEft,
    handleInterac,
    handleConfirmRequest,
    handleChange,
    handleClose,
    handleOpenAssetMenu,
    handleCloseAssetMenu,
    chooseAsset,
    handleOpenFiatCurrencyMenu,
    handleCloseFiatCurrencyMenu,
    chooseFiatCurrency,
    calculateFiatAmount,
    calculateFxRate,
    handleSearch,
    handleOpenBankDetails,
    handleCloseBankDetails,
    handleSelectRecipientBank,
    handleRemoveRecipientBank
  } = useRequest(type)
  const isPurchase = type === RequestType.PURCHASE
  const isSend = type === RequestType.SEND
  const isSendOther = type === RequestType.SEND_THIRD_PARTY

  const currentAsset = assets.find((item) => item.name === asset) as Asset
  const currentFiatCurrency = fiatCurrencies.find((item) => item.name === fiatCurrency) as Currency

  const networkFee =
    // isPurchase && selectedWallet.chain === ChainName.ETHEREUM ? exchangeRates.eth : 0
    0

  const fxRate = calculateFxRate(asset, fiatCurrency)

  // TO-DO: networkFee not being calculated currently in calculateFiatAmount method, update it when we start supporting networkFees
  const fiatAmount = value ? formatNumber(calculateFiatAmount(asset, fiatCurrency)) : 0

  const assetBalance =
    selectedWallet.chain == ChainName.ETHEREUM
      ? selectedWalletBalance && selectedWalletBalance[asset === ChainAsset.QCAD ? 'qcad' : 'usdc']
      : selectedWalletBalance && selectedWalletBalance[asset === ChainAsset.QCAD ? 'qcad_xlm' : 'usdc_xlm']

  const walletError =
    selectedWallet.walletType === WalletType.MYALGO
      ? 'MyAlgo wallet is disabled due to vulnerability, please choose another wallet'
      : errors.walletError

  const [isPurchaseFlowEnabled, setIsPurchaseFlowEnabled] = React.useState(false)

  const getEnablePurchaseBillpayFlag = async () => {
    const flag = flagsmith.getAllFlags()[ENABLE_PURCHASE_FLOW]
    if (flag.enabled) {
      setIsPurchaseFlowEnabled(flag.enabled)
    }
  }

  React.useEffect(() => {
    getEnablePurchaseBillpayFlag()
  }, [])

  return (
    <>
      {!order ? (
        <Container error={walletError}>
          <S.Row>
            <Typography variant="h5" fontWeight={600}>
              {isPurchase && 'Purchase'}
              {isSend && 'Send'}
              {isSendOther && (recipient ? 'Send' : 'Please Select a Recipient')}
            </Typography>
          </S.Row>
          {isSendOther && !recipientBank && (
            <Box>
              <S.StyledButton startIcon={<NewContactIcon />} variant="outlined" href="/address-book">
                Add a New Recipient
              </S.StyledButton>
              <Divider sx={{ my: 2 }} />
              <TextField
                value={search}
                onChange={handleSearch}
                name="filter"
                placeholder="Search for a recipient..."
                fullWidth
                size="small"
              />
              <List
                sx={{
                  position: 'relative',
                  overflow: 'auto',
                  maxHeight: 400
                }}
              >
                {filteredList.map((recipient, idx) => (
                  <ListItem key={idx}>
                    <ListItemButton onClick={() => handleOpenBankDetails(recipient)}>
                      <ListItemText
                        primary={`${recipient.contactDetails.firstName} ${recipient.contactDetails.lastName}`}
                      />
                      <NavigateNext />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </Box>
          )}
          {(!isSendOther || recipientBank) && (
            <Stack>
              <S.Row>
                {selectedWalletBalance && (
                  <S.WalletBalance>
                    Balance: {assetBalance?.toString() ? formatNumber(assetBalance as number) : 'not opted-in'}
                  </S.WalletBalance>
                )}
                <S.InputLabel>Asset</S.InputLabel>
                <TextField
                  value={value}
                  onChange={handleChange}
                  name="asset"
                  placeholder="0"
                  error={Boolean(errors.inputError)}
                  helperText={errors.inputError}
                  InputProps={{
                    inputComponent: NumberFormatCustom as unknown as React.ElementType<InputBaseComponentProps>,
                    endAdornment: (
                      <S.StyledInputAdornment position="end">
                        <Button
                          size="small"
                          startIcon={<currentAsset.Icon />}
                          endIcon={<ExpandMore />}
                          color="inherit"
                          onClick={handleOpenAssetMenu}
                        >
                          {currentAsset.name}
                        </Button>
                        <Menu
                          id="menu-asset-type"
                          anchorEl={anchorElAsset}
                          keepMounted
                          open={Boolean(anchorElAsset)}
                          onClose={handleCloseAssetMenu}
                        >
                          {assets.map(({ name, Icon }) => (
                            <MenuItem
                              key={name}
                              onClick={() => chooseAsset(name)}
                              disabled={name === ChainAsset.QCAD && selectedWallet.chain === ChainName.ALGORAND}
                            >
                              <ListItemIcon>
                                <Icon fontSize="small" />
                              </ListItemIcon>
                              <Typography>{name}</Typography>
                            </MenuItem>
                          ))}
                        </Menu>
                      </S.StyledInputAdornment>
                    )
                  }}
                  fullWidth
                  size="medium"
                />
              </S.Row>
              <S.Row display="flex" justifyContent="center">
                <FromToIcon sx={{ transform: isPurchase ? 'rotateX(180deg)' : undefined }} fontSize="large" />
              </S.Row>
              <S.Row>
                <S.InputLabel>Amount</S.InputLabel>
                <S.AmountTextField
                  onChange={handleChange}
                  name="amount"
                  placeholder="0"
                  value={value ? fiatAmount : ''}
                  InputProps={{
                    endAdornment: (
                      <S.StyledInputAdornment position="end">
                        <Button
                          size="small"
                          startIcon={<currentFiatCurrency.Icon />}
                          endIcon={<ExpandMore />}
                          color="inherit"
                          onClick={handleOpenFiatCurrencyMenu}
                          disabled={isSendOther}
                        >
                          {currentFiatCurrency.name}
                        </Button>
                        <Menu
                          id="menu-fiat-currency"
                          anchorEl={anchorElFiatCurrency}
                          keepMounted
                          open={Boolean(anchorElFiatCurrency)}
                          onClose={handleCloseFiatCurrencyMenu}
                        >
                          {fiatCurrencies.map(({ name, Icon }) => (
                            <MenuItem key={name} onClick={() => chooseFiatCurrency(name)}>
                              <ListItemIcon>
                                <Icon fontSize="small" />
                              </ListItemIcon>
                              <Typography>{name}</Typography>
                            </MenuItem>
                          ))}
                        </Menu>
                      </S.StyledInputAdornment>
                    )
                  }}
                  disabled
                  fullWidth
                  size="medium"
                />
              </S.Row>
              {isSendOther && recipient && (
                <Box>
                  <S.InputLabel>Recipient</S.InputLabel>
                  <TextField
                    value={`${recipient.contactDetails.firstName} ${recipient.contactDetails.lastName}`}
                    name="recipient"
                    fullWidth
                    size="medium"
                    disabled
                    InputProps={{
                      endAdornment: (
                        <S.StyledInputAdornment position="end">
                          <IconButton onClick={handleRemoveRecipientBank}>
                            <RemoveCircleOutline color="error" />
                          </IconButton>
                        </S.StyledInputAdornment>
                      )
                    }}
                  />
                </Box>
              )}
              <S.StyledDivider />
              <S.Row>
                {isSendOther && recipient && recipientBank && (
                  <Box mb={2}>
                    <S.Summary>Recipient Details</S.Summary>
                    <SummaryTable
                      data={[
                        {
                          key: 'Recipient',
                          value: `${recipient.contactDetails.firstName} ${recipient.contactDetails.lastName}`
                        },
                        {
                          key: 'Bank Country',
                          value: recipientBank.country
                        },
                        {
                          key: 'Bank Name',
                          value: recipientBank.name
                        },
                        {
                          key: 'Routing Number/SWIFT BIC Code',
                          value:
                            recipientBank.country === BankingCountry.CAN
                              ? recipientBank.routingNum
                              : recipientBank.swiftBicCode
                        },
                        {
                          key: 'Account Number',
                          value: recipientBank.accountNum
                        }
                      ]}
                    />
                  </Box>
                )}
                <S.Summary>Summary</S.Summary>
                <SummaryTable
                  data={[
                    {
                      key: 'You Pay',
                      value: (
                        <b>
                          {isPurchase && `$${fiatAmount || '0.00'} ${fiatCurrency}`}
                          {(isSend || isSendOther) && `${formatNumber(value) || '0.00'} ${asset}`}
                        </b>
                      )
                    },
                    {
                      key: 'Order Fee',
                      value: '$0.00'
                    },
                    {
                      key: isPurchase ? 'Network Fee' : 'Transfer Fee',
                      value: isPurchase ? `$${formatNumber(networkFee)}` : '$0.00'
                    },
                    {
                      key: 'Indicative FX Rate',
                      value: `1 ${asset} ≈ ${formatNumber(fxRate, undefined, 4)} ${fiatCurrency}`
                    },
                    {
                      key: 'Indicative FX Rate As Of',
                      value:
                        exchangeRatesTimestamp.toLocaleDateString() + ' ' + exchangeRatesTimestamp.toLocaleTimeString()
                    },
                    {
                      key: 'You Receive',
                      value: (
                        <b>
                          {isPurchase && `≈ ${formatNumber(value) || '0.00'} ${asset}`}
                          {(isSend || isSendOther) && `≈ $${fiatAmount || '0.00'} ${fiatCurrency}`}
                        </b>
                      )
                    }
                  ]}
                />
              </S.Row>
              {!isOptedIn && isPurchase && asset !== ChainAsset.QCAD && (
                <S.Row>
                  <Alert severity="info">
                    To be able to purchase {asset} on algorand, you need to opt-in to the asset. You only need to do
                    this once per wallet account to validate that it has access to the {asset} asset.
                  </Alert>
                </S.Row>
              )}
              {isPurchase && fiatCurrency === FiatCurrency.USD && (
                <S.Row>
                  <Alert severity="info">
                    Purchasing with USD is not currently supported. Please contact support for more information.
                  </Alert>
                </S.Row>
              )}
              <LoadingButton
                variant="contained"
                size="large"
                loading={loading}
                fullWidth
                onClick={handleRequest}
                disabled={
                  (!isPurchaseFlowEnabled && isPurchase) ||
                  Boolean(errors.inputError) ||
                  (Number(value) === 0 && isOptedIn) ||
                  (asset === ChainAsset.QCAD && selectedWallet.chain === ChainName.ALGORAND) ||
                  (fiatCurrency === FiatCurrency.USD && isPurchase) ||
                  selectedWallet.walletType === WalletType.MYALGO
                }
              >
                {isOptedIn ? (
                  <>
                    {isPurchase && 'Purchase'}
                    {(isSend || isSendOther) && 'Send'}
                  </>
                ) : (
                  'Opt In'
                )}
              </LoadingButton>
            </Stack>
          )}
        </Container>
      ) : (
        <RequestInitiated
          order={order}
          type={type}
          paymentMethod={paymentMethod}
          redirectLink={interacLink}
          completeLater={completeLater}
        />
      )}
      <ChoosePayment
        open={modals.choosePayment}
        onClose={() => handleClose('choosePayment')}
        choosePayment={choosePayment}
        type={isPurchase ? 'Purchase' : 'Redeem'}
        fiatCurrency={fiatCurrency}
        fiatAmount={calculateFiatAmount(asset, fiatCurrency)}
      />
      {modals.interac && (
        <Interac
          close={() => handleClose('interac')}
          handleInterac={handleInterac}
          amount={fiatAmount as string}
          fiatCurrency={fiatCurrency}
          isPurchase={isPurchase}
        />
      )}
      {modals.billpay && (
        <Billpay
          close={() => handleClose('billpay')}
          handleBillpay={handleBillpay}
          amount={fiatAmount as string}
          fiatCurrency={fiatCurrency}
        />
      )}
      {modals.eft && (
        <Eft
          close={() => handleClose('eft')}
          handleEft={handleEft}
          amount={fiatAmount as string}
          type={type}
          asset={asset}
          fiatCurrency={fiatCurrency}
        />
      )}
      <ConfirmRequest
        open={modals.confirm}
        onClose={() => handleClose('confirm')}
        asset={currentAsset}
        cryptoAmount={value}
        fiatAmount={fiatAmount}
        fxRate={fxRate}
        networkFee={networkFee}
        confirmRequest={handleConfirmRequest}
        type={type}
        exchangeRatesTimestamp={exchangeRatesTimestamp}
        fiatCurrency={fiatCurrency}
        selectedWalletAddress={selectedWallet.address}
        selectedWalletChain={selectedWallet.chain}
      />
      <RequestLoading
        open={modals.requestLoading}
        asset={asset}
        cryptoAmount={value}
        fiatAmount={fiatAmount}
        type={type}
        fiatCurrency={fiatCurrency}
      />
      <Modal
        open={modals.kycPortal}
        onClose={() => {
          handleClose('kycPortal')
          setShowIcomply(false)
        }}
        title="KYC"
        keepMounted
        scroll="body"
      >
        <div id="icomply" style={{ display: showIcomply ? 'block' : 'none' }}></div>
        {!showIcomply && <Compliance showIcomply={showIcomplyWidget} />}
      </Modal>
      <Modal
        open={modals.bankDetails}
        onClose={handleCloseBankDetails}
        title="Beneficiary Bank Accounts"
        maxWidth="md"
        fullWidth
        keepMounted
      >
        <Typography variant="subtitle2" color="grey.500" mb={2}>
          Please choose from the following bank accounts to send to
        </Typography>
        <Divider sx={{ marginBottom: '24px' }} />
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Bank Country</TableCell>
                <TableCell>Acc Holder Name</TableCell>
                <TableCell>Bank Name</TableCell>
                <TableCell>Routing Num</TableCell>
                <TableCell>ACH Code</TableCell>
                <TableCell>SWIFT/BIC Code</TableCell>
                <TableCell>Account Num</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            {recipient &&
              recipient.bankDetails.map((bank, idx) => (
                <TableBody key={idx}>
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell component="th" scope="row">
                      <Box display="flex" alignItems="center">
                        {bank.country === BankingCountry.CAN ? (
                          <FlagIcon fontSize="small" sx={{ mr: 1 }} />
                        ) : (
                          <USFlagIcon fontSize="small" sx={{ mr: 1 }} />
                        )}
                        {bank.country}
                      </Box>
                    </TableCell>
                    <TableCell>{bank.accHolderName}</TableCell>
                    <TableCell>{bank.name}</TableCell>
                    <TableCell>{bank.country === BankingCountry.CAN && bank.routingNum}</TableCell>
                    <TableCell>{bank.country === BankingCountry.US && bank.achCode}</TableCell>
                    <TableCell>{bank.country === BankingCountry.US && bank.swiftBicCode}</TableCell>
                    <TableCell>{bank.accountNum}</TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleSelectRecipientBank(bank)}>
                        <NavigateNext />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                </TableBody>
              ))}
          </Table>
          {recipient && !recipient.bankDetails.length && (
            <Box display="flex" alignItems="center" justifyContent="center" mt={4}>
              <Typography variant="h6">Recipient has no blockchain addresses</Typography>
            </Box>
          )}
        </TableContainer>
      </Modal>
    </>
  )
}

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
}

const NumberFormatCustom = React.forwardRef<NumberFormat<Record<string, unknown>>, CustomProps>((props, ref) => {
  const { onChange, ...other } = props

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        })
      }}
      thousandSeparator
      isNumericString
      allowNegative={false}
      decimalScale={2}
      fixedDecimalScale
    />
  )
})

NumberFormatCustom.displayName = 'NumberFormatCustom'

export default Request
