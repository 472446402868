import React from 'react'
import { Alert, Container } from '@mui/material'
import * as S from './styles'
import { Box } from '@mui/system'

interface ContainerProps {
  maxWidth?: number | string
  title?: string
  error?: string
  success?: string
  paperPadding?: number | string
  marginTop?: number | string
  marginBottom?: number | string
  children: React.ReactNode
}

const CustomContainer: React.FC<ContainerProps> = ({
  maxWidth,
  title,
  error,
  success,
  paperPadding,
  marginTop,
  marginBottom,
  children
}: ContainerProps) => {
  return (
    <Container
      maxWidth={false}
      sx={{ maxWidth: maxWidth || '550px', marginTop: marginTop || '90px', marginBottom: marginBottom || '60px' }}
    >
      {title && (
        <S.Title variant="h4" textAlign="center">
          {title}
        </S.Title>
      )}
      <S.StyledPaper variant="card" paperPadding={paperPadding}>
        {(error || success) && (
          <Box mb="24px" maxWidth="550px">
            <Alert severity={error ? 'error' : 'success'}>{error || success}</Alert>
          </Box>
        )}
        {children}
      </S.StyledPaper>
    </Container>
  )
}

export default CustomContainer
