import { SvgIcon, SvgIconProps } from '@mui/material'
import React from 'react'

export const BankIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.83337 28.9583V14.8333H8.33337V28.9583H5.83337ZM15.9167 28.9583V14.8333H18.4167V28.9583H15.9167ZM0.333374 33.9583V31.4583H33.6667V33.9583H0.333374ZM25.6667 28.9583V14.8333H28.1667V28.9583H25.6667ZM0.333374 12.3333V10.125L17 0.625L33.6667 10.125V12.3333H0.333374ZM5.91671 9.83333H28.0834H5.91671ZM5.91671 9.83333H28.0834L17 3.5L5.91671 9.83333Z"
        fill="black"
      />
    </SvgIcon>
  )
}
