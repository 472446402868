import { OrderDetails } from 'src/common/api'
import { calculateFxRate, formatDate, formatNumber, formatTime, trimId } from 'src/common/helpers'
import { PaymentMethod } from './payment-method'
import { OrderStatus } from './status'

interface Detail {
  key: string
  value: string
}

export interface FormattedOrderDetails {
  orderDetails: Detail[]
  transferDetails: Detail[]
  transactionDetails: Detail[]
}

export const formatOrder = (order: OrderDetails): FormattedOrderDetails => {
  const isPurchase = order.type === 'PURCHASE'
  const transaction = order.transactions[0]
  const transfer = order.transfers[0]
  const orderDetails: Detail[] = transaction && [
    {
      key: 'Status',
      value: OrderStatus[order.status]
    },
    {
      key: 'Action',
      value: `${transaction.asset} ${isPurchase ? 'Purchase' : 'Send'}`
    },
    {
      key: 'Date & Time (UTC)',
      value: `${formatDate(order.createdAt)} ${formatTime(order.createdAt)}`
    },
    {
      key: 'Wallet',
      value: trimId(transaction.address) as string
    },
    {
      key: `Amount (${transaction.asset}/${order.fiatCurrency})`,
      value: `${order.cryptoAmount} ${transaction.asset} / $${order.fiatAmount} ${order.fiatCurrency}`
    },
    {
      key: 'FX Rate',
      value: order.fxRate
        ? String(order.fxRate)
        : formatNumber(
            Number(
              calculateFxRate(
                order.fiatCurrency,
                transaction.asset,
                Number(order.fiatAmount),
                Number(order.cryptoAmount)
              )
            ),
            undefined,
            4
          )
    },
    {
      key: 'Order Fee',
      value: '$0.00'
    },
    {
      key: 'Order Number',
      value: String(order.id)
    }
  ]

  const transferDetails: Detail[] = transfer && [
    {
      key: 'Status',
      value: transfer.status
    },
    {
      key: 'Date & Time (UTC)',
      value: `${formatDate(transfer.createdAt)} ${formatTime(transfer.createdAt)}`
    },
    {
      key: 'Payment Method',
      value: PaymentMethod[order.paymentMethod]
    },
    {
      key: `Amount (${transfer.fiatCurrency})`,
      value: `$${transfer.amount} ${transfer.fiatCurrency}`
    },
    {
      key: 'Transfer ID',
      value: transfer.referenceId
    }
  ]

  const transactionDetails: Detail[] = transaction && [
    {
      key: 'Status',
      value: transaction.status
    },
    {
      key: 'Date & Time (UTC)',
      value: `${formatDate(transaction.createdAt)} ${formatTime(transaction.createdAt)}`
    },
    {
      key: `Amount (${transaction.asset})`,
      value: `${transaction.amount} ${transaction.asset}`
    },
    {
      key: 'Transaction ID',
      value: transaction.referenceId
    }
  ]

  return { orderDetails, transferDetails, transactionDetails }
}
