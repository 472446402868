import { Button, InputAdornment, styled, Typography } from '@mui/material'

export const StyledInputAdornment = styled(InputAdornment)({
  color: 'inherit',
  fontSize: '1rem',
  fontWeight: 600,
  '& > .MuiSvgIcon-root': {
    marginRight: 8
  }
})

export const WalletBalance = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  float: 'right',
  fontSize: '14px',
  marginTop: '2px'
}))

export const StyledButton = styled(Button)({
  boxSizing: 'border-box',
  height: '32px',
  border: '1px solid #FFFFFF',
  borderRadius: '8px',
  color: '#4F4F4F',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '13px',
  lineHeight: '16px'
})

export const Summary = styled(Typography)({
  fontWeight: 500,
  marginBottom: 4
})
