import { Button, DialogProps, Typography } from '@mui/material'
import React from 'react'
import { Modal } from 'src/ui'
import { trimId } from '../../../../common/helpers'
import { ChainName, WalletType } from '../../../../common/static'

interface AttestOwnershipProps extends DialogProps {
  storeWallet: () => void
  fetchedWallet: {
    address: string
    name: string
    chain: ChainName
    walletType: WalletType
  }
}

const AttestOwnership: React.FC<AttestOwnershipProps> = ({
  storeWallet,
  fetchedWallet,
  ...props
}: AttestOwnershipProps) => {
  return (
    <Modal {...props} title="Wallet Ownership">
      <Typography mb="24px">
        Please confirm that the wallet {trimId(fetchedWallet.address)} being connected to the Grapes platform is under
        your sole control and ownership.
      </Typography>
      <Button variant="contained" fullWidth onClick={storeWallet}>
        Confirm
      </Button>
    </Modal>
  )
}

export default AttestOwnership
