import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const DeleteContactIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M26.1 84C24.45 84 23.0375 83.4125 21.8625 82.2375C20.6875 81.0625 20.1 79.65 20.1 78V21H16V15H34.8V12H61.2V15H80V21H75.9V78C75.9 79.6 75.3 81 74.1 82.2C72.9 83.4 71.5 84 69.9 84H26.1ZM69.9 21H26.1V78H69.9V21ZM36.7 69.4H42.7V29.5H36.7V69.4ZM53.3 69.4H59.3V29.5H53.3V69.4Z"
        fill="#D3080C"
      />
    </SvgIcon>
  )
}
