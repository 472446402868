import { Button, ButtonProps, Container, Paper } from '@mui/material'
import { styled } from '@mui/system'

export const StyledContainer = styled(Container)({
  marginTop: 60,
  paddingBottom: 60
})

export const StyledPaper = styled(Paper)({
  padding: '40px 40px 70px'
})

interface TabProps extends ButtonProps {
  active?: boolean
}

export const Tab = styled(Button)<TabProps>(({ theme, active }) => ({
  borderRadius: 99,
  fontSize: '.9rem',
  color: theme.palette.grey['600'],
  backgroundColor: 'transparent',
  ':hover, :active': {
    color: theme.palette.text.primary,
    backgroundColor: active ? theme.palette.primary[99] : 'transparent'
  },
  ...(active && { backgroundColor: theme.palette.primary[99], color: theme.palette.text.primary }),
  marginRight: 24,
  ':last-of-type': {
    marginRight: 0
  }
}))
