import MyAlgoConnect from '@randlabs/myalgo-connect'
import algosdk from 'algosdk'
import axios from 'axios'
import { useMetaMask } from 'metamask-react'
import React, { useEffect, useState } from 'react'
import {
  checkOrderEligibility,
  CreateOrderDTO,
  createPurchaseOrder,
  createRedeemOrder,
  ExchangeRatesModel,
  getFxRate,
  getInteracRequestLink,
  KYC_STATUS,
  OrderModel,
  UpdateRedeemDTO,
  updateRedeemOrder,
  updateUserKYC
} from 'src/common/api'
import { useWallet } from 'src/common/context'
import Web3 from 'web3'
import { AbiItem } from 'web3-utils'
import {
  BASIS_POINT_SPREAD,
  BankingCountry,
  ChainAsset,
  ChainName,
  FiatCurrency,
  RequestType,
  WalletType
} from 'src/common/static'
import { alertNotification } from 'src/ui'
import { USDCcontractABI, QCADcontractABI } from 'src/common/AssetContracts'
import { BankDetailsModel, ContactDetailsModel, getContacts } from 'src/common/api/contacts'

interface Errors {
  inputError?: string
  walletError?: string
}

interface Order extends OrderModel {
  asset: ChainAsset
  chain: ChainName
}

const useRequest = (type: RequestType) => {
  const isPurchase = type === RequestType.PURCHASE
  const isSend = type === RequestType.SEND
  const isSendOther = type === RequestType.SEND_THIRD_PARTY

  const { fetchWallets, selectedWallet, wallets, setIsSignMetamaskOpen } = useWallet()

  const { ethereum } = useMetaMask()
  const [value, setValue] = useState('')
  const [asset, setAsset] = useState(ChainAsset.USDC)
  const [fiatCurrency, setFiatCurrency] = useState(FiatCurrency.CAD)
  const [paymentMethod, setPaymentMethod] = useState<'BILL_PAY' | 'EFT' | 'E_TRANSFER' | 'FIAT_OTHER'>('EFT')
  const [exchangeRates, setExchangeRates] = useState<ExchangeRatesModel>({ eth: 0, usd: 1 })
  const [exchangeRatesTimestamp, setExchangeRatesTimestamp] = useState(new Date())

  const [modals, setModals] = useState({
    choosePayment: false,
    billpay: false,
    eft: false,
    interac: false,
    confirm: false,
    requestLoading: false,
    kycPortal: false,
    bankDetails: false
  })
  const [showIcomply, setShowIcomply] = useState(false)
  const [errors, setErrors] = useState<Errors>({
    inputError: undefined,
    walletError: undefined
  })
  const [loading, setLoading] = useState(false)
  const [order, setOrder] = useState<Order | null>(null)
  const [interacLink, setInteracLink] = useState('')
  const [completeLater, setCompleteLater] = useState(false)
  const [recipientList, setRecipientList] = useState<ContactDetailsModel[]>([])
  const [search, setSearch] = useState<string>('')
  const [filteredList, setFilteredList] = useState<ContactDetailsModel[]>([])
  const [recipient, setRecipient] = useState<ContactDetailsModel | null>(null)
  const [recipientBank, setRecipientBank] = useState<BankDetailsModel | null>(null)

  const selectedWalletBalance = selectedWallet.address
    ? wallets.find((wallet) => wallet.address === selectedWallet.address)?.balance
    : null

  const isOptedIn =
    selectedWallet.chain === ChainName.ALGORAND && selectedWallet.address
      ? asset === ChainAsset.QCAD
        ? Boolean(selectedWalletBalance?.qcad?.toString())
        : Boolean(selectedWalletBalance?.usdc?.toString())
      : true

  const qcadWalletBalance = selectedWalletBalance?.qcad ?? selectedWalletBalance?.qcad_xlm ?? 0
  const usdcWalletBalance = selectedWalletBalance?.usdc ?? selectedWalletBalance?.usdc_xlm ?? 0

  const fetchFxRate = async () => {
    try {
      const response = await getFxRate()
      setExchangeRates(response.data)
      setExchangeRatesTimestamp(new Date())
    } catch (err) {
      alertNotification('Fetching current FX rate failed. Please try again later.', 'error')
    }
  }

  const fetchContacts = async () => {
    try {
      const recipients = await getContacts()
      setRecipientList(recipients.data)
      setFilteredList(recipients.data)
    } catch {
      alertNotification('Something went wrong when fetching contacts.')
    }
  }

  useEffect(() => {
    if (type === RequestType.SEND_THIRD_PARTY) {
      fetchContacts()
    }
    fetchFxRate()
    const interval = setInterval(() => fetchFxRate(), 60000)
    return () => {
      clearInterval(interval)
    }
  }, [])

  useEffect(() => {
    setAsset(ChainAsset.USDC)
  }, [selectedWallet.chain])

  const showIcomplyWidget = () => {
    setShowIcomply(true)
  }

  const initiateKYC = () => {
    handleOpen('kycPortal')
    window.iComply.init(document.getElementById('icomply'), {
      icomplyPreview: true,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      callback: async (serverResponse: any) => {
        try {
          await updateUserKYC({ kycId: serverResponse.entityId })
        } catch (err) {
          alertNotification('Updating KYC failed. Please contact support.', 'error')
        }
      }
    })
  }

  const waitForConfirmation = async (txId: string, timeout: number) => {
    try {
      const algodClient = new algosdk.Algodv2('', process.env.REACT_APP_ALGOD_API, '')

      const status = await algodClient.status().do()
      if (status === undefined) {
        throw new Error('Unable to get node status')
      }

      const startround = status['last-round'] + 1
      let currentround = startround

      while (currentround < startround + timeout) {
        const pendingInfo = await algodClient.pendingTransactionInformation(txId).do()
        if (pendingInfo !== undefined) {
          if (pendingInfo['confirmed-round'] !== null && pendingInfo['confirmed-round'] > 0) {
            //Got the completed Transaction
            return pendingInfo
          } else {
            if (pendingInfo['pool-error'] != null && pendingInfo['pool-error'].length > 0) {
              // If there was a pool error, then the transaction has been rejected!
              throw new Error('Transaction ' + txId + ' rejected - pool error: ' + pendingInfo['pool-error'])
            }
          }
        }
        await algodClient.statusAfterBlock(currentround).do()
        currentround++
      }

      throw new Error('Transaction ' + txId + ' not confirmed after ' + timeout + ' rounds!')
    } catch (err) {
      alertNotification((err as Error).message)
    }
  }

  const optIn = async () => {
    try {
      const algodClient = new algosdk.Algodv2('', process.env.REACT_APP_ALGOD_API, '')
      const params = await algodClient.getTransactionParams().do()

      const txn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
        suggestedParams: {
          ...params
        },
        from: selectedWallet.address as string,
        to: selectedWallet.address as string,
        assetIndex: asset === ChainAsset.QCAD ? 16546134 : Number(process.env.REACT_APP_ALGO_ASSET_ID),
        amount: 0
      })

      const myAlgoConnect = new MyAlgoConnect()
      const signedTxn = await myAlgoConnect.signTransaction(txn.toByte())
      const tx = await algodClient.sendRawTransaction(signedTxn.blob).do()
      await waitForConfirmation(tx.txId, 4)
      await fetchWallets()
    } catch (err) {
      alertNotification('Opt-In transaction failed. Please contact support.', 'error')
    }
  }

  const handleRequest = async () => {
    let inputError
    let walletError
    if (Number(value) === 0 && isOptedIn) {
      inputError = 'Must be greater than 0'
    }
    if (!selectedWallet.address) {
      walletError = 'Please choose a wallet first'
    }
    if (!(inputError || walletError)) {
      setLoading(true)
      try {
        const response = await checkOrderEligibility({
          asset,
          address: selectedWallet.address as string,
          chain: selectedWallet.chain
        })
        const { kycStatus, walletScreening } = response.data
        if (walletScreening === 'fail') {
          walletError = "You can't use this wallet"
        } else {
          switch (kycStatus) {
            case KYC_STATUS.REJECTED: {
              walletError = 'Transaction declined.'
              break
            }
            case KYC_STATUS.PENDING:
            case KYC_STATUS.REVIEW:
            case KYC_STATUS.NEW: {
              walletError = 'Your KYC decision is still pending'
              break
            }
            case KYC_STATUS.UNINITIATED: {
              initiateKYC()
              break
            }
            case KYC_STATUS.APPROVED: {
              if (isOptedIn) {
                if (isSendOther) {
                  handleOpen('confirm')
                } else {
                  handleOpen('choosePayment')
                }
              } else {
                await optIn()
              }
              break
            }
          }
        }
      } catch (err) {
        alertNotification()
      }
      setLoading(false)
    }
    setErrors({ inputError, walletError })
  }

  const choosePayment = (method: string) => {
    handleClose('choosePayment')
    if (isPurchase && method === 'interac') {
      handleOpen('confirm')
      setPaymentMethod('E_TRANSFER')
    } else {
      handleOpen(method)
    }
  }

  const handleBillpay = () => {
    handleClose('billpay')
    handleOpen('confirm')
    setPaymentMethod('BILL_PAY')
  }

  const handleInterac = async (stopLoading: () => void, completeLater: boolean) => {
    setPaymentMethod('E_TRANSFER')
    setCompleteLater(completeLater)
    handleClose('interac')
    if (isPurchase) {
      handleOpen('requestLoading')
      try {
        const response = await createOrder(completeLater)
        const interacRefId = await getInteracRequestLink(response.data.id)
        setOrder({ ...response.data, chain: selectedWallet.chain, asset })
        setInteracLink(`${interacRefId.data}`)
        if (!completeLater) {
          window.open(`${interacRefId.data}`, '_blank')
        }
      } catch (err) {
        if (axios.isAxiosError(err)) {
          alertNotification("Something went wrong and your order couldn't be processed.")
        } else {
          alertNotification((err as Error).message)
        }
      }
      handleClose('requestLoading')
      stopLoading()
    } else {
      handleOpen('confirm')
    }
  }

  const handleEft = (selectedBank: BankDetailsModel) => {
    setRecipientBank(selectedBank)
    handleClose('payment')
    handleOpen('confirm')
    setPaymentMethod('EFT')
  }
  const signMetamaskTransaction = async (order: OrderModel) => {
    if (ethereum) {
      try {
        const web3 = new Web3(ethereum)
        web3.eth.defaultAccount = selectedWallet?.address
        const { contractAddress, adminAddress, amount, contractABI } =
          asset === ChainAsset.QCAD
            ? {
                contractABI: QCADcontractABI as AbiItem[],
                adminAddress: process.env.REACT_APP_GRAPES_ETH_ADMIN,
                contractAddress: process.env.REACT_APP_QCAD_CONTRACT_ADDRESS,
                amount: Math.round(Number(order.cryptoAmount) * 100)
              }
            : {
                contractABI: USDCcontractABI as AbiItem[],
                adminAddress: process.env.REACT_APP_USDC_ETH_ADMIN,
                contractAddress: process.env.REACT_APP_USDC_CONTRACT_ADDRESS,
                amount: Math.round(Number(order.cryptoAmount) * 1000000)
              }
        const contract = new web3.eth.Contract(contractABI, contractAddress)
        // const response = await contract.methods
        //   .transfer(process.env.REACT_APP_GRAPES_ETH_ADMIN, Number(order.cryptoAmount) * 100)
        //   .send({
        //     from: selectedWallet.address
        //   })
        // return response.transactionHash

        const encodedData = contract.methods.transfer(adminAddress, amount).encodeABI()
        const txParams = {
          from: selectedWallet.address,
          to: contractAddress,
          value: web3.utils.toHex(0),
          data: encodedData
        }
        setIsSignMetamaskOpen(true)
        const txHash = await ethereum.request({
          method: 'eth_sendTransaction',
          params: [txParams]
        })
        setIsSignMetamaskOpen(false)
        return txHash
      } catch (err) {
        setIsSignMetamaskOpen(false)
        alertNotification('Sign transaction failed. Please contact support.', 'error')
      }
    } else {
      alertNotification('Metamask not available. Please install Metamask browser extension and retry.', 'error')
    }
  }

  const createOrder = async (completeLater = false) => {
    try {
      let orderRequestBody: CreateOrderDTO
      if (paymentMethod === 'EFT') {
        orderRequestBody = {
          asset,
          fiatAmount: 0.0,
          fiatCurrency,
          cryptoAmount: Number(value),
          fxRate: 1.0,
          address: selectedWallet.address as string,
          chain: selectedWallet.chain,
          attestation: true,
          paymentMethod: paymentMethod,
          completeLater: completeLater,
          bankingDetailsId: recipientBank?.id
        }
      } else {
        orderRequestBody = {
          asset,
          fiatAmount: 0.0,
          fiatCurrency,
          cryptoAmount: Number(value),
          fxRate: 1.0,
          address: selectedWallet.address as string,
          chain: selectedWallet.chain,
          attestation: true,
          paymentMethod: isSendOther ? 'FIAT_OTHER' : paymentMethod,
          completeLater: completeLater
        }
      }

      const response = isPurchase
        ? await createPurchaseOrder(orderRequestBody)
        : await createRedeemOrder(orderRequestBody)
      return response
    } catch (err) {
      throw new Error('Something went wrong. Please check your transaction limits or try again later.')
    }
  }

  const calcBasisPointSpread = () => {
    if (isPurchase) {
      return Number(1 + BASIS_POINT_SPREAD)
    } else if (isSend) {
      return Number(1 - BASIS_POINT_SPREAD)
    } else {
      return 1
    }
  }

  const calculateFxRate = (asset: ChainAsset, fiatCurrency: FiatCurrency) => {
    const basisPointSpread = calcBasisPointSpread()
    if (asset === ChainAsset.USDC && fiatCurrency === FiatCurrency.CAD) {
      return Number(exchangeRates.usd * basisPointSpread)
    } else if (asset === ChainAsset.QCAD && fiatCurrency === FiatCurrency.USD) {
      return Number((1 / exchangeRates.usd) * basisPointSpread)
    } else {
      return 1
    }
  }

  const calculateFiatAmount = (asset: ChainAsset, fiatCurrency: FiatCurrency) => {
    const fxRate = calculateFxRate(asset, fiatCurrency)
    if (
      (asset === ChainAsset.USDC && fiatCurrency === FiatCurrency.USD) ||
      (asset === ChainAsset.QCAD && fiatCurrency === FiatCurrency.CAD)
    ) {
      return Number(value)
    } else {
      return Number(value) * fxRate
    }
  }

  const handleConfirmRequest = async (stopLoading: () => void) => {
    if (isPurchase && paymentMethod === 'E_TRANSFER') {
      handleClose('confirm')
      stopLoading()
      handleOpen('interac')
      return
    }
    if (isPurchase) {
      handleClose('confirm')
      handleOpen('requestLoading')
    }
    try {
      let response = await createOrder()
      if (isSend || isSendOther) {
        let txId = ''
        if (selectedWallet.walletType === WalletType.METAMASK) {
          txId = await signMetamaskTransaction(response.data)
        }
        handleClose('confirm')
        handleOpen('requestLoading')
        let orderRequestBody: UpdateRedeemDTO
        if (isSendOther) {
          orderRequestBody = {
            orderId: response.data.id,
            txId,
            paymentMethod: 'FIAT_OTHER',
            contactId: recipient?.contactDetails.id,
            bankingDetailsId: recipientBank?.id
          }
        } else if (isSend && paymentMethod === 'EFT') {
          orderRequestBody = {
            orderId: response.data.id,
            txId,
            paymentMethod: paymentMethod,
            bankingDetailsId: recipientBank?.id
          }
        } else {
          orderRequestBody = {
            orderId: response.data.id,
            txId,
            paymentMethod: paymentMethod
          }
        }
        response = await updateRedeemOrder(orderRequestBody)
      }
      setOrder({ ...response.data, chain: selectedWallet.chain, asset })
    } catch (err) {
      if (axios.isAxiosError(err)) {
        alertNotification("Something went wrong and your order couldn't be processed.")
      } else {
        alertNotification((err as Error).message)
      }
    }
    handleClose('requestLoading')
    stopLoading()
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value
    if (Number(newValue) > 0 && errors.inputError) {
      setErrors((s) => ({ ...s, inputError: undefined }))
    }

    if ((isSend || isSendOther) && asset === ChainAsset.QCAD) {
      // epic v1.3: custodial does not support QCAD on staging, we transfer VCAD tokens on staging hence ignore balance check
      if (
        selectedWallet.walletType === WalletType.CUSTODIAL &&
        (process.env.REACT_APP_API_URL?.includes('staging') || process.env.REACT_APP_API_URL?.includes('localhost'))
      ) {
        setErrors((s) => ({ ...s, inputError: undefined }))
      } else {
        if (Number(newValue) > qcadWalletBalance) {
          setErrors((s) => ({ ...s, inputError: 'Insufficient QCAD balance' }))
        }
      }
    }
    if ((isSend || isSendOther) && asset === ChainAsset.USDC && Number(newValue) > usdcWalletBalance) {
      setErrors((s) => ({ ...s, inputError: 'Insufficient USDC balance' }))
    }
    if (isSend || isSendOther) {
      const fiatAmount = calculateFiatAmount(asset, fiatCurrency)
      if (Number(fiatAmount) > 100000) {
        setErrors((s) => ({ ...s, inputError: 'Send amount exceeds transaction limit $100,000' }))
      }
    }
    if (isPurchase) {
      const fiatAmount = calculateFiatAmount(asset, fiatCurrency)
      if (Number(fiatAmount) > 500000) {
        setErrors((s) => ({
          ...s,
          inputError: 'Purchase amount exceeds transaction limit $500,000. Please contact support.'
        }))
      }
    }
    setValue(newValue)
  }

  const handleClose = (modal: string) => {
    setModals((s) => ({ ...s, [modal]: false }))
  }

  const handleOpen = (modal: string) => {
    setModals((s) => ({ ...s, [modal]: true }))
  }

  const [anchorElAsset, setAnchorElAsset] = useState<null | HTMLElement>(null)

  const handleOpenAssetMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElAsset(event.currentTarget)
  }

  const handleCloseAssetMenu = () => {
    setAnchorElAsset(null)
  }

  const chooseAsset = (asset: ChainAsset) => {
    setAsset(asset)
    setValue('')
    setErrors((s) => ({ ...s, inputError: undefined }))
    handleCloseAssetMenu()
  }

  const [anchorElFiatCurrency, setAnchorElFiatCurrency] = useState<null | HTMLElement>(null)

  const handleOpenFiatCurrencyMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElFiatCurrency(event.currentTarget)
  }

  const handleCloseFiatCurrencyMenu = () => {
    setAnchorElFiatCurrency(null)
  }

  const chooseFiatCurrency = (fiatCurrency: FiatCurrency) => {
    setFiatCurrency(fiatCurrency)
    setValue('')
    setErrors((s) => ({ ...s, inputError: undefined }))
    handleCloseFiatCurrencyMenu()
  }

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value)
    setFilteredList(
      recipientList.filter((c) =>
        `${c.contactDetails.firstName} ${c.contactDetails.lastName}`
          .toLowerCase()
          .includes(event.target.value.toLowerCase())
      )
    )
  }

  const handleOpenBankDetails = (contact: ContactDetailsModel) => {
    setRecipient(contact)
    handleOpen('bankDetails')
  }

  const handleCloseBankDetails = () => {
    handleClose('bankDetails')
  }

  const handleSelectRecipientBank = (bank: BankDetailsModel) => {
    setRecipientBank(bank)
    if (bank.country === BankingCountry.CAN) {
      setFiatCurrency(FiatCurrency.CAD)
    } else {
      setFiatCurrency(FiatCurrency.USD)
    }
    handleClose('bankDetails')
  }

  const handleRemoveRecipientBank = () => {
    setRecipientBank(null)
  }

  return {
    value,
    modals,
    showIcomply,
    asset,
    order,
    exchangeRates,
    exchangeRatesTimestamp,
    paymentMethod,
    selectedWallet,
    selectedWalletBalance,
    isOptedIn,
    loading,
    errors,
    anchorElAsset,
    fiatCurrency,
    anchorElFiatCurrency,
    interacLink,
    completeLater,
    search,
    filteredList,
    recipient,
    recipientBank,
    handleRequest,
    showIcomplyWidget,
    setShowIcomply,
    choosePayment,
    handleBillpay,
    handleEft,
    handleInterac,
    handleConfirmRequest,
    handleChange,
    handleClose,
    handleOpenAssetMenu,
    handleCloseAssetMenu,
    chooseAsset,
    handleOpenFiatCurrencyMenu,
    handleCloseFiatCurrencyMenu,
    chooseFiatCurrency,
    calculateFiatAmount,
    calculateFxRate,
    handleSearch,
    handleOpenBankDetails,
    handleCloseBankDetails,
    handleSelectRecipientBank,
    handleRemoveRecipientBank
  }
}

export default useRequest
