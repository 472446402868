import { TableRow, TableRowProps } from '@mui/material'
import { styled } from '@mui/system'

interface StyledTableRowProps extends TableRowProps {
  padding?: number | string
}

export const StyledTableRow = styled(TableRow, {
  shouldForwardProp: (prop) => prop !== 'padding'
})<StyledTableRowProps>(({ padding }) => ({
  '& td': {
    border: 0,
    padding: padding || '3px 0'
  }
}))
