import { SvgIcon, SvgIconProps } from '@mui/material'
import React from 'react'

export const QRCodeIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.83333 15.5V13.8333H10.5V15.5H8.83333ZM7.16667 13.8333V9.66667H8.83333V13.8333H7.16667ZM13.8333 11.3333V8H15.5V11.3333H13.8333ZM12.1667 8V6.33333H13.8333V8H12.1667ZM2.16667 9.66667V8H3.83333V9.66667H2.16667ZM0.5 8V6.33333H2.16667V8H0.5ZM8 2.16667V0.5H9.66667V2.16667H8ZM1.54167 4.45833H4.45833V1.54167H1.54167V4.45833ZM0.5 5.5V0.5H5.5V5.5H0.5ZM1.54167 14.4583H4.45833V11.5417H1.54167V14.4583ZM0.5 15.5V10.5H5.5V15.5H0.5ZM11.5417 4.45833H14.4583V1.54167H11.5417V4.45833ZM10.5 5.5V0.5H15.5V5.5H10.5ZM12.1667 15.5V13H10.5V11.3333H13.8333V13.8333H15.5V15.5H12.1667ZM8.83333 9.66667V8H12.1667V9.66667H8.83333ZM5.5 9.66667V8H3.83333V6.33333H8.83333V8H7.16667V9.66667H5.5ZM6.33333 5.5V2.16667H8V3.83333H9.66667V5.5H6.33333ZM2.375 3.625V2.375H3.625V3.625H2.375ZM2.375 13.625V12.375H3.625V13.625H2.375ZM12.375 3.625V2.375H13.625V3.625H12.375Z"
        fill="#4F4F4F"
      />
    </SvgIcon>
  )
}
