import React, { useState } from 'react'
import { FiatCurrency } from '../../../static'
import Details from './Details'

interface InteracProps {
  close: () => void
  handleInterac: (stopLoading: () => void, completeLater: boolean) => void
  amount: string
  fiatCurrency: FiatCurrency
  isPurchase: boolean
}

const Interac: React.FC<InteracProps> = ({ close, handleInterac, amount, fiatCurrency, isPurchase }: InteracProps) => {
  const [modals, setModals] = useState({
    details: true
  })
  const [loading, setLoading] = useState(false)

  const stopLoading = () => {
    setLoading(false)
  }

  const handleClose = (modal?: string) => {
    if (modal) {
      setModals((s) => ({ ...s, [modal]: false }))
      return
    }
    setModals({ details: true })
    close()
  }

  const handleRedirect = () => {
    setLoading(true)
    handleInterac(stopLoading, false)
  }

  const handleEmail = () => {
    setLoading(true)
    handleInterac(stopLoading, true)
  }

  return (
    <>
      <Details
        open={modals.details}
        onClose={() => handleClose()}
        handleRedirect={handleRedirect}
        handleEmail={handleEmail}
        amount={amount}
        fiatCurrency={fiatCurrency}
        loading={loading}
        isPurchase={isPurchase}
      />
    </>
  )
}

export default Interac
