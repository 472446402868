import React from 'react'
import { Field, FieldProps } from 'formik'
import { FormControl, Grid, IconButton, InputAdornment, OutlinedInput } from '@mui/material'
import usePasswordInputField from './PasswordInputField.State'
import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material'
import * as S from './styles'

interface PasswordInputFieldProps {
  state: { strengthColor: string; value: number }
}

const PasswordInputField: React.FC<PasswordInputFieldProps> = ({
  state: { strengthColor, value }
}: PasswordInputFieldProps) => {
  const { handleClickShowPassword, handleMouseDownPassword, showPassword } = usePasswordInputField()

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid xs={9} item>
          <S.PasswordLabel>Password *</S.PasswordLabel>
        </Grid>
        <Grid xs={3} item>
          <S.Progress strengthColor={strengthColor} variant="determinate" value={value} />
        </Grid>
      </Grid>
      <Field name="password">
        {(props: FieldProps) => {
          const { field, meta } = props
          return (
            <FormControl error={meta.touched && Boolean(meta.error)} fullWidth>
              <OutlinedInput
                {...field}
                id="outlined-adornment-password"
                placeholder="Password"
                type={showPassword ? 'text' : 'password'}
                fullWidth
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                    </IconButton>
                  </InputAdornment>
                }
                size="small"
              />
            </FormControl>
          )
        }}
      </Field>
    </>
  )
}

export default PasswordInputField
