import React, { createContext, useContext } from 'react'
import useProvideWallet, { WalletContext } from './ProvideWallet.State'

interface ProvideWalletProps {
  children: React.ReactNode
}

const walletContext = createContext<WalletContext | null>(null)

export const ProvideWallet: React.FC<ProvideWalletProps> = ({ children }: ProvideWalletProps) => {
  const wallet = useProvideWallet()

  return <walletContext.Provider value={wallet}>{children}</walletContext.Provider>
}

export const useWallet = (): WalletContext => {
  const context = useContext(walletContext) as WalletContext
  if (!context) {
    throw new Error('useWallet must be used within a WalletContext')
  }
  return context
}
