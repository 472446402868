import { useState } from 'react'
import { isEmailValid } from '../../common/helpers'
import { useAuth } from '../../common/context'

interface Values {
  email: string
  password: string
}

export const initialValues: Values = {
  email: '',
  password: ''
}

export const validate = (values: Values) => {
  const errors: Partial<Values> = {}

  if (!values.email) {
    errors.email = 'Required'
  } else if (!isEmailValid(values.email)) {
    errors.email = 'Invalid email address'
  }
  if (!values.password) {
    errors.password = 'Required'
  }

  return errors
}

const useMFAuth = () => {
  const { tempUser } = useAuth()

  const [code, setCode] = useState('')
  return { code, setCode, tempUser }
}

export default useMFAuth
