import { useState } from 'react'
import { calculatePasswordStrength } from 'src/common/helpers'
import { FormikHelpers } from 'formik'
import { useAuth } from 'src/common/context'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { alertNotification } from 'src/ui'

interface Values {
  password: string
}

interface State {
  error?: string
  success?: string
  bar: {
    strengthColor: string
    value: number
  }
}

export const initialValues: Values = {
  password: ''
}

export const validate = (values: Values) => {
  const errors: Partial<Values> = {}
  let bar = {
    strengthColor: 'disabled',
    value: 0
  }
  if (!values.password) {
    errors.password = 'Required'
  } else {
    const { error, bar: barState } = calculatePasswordStrength(values.password)
    if (error) {
      errors.password = error
    }
    bar = barState
  }
  return { errors, bar }
}

const ResetPassword = () => {
  const { resetPassword } = useAuth()

  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const username = searchParams.get('user_name')
  const confirmationCode = searchParams.get('confirmation_code')

  const invalidURL = !(username && confirmationCode)

  const [state, setState] = useState<State>({
    error: undefined,
    success: undefined,
    bar: {
      strengthColor: 'disabled',
      value: 0
    }
  })

  const onSubmit = async (values: Values, { setSubmitting }: FormikHelpers<Values>) => {
    const { password } = values

    try {
      await resetPassword(username as string, confirmationCode as string, password)
      navigate('/login')
      setSubmitting(false)
      alertNotification('Your password has been reset successfully.', 'success')
    } catch (err) {
      setState({ ...state, error: (err as Error).message, success: undefined })
      setSubmitting(false)
    }
  }

  return { state, setState, onSubmit, invalidURL }
}

export default ResetPassword
