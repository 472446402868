import { useState, useEffect } from 'react'
import {
  createUserNewBank,
  deleteUserBank,
  getOrderLimits,
  getProfile,
  requestLimitIncrease,
  saveProfile
} from 'src/common/api'
import { useAuth, useWallet } from 'src/common/context'
import {
  ValidationError,
  isAchCodeValid,
  isEmailValid,
  isInstitutionNumValid,
  isSwiftBicCodeValid,
  isTransitNumValid
} from 'src/common/helpers'
import { BankingCountry } from 'src/common/static'
import { alertDialog, alertNotification } from 'src/ui'

interface BankModel {
  id: number
  country: string
  name: string
  institutionNum: string
  transitNum: string
  routingNum: string
  accountNum: string
  accHolderName: string
  isPrimary: boolean
  achCode: string
  swiftBicCode: string
}

interface NewBankModel {
  country: BankingCountry
  name: string
  accHolderName: string
  institutionNum: string
  transitNum: string
  routingNum: string
  accountNum: string
  achCode: string
  swiftBicCode: string
}

const useProfile = () => {
  const { user } = useAuth()
  const { wallets } = useWallet()

  const [open, setOpen] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [values, setValues] = useState({
    email: 'N/A',
    firstName: 'N/A',
    lastName: 'N/A',
    accountType: 'N/A',
    accountId: 0,
    address: '',
    city: '',
    stateProvince: '',
    countryCode: '',
    postalCode: '',
    interacEmail: 'N/A'
  })
  const [bankDetails, setBankDetails] = useState<BankModel[]>([])
  const [newBankDetails, setNewBankDetails] = useState<NewBankModel[]>([])
  const noErrors = {
    accHolderName: false,
    name: false,
    institutionNum: false,
    transitNum: false,
    accountNum: false,
    achCode: false,
    swiftBicCode: false,
    interacEmail: false
  }
  const [errors, setErrors] = useState(noErrors)
  const [availableLimits, setAvailableLimits] = useState({
    daily: 100000,
    weekly: 500000
  })
  const [isEdit, setIsEdit] = useState(false)
  const [bankIdx, setBankIdx] = useState(0)
  const [newBankIdx, setNewBankIdx] = useState(0)
  const [blockchainIdx, setBlockchainIdx] = useState(0)
  const [anchorElCountry, setAnchorElCountry] = useState<null | HTMLElement>(null)
  const [targetNew, setTargetNew] = useState(false)
  const [showQR, setShowQR] = useState(false)

  const sendIncreaseLimitRequest = async () => {
    setSubmitting(true)
    try {
      await requestLimitIncrease()
      setSubmitting(false)
      setOpen(false)
      alertDialog(
        'Request has been sent.',
        'We will get back to you once we review your eligibility. We appreciate your patience.'
      )
    } catch (err) {
      setOpen(false)
      alertNotification('Sending request to increase limit failed. Please contact support.', 'error')
    }
  }

  const handleOpenLimit = () => {
    setOpen(true)
  }

  const handleCloseLimit = () => {
    setOpen(false)
  }

  const handleOpenCountryMenu = (event: React.MouseEvent<HTMLElement>, isNew: boolean, idx: number) => {
    if (isNew) {
      setTargetNew(true)
      setNewBankIdx(idx)
    } else {
      setTargetNew(false)
      setBankIdx(idx)
    }
    setAnchorElCountry(event.currentTarget)
  }

  const handleCloseCountryMenu = () => {
    setAnchorElCountry(null)
  }

  const chooseCountry = (country: BankingCountry) => {
    if (targetNew) {
      const updatedBankDetails = {
        ...newBankDetails[newBankIdx],
        country: country
      }
      setNewBankDetails(newBankDetails.map((bank, idx) => (idx === newBankIdx ? updatedBankDetails : bank)))
    } else {
      const updatedBankDetails = {
        ...bankDetails[bankIdx],
        country: country
      }
      setBankDetails(bankDetails.map((bank, idx) => (idx === bankIdx ? updatedBankDetails : bank)))
    }
    handleCloseCountryMenu()
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name
    const newValue = event.target.value
    if (name === 'interacEmail') {
      setValues({
        ...values,
        [name]: newValue
      })
    } else {
      const updatedBankDetails = {
        ...bankDetails[bankIdx],
        [name]: newValue
      }
      setBankDetails(bankDetails.map((bank, idx) => (idx === bankIdx ? updatedBankDetails : bank)))
    }
  }

  const handleChangeNewBank = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name
    const newValue = event.target.value
    const updatedBankDetails = {
      ...newBankDetails[newBankIdx],
      [name]: newValue
    }
    setNewBankDetails(newBankDetails.map((bank, idx) => (idx === newBankIdx ? updatedBankDetails : bank)))
  }

  const handleAddNewBank = () => {
    setNewBankDetails([
      ...newBankDetails,
      {
        country: BankingCountry.CAN,
        name: '',
        accHolderName: '',
        institutionNum: '',
        transitNum: '',
        routingNum: '',
        accountNum: '',
        achCode: '',
        swiftBicCode: ''
      }
    ])
    setIsEdit(true)
  }

  const handleDeleteBank = async (isNew: boolean, idx: number) => {
    try {
      if (isNew) {
        setNewBankDetails(newBankDetails.filter((i) => newBankDetails.indexOf(i) !== idx))
      } else {
        const response = await deleteUserBank({
          bankingDetailsId: `${bankDetails[idx].id}`
        })
        if (response) {
          setBankDetails(bankDetails.filter((i) => bankDetails.indexOf(i) !== idx))
          alertNotification('Bank details successfully deleted', 'success')
        }
      }
    } catch {
      alertNotification('Error deleting bank details', 'error')
    }
  }

  const handleEdit = async () => {
    if (isEdit) {
      await handleRequest()
    } else {
      setIsEdit(true)
    }
  }

  const isMissingBankDetails = (): boolean => {
    for (let i = 0; i < bankDetails.length; i++) {
      const bank = bankDetails[i]
      if (
        bank.country === BankingCountry.CAN &&
        (bank.name || bank.institutionNum || bank.transitNum || bank.accountNum || bank.accHolderName) &&
        (!bank.name || !bank.institutionNum || !bank.transitNum || !bank.accountNum || !bank.accHolderName)
      ) {
        return true
      } else if (
        bank.country === BankingCountry.US &&
        (bank.name || bank.achCode || bank.swiftBicCode || bank.accountNum || bank.accHolderName) &&
        (!bank.name || !bank.achCode || !bank.swiftBicCode || !bank.accountNum || !bank.accHolderName)
      ) {
        return true
      }
    }
    for (let i = 0; i < newBankDetails.length; i++) {
      const bank = newBankDetails[i]
      if (
        bank.country === BankingCountry.CAN &&
        (bank.name || bank.institutionNum || bank.transitNum || bank.accountNum || bank.accHolderName) &&
        (!bank.name || !bank.institutionNum || !bank.transitNum || !bank.accountNum || !bank.accHolderName)
      ) {
        return true
      } else if (
        bank.country === BankingCountry.US &&
        (bank.name || bank.achCode || bank.swiftBicCode || bank.accountNum || bank.accHolderName) &&
        (!bank.name || !bank.achCode || !bank.swiftBicCode || !bank.accountNum || !bank.accHolderName)
      ) {
        return true
      }
    }
    return false
  }

  const isValidBankDetails = () => {
    const isValid = {
      institutionNum: true,
      transitNum: true,
      achCode: true,
      swiftBicCode: true
    }
    for (let i = 0; i < bankDetails.length; i++) {
      const bank = bankDetails[i]
      const isCAN = bank.country === BankingCountry.CAN
      if (isCAN && bank.institutionNum && !isInstitutionNumValid(bank.institutionNum)) {
        isValid.institutionNum = false
      }
      if (isCAN && bank.transitNum && !isTransitNumValid(bank.transitNum)) {
        isValid.transitNum = false
      }
      if (!isCAN && bank.achCode && !isAchCodeValid(bank.achCode)) {
        isValid.achCode = false
      }
      if (!isCAN && bank.swiftBicCode && !isSwiftBicCodeValid(bank.swiftBicCode)) {
        isValid.swiftBicCode = false
      }
    }
    for (let i = 0; i < newBankDetails.length; i++) {
      const bank = newBankDetails[i]
      const isCAN = bank.country === BankingCountry.CAN
      if (isCAN && bank.institutionNum && !isInstitutionNumValid(bank.institutionNum)) {
        isValid.institutionNum = false
      }
      if (isCAN && bank.transitNum && !isTransitNumValid(bank.transitNum)) {
        isValid.transitNum = false
      }
      if (!isCAN && bank.achCode && !isAchCodeValid(bank.achCode)) {
        isValid.achCode = false
      }
      if (!isCAN && bank.swiftBicCode && !isSwiftBicCodeValid(bank.swiftBicCode)) {
        isValid.swiftBicCode = false
      }
    }
    return isValid
  }

  const validate = () => {
    const validateBankDetails = isValidBankDetails()
    if (values.interacEmail && !isEmailValid(values.interacEmail)) {
      setErrors({
        ...noErrors,
        interacEmail: true
      })
      throw new ValidationError('Invalid email')
    } else if (isMissingBankDetails()) {
      throw new ValidationError('Missing bank details')
    } else if (!validateBankDetails.institutionNum) {
      setErrors({
        ...noErrors,
        institutionNum: true
      })
      throw new ValidationError('Invalid institution num. Format: 3 digits')
    } else if (!validateBankDetails.transitNum) {
      setErrors({
        ...noErrors,
        transitNum: true
      })
      throw new ValidationError('Invalid transit num. Format: 5 digits')
    } else if (!validateBankDetails.achCode) {
      setErrors({
        ...noErrors,
        achCode: true
      })
      throw new ValidationError('Invalid ACH code. Format: 9 digits')
    } else if (!validateBankDetails.swiftBicCode) {
      setErrors({
        ...noErrors,
        swiftBicCode: true
      })
      throw new ValidationError('Invalid Swift BIC code. Format: min 8 digits, max 11 digits')
    }
  }

  const handleRequest = async () => {
    try {
      setSubmitting(true)
      validate()
      if (newBankDetails.length) {
        const addNewBankResponse = await createUserNewBank({
          bankDetails: newBankDetails.map((b) => ({
            country: b.country,
            name: b.name,
            accHolderName: b.accHolderName,
            routingNum: `${b.institutionNum}-${b.transitNum}`,
            accountNum: b.accountNum,
            achCode: b.achCode,
            swiftBicCode: b.swiftBicCode
          }))
        })
        setBankDetails(
          bankDetails.concat(
            addNewBankResponse.data.bankDetails.map((b) => ({
              id: b.id,
              country: b.country,
              name: b.name,
              institutionNum: b.routingNum.substring(0, 3),
              transitNum: b.routingNum.substring(4),
              routingNum: b.routingNum,
              accountNum: b.accountNum,
              accHolderName: b.accHolderName,
              isPrimary: b.isPrimary,
              achCode: b.achCode,
              swiftBicCode: b.swiftBicCode
            }))
          )
        )
        setNewBankDetails([])
      }
      const data = values.interacEmail
        ? {
            interacEmail: values.interacEmail,
            updateBankDetails: bankDetails.map((b) => ({
              id: b.id,
              country: b.country,
              name: b.name,
              accHolderName: b.accHolderName,
              routingNum: `${b.institutionNum}-${b.transitNum}`,
              accountNum: b.accountNum,
              achCode: b.achCode,
              swiftBicCode: b.swiftBicCode,
              isPrimary: b.isPrimary
            }))
          }
        : {
            updateBankDetails: bankDetails.map((b) => ({
              id: b.id,
              country: b.country,
              name: b.name,
              accHolderName: b.accHolderName,
              routingNum: `${b.institutionNum}-${b.transitNum}`,
              accountNum: b.accountNum,
              achCode: b.achCode,
              swiftBicCode: b.swiftBicCode,
              isPrimary: b.isPrimary
            }))
          }
      const response = await saveProfile(data)
      setErrors(noErrors)
      setIsEdit(false)
      if (response) {
        alertNotification('Profile changes have been saved', 'success')
        await getDetails()
      }
      setSubmitting(false)
    } catch (e) {
      setSubmitting(false)
      if (e instanceof ValidationError) {
        alertNotification(e.message, 'error')
      } else {
        alertNotification('Updating banking details failed. Please contact support.', 'error')
      }
    }
  }

  const getDetails = async () => {
    try {
      const response = await getProfile()
      setValues(response.data)
      setBankDetails(
        response.data.bankDetails.map((b) => ({
          id: b.id,
          country: b.country,
          name: b.name,
          institutionNum: b.routingNum.substring(0, 3),
          transitNum: b.routingNum.substring(4),
          routingNum: b.routingNum,
          accountNum: b.accountNum,
          accHolderName: b.accHolderName,
          isPrimary: b.isPrimary,
          achCode: b.achCode,
          swiftBicCode: b.swiftBicCode
        }))
      )
    } catch (err) {
      alertNotification('Fetching banking details failed. Please contact support.', 'error')
    }
  }

  const getAvailableLimits = async () => {
    try {
      const response = await getOrderLimits()
      setAvailableLimits({
        daily: response.data.availableDailyLimit,
        weekly: response.data.availableWeeklyLimit
      })
    } catch (err) {
      alertNotification('Fetching available limits failed. Please contact support.', 'error')
    }
  }

  const copyToClipboard = (value: string) => {
    navigator.clipboard.writeText(value)
    alertNotification('Successfully copied to clipboard!', 'success')
  }

  const openQR = (idx: number) => {
    setBlockchainIdx(idx)
    setShowQR(true)
  }

  const closeQR = () => {
    setShowQR(false)
  }

  useEffect(() => {
    getDetails()
    getAvailableLimits()
  }, [])

  return {
    user,
    wallets,
    open,
    submitting,
    values,
    bankDetails,
    newBankDetails,
    errors,
    availableLimits,
    isEdit,
    blockchainIdx,
    anchorElCountry,
    showQR,
    setBankIdx,
    setNewBankIdx,
    sendIncreaseLimitRequest,
    handleOpenLimit,
    handleCloseLimit,
    handleOpenCountryMenu,
    handleCloseCountryMenu,
    chooseCountry,
    handleChange,
    handleChangeNewBank,
    handleAddNewBank,
    handleDeleteBank,
    handleEdit,
    copyToClipboard,
    openQR,
    closeQR
  }
}

export default useProfile
