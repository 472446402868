import { LinearProgress, LinearProgressProps, Typography } from '@mui/material'
import { styled } from '@mui/system'
import { hexToRgba } from 'src/common/helpers'

interface StyledProgressProps extends LinearProgressProps {
  strengthColor?: string
}

export const Progress = styled(LinearProgress, {
  shouldForwardProp: (prop) => prop !== 'strengthColor'
})<StyledProgressProps>(({ strengthColor, theme }) => ({
  ...(strengthColor === 'disabled' && {
    '&.MuiLinearProgress-colorPrimary': {
      backgroundColor: theme.palette.action.disabled
    },
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: theme.palette.action.disabled
    }
  }),
  ...(strengthColor === 'weak' && {
    '&.MuiLinearProgress-colorPrimary': {
      backgroundColor: hexToRgba(theme.palette.error.main, 0.5)
    },
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: theme.palette.error.main
    }
  }),
  ...(strengthColor === 'medium' && {
    '&.MuiLinearProgress-colorPrimary': {
      backgroundColor: hexToRgba(theme.palette.warning.main, 0.5)
    },
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: theme.palette.warning.main
    }
  }),
  ...(strengthColor === 'strong' && {
    '&.MuiLinearProgress-colorPrimary': {
      backgroundColor: hexToRgba(theme.palette.success.main, 0.5)
    },
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: theme.palette.success.main
    }
  })
}))

export const PasswordLabel = styled(Typography)({
  marginBottom: '12px',
  fontWeight: 600
})
