import { DialogProps, Link, SvgIconProps, Typography } from '@mui/material'
import React, { useState } from 'react'
import { formatNumber, trimId } from 'src/common/helpers'
import { Modal, SummaryTable } from 'src/ui'
import * as S from './styles'
import { LoadingButton } from '@mui/lab'
import { Box } from '@mui/system'
import { FlagIcon, USFlagIcon } from 'src/common/assets'
import { ChainAsset, RequestType, FiatCurrency, ChainName } from 'src/common/static'

interface Asset {
  name: ChainAsset
  Icon: React.FC<SvgIconProps<'svg', Record<string, unknown>>>
}

interface ConfirmRequestProps extends DialogProps {
  confirmRequest: (stopLoading: () => void) => void
  fxRate: number
  networkFee: number
  asset: Asset
  cryptoAmount: string
  fiatAmount: number | string
  type: RequestType
  exchangeRatesTimestamp: Date
  fiatCurrency: FiatCurrency
  selectedWalletAddress: string | null
  selectedWalletChain: ChainName
}

const ConfirmRequest: React.FC<ConfirmRequestProps> = ({
  confirmRequest,
  asset,
  fxRate,
  networkFee,
  cryptoAmount,
  fiatAmount,
  type,
  exchangeRatesTimestamp,
  fiatCurrency,
  selectedWalletAddress,
  selectedWalletChain,
  ...props
}: ConfirmRequestProps) => {
  const isPurchase = type === RequestType.PURCHASE
  const isSend = type === RequestType.SEND || type === RequestType.SEND_THIRD_PARTY

  const [loading, setLoading] = useState(false)

  const stopLoading = () => {
    setLoading(false)
  }

  const handleClick = () => {
    setLoading(true)
    confirmRequest(stopLoading)
  }

  const displayAsset = (
    Icon: React.FC<SvgIconProps<'svg', Record<string, unknown>>>,
    title: string,
    subtitle: string
  ) => (
    <Box display="flex" alignItems="center" width="145px">
      <Icon sx={{ mr: '16px', fontSize: '40px' }} />
      <Box>
        <Typography fontWeight={600}>{title}</Typography>
        <Typography variant="body2">{subtitle}</Typography>
      </Box>
    </Box>
  )

  return (
    <Modal {...props} title={`Confirm ${isPurchase ? 'Purchase' : 'Send'}`}>
      <S.Row display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          <S.DenseRow>
            <Typography>You pay</Typography>
          </S.DenseRow>
          <Typography variant="h4" fontWeight={600}>
            {isPurchase && fiatAmount}
            {isSend && formatNumber(cryptoAmount)}
          </Typography>
        </Box>
        {isPurchase &&
          displayAsset(
            fiatCurrency === FiatCurrency.USD ? USFlagIcon : FlagIcon,
            fiatCurrency,
            `${fiatCurrency} Currency`
          )}
        {isSend && displayAsset(asset.Icon, asset.name, `${asset.name} Coin`)}
      </S.Row>
      <S.Row display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          <S.DenseRow>
            <Typography>You receive</Typography>
          </S.DenseRow>
          <Typography variant="h4" fontWeight={600}>
            {isPurchase && formatNumber(cryptoAmount)}
            {isSend && fiatAmount}
          </Typography>
        </Box>
        <Typography fontWeight={600}>
          {isPurchase && displayAsset(asset.Icon, asset.name, `${asset.name} Coin`)}
          {isSend &&
            displayAsset(
              fiatCurrency === FiatCurrency.USD ? USFlagIcon : FlagIcon,
              fiatCurrency,
              `${fiatCurrency} Currency`
            )}
        </Typography>
      </S.Row>
      <S.Row>
        <S.Summary>Summary</S.Summary>
        <SummaryTable
          data={[
            {
              key: 'Order Fee',
              value: '$0.00'
            },
            {
              key: isPurchase ? 'Network Fee' : 'Transfer Fee',
              value: isPurchase ? `$${formatNumber(networkFee)}` : '$0.00'
            },
            {
              key: 'Indicative FX Rate',
              value: `1 ${asset.name} ≈ ${formatNumber(fxRate, undefined, 4)} ${fiatCurrency}`
            },
            {
              key: 'Indicative FX Rate As Of',
              value: exchangeRatesTimestamp.toLocaleDateString() + ' ' + exchangeRatesTimestamp.toLocaleTimeString()
            },
            {
              key: isPurchase ? 'Receiving Blockchain Address' : 'Sender Blockchain Address',
              value: trimId(selectedWalletAddress)
            },
            {
              key: 'Chain',
              value: selectedWalletChain
            }
          ]}
        />
      </S.Row>
      <S.Row>
        <Typography variant="subtitle2" color="grey.500">
          The quoted FX rate for the transaction will be valid for 24 hours after confirmation. If funds are received
          after 24 hours, the FX rate at the time of fund receipt will be applied to your order.
        </Typography>
      </S.Row>
      <S.Row>
        <LoadingButton variant="contained" loading={loading} fullWidth onClick={handleClick} size="large">
          Confirm {isPurchase ? 'Purchase' : 'Send'}
        </LoadingButton>
      </S.Row>
      <Typography variant="subtitle2" textAlign="center">
        By clicking confirm you are authorizing Grapes to process the transaction which may involve conversion of
        digital assets or fiat. You also understand the risks of cryptocurrency and digital wallets.{' '}
        <Link component="a" href="https://www.grapesfinance.com/tnc/" target="_blank" rel="noopener">
          Terms of service
        </Link>
      </Typography>
    </Modal>
  )
}

export default ConfirmRequest
