import React from 'react'
import { createTheme, responsiveFontSizes, CircularProgress, LinkProps, ThemeOptions } from '@mui/material'
import type {} from '@mui/lab/themeAugmentation'
import { LinkProps as RouterLinkProps, Link as RouterLink } from 'react-router-dom'

// extends mui link to also be a react router link
const LinkBehavior = React.forwardRef<never, Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }>(
  (props, ref) => {
    const { href, ...other } = props
    // Map href (MUI) -> to (react-router)
    return <RouterLink ref={ref} to={href} {...other} />
  }
)

LinkBehavior.displayName = 'LinkBehavior'

declare module '@mui/material/Paper' {
  interface PaperPropsVariantOverrides {
    card: true
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    modal: true
  }
}

declare module '@mui/material/styles' {
  interface PaletteColor {
    99?: string
    98?: string
    97?: string
    95?: string
    90?: string
    80?: string
    60?: string
    40?: string
    30?: string
    20?: string
    10?: string
    light: string
    main: string
    dark: string
    contrastText: string
  }
  // allow configuration using `createTheme`
  interface SimplePaletteColorOptions {
    99?: string
    98?: string
    97?: string
    95?: string
    90?: string
    80?: string
    60?: string
    40?: string
    30?: string
    20?: string
    10?: string
    light?: string
    main: string
    dark?: string
    contrastText?: string
  }
}

// set theme colors
let theme = createTheme({
  palette: {
    primary: {
      99: '#F8E8FF',
      98: '#F1D1FF',
      97: '#EBBAFF',
      95: '#E4A3FF',
      90: '#D775FF',
      80: '#C947FF',
      60: '#A200E5',
      40: '#6C0099',
      30: '#5A007F',
      20: '#480066',
      10: '#36004C',
      main: '#BC19FF',
      dark: '#7E00B2'
    },
    error: {
      main: '#D3080C'
    },
    info: {
      main: '#BC19FF'
    },
    background: {
      default: '#F7F8FA'
    }
  }
})

theme = createTheme(theme, {
  palette: {
    mode: 'light'
  },
  shape: {
    borderRadius: 16
  },
  typography: {
    button: {
      textTransform: 'none',
      fontWeight: 600
    }
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
        LinkComponent: LinkBehavior
      }
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true
      },
      styleOverrides: {
        sizeSmall: ({ ownerState }) => ({
          ...(ownerState.startIcon && {
            fontSize: '1rem'
          }),
          padding: '5px 15px'
        }),
        sizeMedium: {
          fontSize: '1.1rem'
        },
        sizeLarge: {
          fontSize: '1.1rem',
          padding: '12px 22px'
        },
        text: ({ ownerState }) => ({
          ...(ownerState.color === 'primary' && {
            backgroundColor: theme.palette.primary[99],
            ':hover': {
              backgroundColor: theme.palette.primary[98]
            }
          }),
          ...(ownerState.size === 'medium' && {
            padding: '6px 16px'
          })
        }),
        contained: ({ ownerState }) => ({
          ...(ownerState.color === 'inherit' && {
            ':hover': {
              backgroundColor: theme.palette.grey[400]
            }
          })
        }),
        root: ({ ownerState, theme }) => ({
          ...(ownerState.variant === 'modal' && {
            minHeight: '58px',
            border: '1px solid #000',
            borderRadius: '5px',
            color: theme.palette.text.primary,
            fontWeight: 500,
            justifyContent: 'space-between',
            paddingLeft: '18px',
            paddingRight: '18px',
            ':hover': {
              borderColor: theme.palette.primary.main
            },
            '& .MuiSvgIcon-root': {
              fontSize: '24px !important'
            }
          })
        })
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.variant === 'card' && {
            boxShadow: '0px 9px 46px rgba(208, 126, 242, 0.1), 0px 11px 15px rgba(0, 0, 0, 0.12)'
          })
        })
      }
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(0, 0, 0, 0.25)',
          '&.MuiBackdrop-invisible': {
            backgroundColor: 'transparent'
          }
        }
      }
    },
    MuiTextField: {
      defaultProps: {
        size: 'small'
      },
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.size === 'medium' && {
            'input[type="text"]': {
              fontSize: '1.4rem',
              fontWeight: 600,
              paddingTop: '14px',
              paddingBottom: '14px'
            }
          })
        })
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        sizeSmall: {
          borderRadius: 6
        },
        root: {
          '&.Mui-error': {
            backgroundColor: '#FFE6E6'
          }
        }
      }
    },
    MuiLoadingButton: {
      defaultProps: {
        loadingIndicator: <CircularProgress color="primary" size={24} />
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow:
            '0px 12px 12px rgba(0, 0, 0, 0.04), 0px 5px 12px rgba(0, 0, 0, 0.04), 0px 7px 12px rgba(0, 0, 0, 0.04)'
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        caption: {
          fontSize: '0.85rem'
        }
      }
    },
    MuiLink: {
      /**
       * in case you need the behavior of the link to be a normal anchor instead
       * of a react router Link, just set the component prop to anchor ('a' tag)
       *  */
      defaultProps: {
        component: LinkBehavior
      } as LinkProps
    },
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          height: '100%'
        },
        body: {
          height: '100%',
          '& > #root': {
            display: 'flex',
            flexDirection: 'column'
          }
        },
        a: {
          WebkitTapHighlightColor: 'rgba(0,0,0,0.3)'
        },
        div: {
          WebkitTapHighlightColor: 'rgba(0,0,0,0.3)'
        },
        '::-webkit-scrollbar': {
          width: 16
        },
        '::-webkit-scrollbar-thumb': {
          backgroundColor: 'rgba(0,0,0,0.25)',
          borderRadius: 99,
          border: '5px solid transparent',
          backgroundClip: 'content-box'
        }
      }
    }
  }
} as ThemeOptions)

export default responsiveFontSizes(theme)
