import httpClient from 'src/common/axios/config'
import {
  CreateContactDTO,
  DeleteContactBankingDetailsDTO,
  DeleteContactBlockchainDetailsDTO,
  DeleteContactDTO,
  UpdateContactDTO,
  UpdateContactNewBankDetailsDTO,
  UpdateContactNewBlockchainDetailsDTO,
  UpdateContactSetPrimaryBankDTO
} from './dto'
import { BankDetailsModel, BlockchainDetailsModel, ContactDetailsModel, ContactModel } from './models'

export const createContact = (params: CreateContactDTO) => httpClient.post<ContactModel>('/contacts', params)

export const getContacts = () => httpClient.get<ContactDetailsModel[]>('/contacts')

export const deleteContact = (params: DeleteContactDTO) => httpClient.delete('/contacts', { params })

export const updateContact = (params: UpdateContactDTO) => httpClient.put<ContactModel>('/contacts', params)

export const updateContactNewBlockchainDetails = (params: UpdateContactNewBlockchainDetailsDTO) =>
  httpClient.put<BlockchainDetailsModel[]>('/contacts/blockchainDetails', params)

export const updateContactNewBankDetails = (params: UpdateContactNewBankDetailsDTO) =>
  httpClient.put<BankDetailsModel[]>('/contacts/bankDetails', params)

export const deleteContactBlockchainDetails = (params: DeleteContactBlockchainDetailsDTO) =>
  httpClient.delete('/contacts/blockchainDetails', { params })

export const deleteContactBankDetails = (params: DeleteContactBankingDetailsDTO) =>
  httpClient.delete('/contacts/bankDetails', { params })

export const updateContactSetPrimaryBank = (params: UpdateContactSetPrimaryBankDTO) =>
  httpClient.put<BankDetailsModel[]>('/contacts/bankDetails/setPrimary', params)
