import React from 'react'
import { ListItemIcon, Menu, MenuItem, SvgIconProps, Theme, Typography, useMediaQuery } from '@mui/material'
import { BalanceIcon, ConnectWalletIcon, MetamaskIcon, MyAlgoIcon } from 'src/common/assets'
import { ExpandMore } from '@mui/icons-material'
import * as S from './styles'
import { trimId } from 'src/common/helpers'
import { Box } from '@mui/system'
import useConnectWallet from './ConnectWallet.State'
import { WalletType } from 'src/common/static'
import AttestOwnership from './AttestOwnership'
import ChooseCustodyNetwork from './ChooseCustodyNetwork'

interface WalletTypeItem {
  type: WalletType
  text: string
  Icon: React.FC<SvgIconProps<'svg', Record<string, unknown>>>
}

const walletTypeList: WalletTypeItem[] = [
  {
    type: WalletType.MYALGO,
    text: 'My Algo',
    Icon: MyAlgoIcon
  },
  {
    type: WalletType.METAMASK,
    text: 'MetaMask',
    Icon: MetamaskIcon
  },
  {
    type: WalletType.CUSTODIAL,
    text: 'Balance',
    Icon: BalanceIcon
  }
]

const ConnectWallet: React.FC = () => {
  const {
    selectedWallet,
    walletType,
    chooseWalletType,
    status,
    loading,
    modals,
    fetchWalletsLoading,
    custodialExists,
    metamaskLoading,
    anchorElWallet,
    fetchedWallet,
    closeModal,
    handleOpenWalletMenu,
    handleCloseWalletMenu,
    handleConnectWallet,
    chooseChain,
    storeWallet,
    closeAttestation
  } = useConnectWallet()

  const currentWalletTypeItem = walletTypeList.find((item) => item.type === walletType) as WalletTypeItem

  const mobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  return (
    <>
      <Box display="flex" alignItems="stretch">
        <S.StyledButton
          startIcon={<currentWalletTypeItem.Icon />}
          endIcon={<ExpandMore />}
          size="small"
          onClick={handleOpenWalletMenu}
          sx={{ whiteSpace: 'pre' }}
        >
          {mobile ? '' : currentWalletTypeItem.text}
        </S.StyledButton>
        <Menu
          sx={{ mt: '8px' }}
          id="menu-wallet-type"
          anchorEl={anchorElWallet}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          open={Boolean(anchorElWallet)}
          onClose={handleCloseWalletMenu}
        >
          {walletTypeList.map(({ type, text, Icon }, i) =>
            type === WalletType.CUSTODIAL && !custodialExists ? (
              []
            ) : (
              <MenuItem
                key={i}
                onClick={() => chooseWalletType(type)}
                value={type}
                disabled={type === WalletType.MYALGO}
              >
                <ListItemIcon>
                  <Icon fontSize="small" />
                </ListItemIcon>
                <Typography>{text}</Typography>
              </MenuItem>
            )
          )}
        </Menu>
        <S.StyledButton
          loading={fetchWalletsLoading || loading || metamaskLoading}
          disabled={walletType === WalletType.MYALGO || (walletType === WalletType.METAMASK && status === 'connected')}
          onClick={handleConnectWallet}
          startIcon={<ConnectWalletIcon />}
          variant="contained"
          size="small"
        >
          {trimId(selectedWallet.address) ||
            (walletType === WalletType.METAMASK && status === 'unavailable' && 'Install Metamask') ||
            'Connect Wallet'}
        </S.StyledButton>
      </Box>
      <AttestOwnership
        open={modals.attestation}
        onClose={closeAttestation}
        storeWallet={storeWallet}
        fetchedWallet={fetchedWallet}
      />
      <ChooseCustodyNetwork
        open={modals.custodyNetwork}
        onClose={() => closeModal('custodyNetwork')}
        chooseChain={chooseChain}
      />
    </>
  )
}

export default ConnectWallet
