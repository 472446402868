import { Box, Typography } from '@mui/material'
import { styled } from '@mui/system'

export const DenseRow = styled(Box)({
  marginBottom: '12px'
})

export const Row = styled(Box)({
  marginBottom: '24px'
})

export const Summary = styled(Typography)({
  fontWeight: 500,
  marginBottom: 4
})
