import { SvgIcon, SvgIconProps } from '@mui/material'
import React from 'react'

export const WarningIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="147" height="127" viewBox="0 0 147 127" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.625 126.125L73.5 0.25L146.375 126.125H0.625ZM74.1625 106.747C75.5979 106.747 76.7849 106.278 77.7234 105.339C78.6619 104.4 79.1312 103.214 79.1312 101.778C79.1312 100.343 78.6619 99.1557 77.7234 98.2172C76.7849 97.2786 75.5979 96.8093 74.1625 96.8093C72.7271 96.8093 71.5401 97.2786 70.6015 98.2172C69.663 99.1557 69.1937 100.343 69.1937 101.778C69.1937 103.214 69.663 104.4 70.6015 105.339C71.5401 106.278 72.7271 106.747 74.1625 106.747ZM69.1937 88.3625H79.1312V51.2625H69.1937V88.3625Z"
        fill="#F4D45C"
      />
    </SvgIcon>
  )
}
