import React from 'react'
import { useWallet } from 'src/common/context'
import { WalletType } from 'src/common/static'
import Custodial from './Custodial'
import NonCustodial from './NonCustodial'

const SendToBlockchainWallet: React.FC = () => {
  const { walletType } = useWallet()

  return walletType === WalletType.CUSTODIAL ? <Custodial /> : <NonCustodial />
}

export default SendToBlockchainWallet
