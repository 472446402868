import { styled, Toolbar } from '@mui/material'
import Box from '@mui/material/Box'

export const OuterContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary[99]
}))

export const StyledToolbar = styled(Toolbar)({
  minHeight: '55px !important'
})
