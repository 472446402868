import { Box, CircularProgress, DialogProps, Typography } from '@mui/material'
import React from 'react'
import * as S from './styles'
import { formatNumber } from 'src/common/helpers'
import { ChainAsset, FiatCurrency, RequestType } from 'src/common/static'
import { Modal } from 'src/ui'

interface RequestLoadingProps extends DialogProps {
  asset: ChainAsset
  cryptoAmount: string
  fiatAmount: number | string
  fiatCurrency: FiatCurrency
  type: RequestType
}

const RequestLoading: React.FC<RequestLoadingProps> = ({
  asset,
  cryptoAmount,
  fiatAmount,
  type,
  fiatCurrency,
  ...props
}: RequestLoadingProps) => {
  const isPurchase = type === RequestType.PURCHASE
  const isSend = type === RequestType.SEND || type === RequestType.SEND_THIRD_PARTY

  return (
    <Modal {...props} padding="60px 25px">
      <Box textAlign="center">
        <S.Row>
          <CircularProgress size={120} thickness={2} disableShrink />
        </S.Row>
        <S.Row>
          <Typography variant="h5" fontWeight={500}>
            Waiting For Confirmation
          </Typography>
        </S.Row>
        <S.Row>
          <Typography fontWeight={600}>
            {isPurchase && `Swapping $${fiatAmount} ${fiatCurrency} for ${formatNumber(cryptoAmount)} ${asset}`}
            {isSend && `Swapping ${formatNumber(cryptoAmount)} ${asset} for $${fiatAmount} ${fiatCurrency}`}
          </Typography>
        </S.Row>
      </Box>
    </Modal>
  )
}

export default RequestLoading
