import { Button, Paper, Popper } from '@mui/material'
import { styled } from '@mui/system'

export const FilterButton = styled(Button)({
  borderRadius: 8,
  fontWeight: 400,
  marginRight: 32,
  ':last-of-type': {
    marginRight: 0,
    marginLeft: 'auto'
  }
})

export const StyledPaper = styled(Paper)(({ theme }) => ({
  marginTop: 8,
  backgroundColor: theme.palette.grey[300],
  borderRadius: 8
}))

export const StyledPopper = styled(Popper)({
  zIndex: 1
})
