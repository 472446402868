import { SvgIcon, SvgIconProps } from '@mui/material'
import React from 'react'

export const WalletIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.99858 10.0405C9.02798 10.592 8.60474 11.063 8.05324 11.0924C7.30055 11.1325 6.7044 11.1816 6.23854 11.2307C5.61292 11.2967 5.23278 11.681 5.16959 12.2337C5.07886 13.0274 5 14.2284 5 16.0007C5 17.7729 5.07886 18.974 5.16959 19.7676C5.23289 20.3213 5.61207 20.7045 6.23675 20.7705C7.33078 20.8859 9.13925 21.0007 12 21.0007C14.8608 21.0007 16.6692 20.8859 17.7632 20.7705C18.3879 20.7045 18.7671 20.3213 18.8304 19.7676C18.9211 18.974 19 17.7729 19 16.0007C19 14.2284 18.9211 13.0274 18.8304 12.2337C18.7672 11.681 18.3871 11.2967 17.7615 11.2307C17.2956 11.1816 16.6995 11.1325 15.9468 11.0924C15.3953 11.063 14.972 10.592 15.0014 10.0405C15.0308 9.48904 15.5017 9.06579 16.0532 9.09519C16.8361 9.13693 17.4669 9.18854 17.9712 9.24173C19.4556 9.39828 20.6397 10.4514 20.8175 12.0066C20.9188 12.893 21 14.1722 21 16.0007C21 17.8292 20.9188 19.1084 20.8175 19.9948C20.6398 21.549 19.4585 22.6027 17.9732 22.7594C16.7919 22.8841 14.9108 23.0007 12 23.0007C9.08922 23.0007 7.20806 22.8841 6.02684 22.7594C4.54151 22.6027 3.36021 21.5491 3.18253 19.9948C3.0812 19.1084 3 17.8292 3 16.0007C3 14.1722 3.0812 12.893 3.18253 12.0066C3.36031 10.4514 4.54436 9.39828 6.02877 9.24173C6.53306 9.18854 7.16393 9.13693 7.94676 9.09519C8.49827 9.06579 8.96918 9.48904 8.99858 10.0405Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.20711 6.20711C8.81658 6.59763 8.18342 6.59763 7.79289 6.20711C7.40237 5.81658 7.40237 5.18342 7.79289 4.79289L11.2929 1.29289C11.6834 0.902369 12.3166 0.902369 12.7071 1.29289L16.2071 4.79289C16.5976 5.18342 16.5976 5.81658 16.2071 6.20711C15.8166 6.59763 15.1834 6.59763 14.7929 6.20711L13 4.41421V14C13 14.5523 12.5523 15 12 15C11.4477 15 11 14.5523 11 14V4.41421L9.20711 6.20711Z"
      />
    </SvgIcon>
  )
}
