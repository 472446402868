import { SvgIcon, SvgIconProps } from '@mui/material'
import React from 'react'

export const BlockchainIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.9626 0.269752C13.2214 0.402552 12.4752 0.734951 11.8772 1.18975C11.4374 1.52715 7.61936 5.36575 7.48636 5.60595L7.38916 5.77975L7.56296 5.74915C8.25816 5.62655 9.08096 5.66735 9.87836 5.87175L10.2822 5.97395L11.8208 4.44575C13.1804 3.09115 13.3952 2.89695 13.6916 2.75895C14.719 2.28355 15.772 2.45235 16.605 3.23935C17.2644 3.86295 17.4892 4.36395 17.4892 5.20215C17.4892 5.80015 17.3972 6.14775 17.116 6.57715C17.0342 6.70495 16.0886 7.68635 15.01 8.75975C13.17 10.5846 13.0318 10.7124 12.6946 10.8656C12.076 11.1416 11.5446 11.1774 10.88 10.9934C10.4966 10.8912 10.2768 10.7684 9.92416 10.472C9.42316 10.058 9.16256 9.97095 8.72296 10.063C8.34476 10.1448 7.95116 10.5384 7.86936 10.9166C7.77216 11.3868 7.89996 11.7242 8.33956 12.1382C9.31576 13.0634 10.7778 13.5438 12.1374 13.3802C13.037 13.2728 13.86 12.9406 14.5602 12.4142C15.0564 12.0362 18.6958 8.35595 18.9564 7.96755C19.2682 7.50235 19.5494 6.86855 19.6822 6.34715C19.8406 5.71335 19.8406 4.59915 19.677 4.00095C19.2426 2.38055 17.9442 0.990352 16.3598 0.443352C15.6646 0.203352 14.7036 0.136751 13.9626 0.269752Z"
        fill="#4F4F4F"
      />
      <path
        d="M7.76217 6.63358C7.06197 6.73058 6.27977 7.03738 5.64097 7.46678C5.26777 7.71718 1.58257 11.3718 1.19397 11.8728C0.0593714 13.355 -0.119629 15.3434 0.739171 17.0352C1.36797 18.2722 2.63037 19.2944 3.96957 19.6522C4.65457 19.8362 5.67677 19.8464 6.35157 19.6778C6.87297 19.5448 7.50677 19.2638 7.97197 18.952C8.56497 18.5534 12.7052 14.3722 12.603 14.27C12.5928 14.2598 12.373 14.2752 12.1122 14.3058C11.55 14.3722 10.768 14.3058 10.1494 14.1422L9.74557 14.0298L8.23257 15.5376C7.35857 16.4066 6.61737 17.1016 6.48437 17.1784C6.05497 17.4186 5.73297 17.4954 5.18097 17.4902C4.36317 17.48 3.85717 17.25 3.24377 16.6008C2.46177 15.7676 2.28797 14.7198 2.76337 13.6874C2.90657 13.3858 3.11597 13.1558 4.88977 11.377C6.60197 9.65938 6.90857 9.37318 7.21017 9.21478C7.85417 8.87238 8.56477 8.81098 9.21377 9.03598C9.60217 9.16878 9.76577 9.26078 10.098 9.54198C10.5988 9.95598 10.8596 10.043 11.2992 9.95098C11.6774 9.86918 12.071 9.47558 12.1528 9.09738C12.25 8.62718 12.1222 8.28978 11.6826 7.87578C11.3554 7.56398 10.7932 7.19078 10.3792 7.00678C9.65857 6.68978 8.53397 6.52618 7.76217 6.63358Z"
        fill="#4F4F4F"
      />
    </SvgIcon>
  )
}
