import { LoadingButton } from '@mui/lab'
import { styled } from '@mui/system'

export const StyledButton = styled(LoadingButton)(({ theme }) => ({
  marginRight: '16px',
  [theme.breakpoints.down('sm')]: {
    marginRight: '4px'
  },
  '&.Mui-disabled': {
    color: theme.palette.text.primary
    // backgroundColor: 'transparent'
  },
  '&.MuiLoadingButton-loading': {
    color: 'transparent'
    // backgroundColor: theme.palette.action.disabledBackground
  }
}))
