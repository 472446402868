import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const BalanceIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="24" height="24" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.0172 1.44135C10.1872 1.44135 9.35174 1.55043 8.5283 1.77096C3.43854 3.13497 0.407061 8.38536 1.77088 13.4751C3.1347 18.565 8.38529 21.5963 13.4752 20.2327C18.5649 18.8686 21.5963 13.6181 20.2324 8.52838C19.5717 6.06281 17.9905 4.00201 15.7799 2.72567C14.3074 1.87569 12.6731 1.44135 11.0172 1.44135ZM11.0043 22C6.14913 22 1.69447 18.7555 0.379473 13.8481C-0.380848 11.0106 0.00938554 8.04718 1.47812 5.50323C2.94685 2.95946 5.3182 1.13987 8.15545 0.379553C10.9924 -0.380949 13.9561 0.00946601 16.5001 1.4782C19.0439 2.94675 20.8637 5.31828 21.6238 8.15553C23.1933 14.0125 19.705 20.0545 13.848 21.624H13.8478C12.8984 21.8784 11.9437 22 11.0043 22Z"
        fill="#2369AD"
      />
      <path
        d="M8.01145 5.70292C7.89182 5.70292 7.77129 5.71846 7.65275 5.74969H7.65257C6.91919 5.94304 6.48243 6.6871 6.67884 7.40833C6.77414 7.75787 7.00196 8.04975 7.32055 8.23077C7.63914 8.41161 8.01036 8.45963 8.36562 8.36591C9.099 8.17257 9.53594 7.42867 9.33935 6.70728C9.24422 6.35791 9.01622 6.06586 8.69763 5.88501C8.48561 5.76451 8.24998 5.70292 8.01145 5.70292ZM8.00473 9.67839C7.5431 9.67839 7.08782 9.55932 6.67739 9.32635C6.06109 8.97644 5.62052 8.41161 5.43627 7.73573C5.05614 6.3406 5.90117 4.90155 7.31964 4.52773C8.73794 4.15337 10.2018 4.98474 10.5819 6.37987C10.962 7.77501 10.1172 9.21406 8.69854 9.58788C8.46909 9.64839 8.23619 9.67839 8.00473 9.67839ZM14.1337 13.4446C14.0161 13.4446 13.8965 13.4596 13.7775 13.4909C13.0442 13.6842 12.6072 14.4281 12.8037 15.1496C13.0005 15.8707 13.7565 16.301 14.4904 16.1072C15.2238 15.9141 15.6607 15.1699 15.4643 14.4485C15.2995 13.8442 14.7418 13.4446 14.1337 13.4446ZM14.1347 17.4187C12.9587 17.4187 11.8799 16.6459 11.5613 15.4769C11.181 14.0818 12.026 12.6427 13.4446 12.2689C14.8636 11.896 16.3268 12.7259 16.7067 14.121C16.891 14.7969 16.7966 15.5028 16.4408 16.1088C16.085 16.7147 15.5106 17.1482 14.8234 17.3292C14.5933 17.3897 14.3621 17.4187 14.1347 17.4187Z"
        fill="#2369AD"
      />
      <path
        d="M3.82549 18.9456L3.81787 17.4632C4.27428 17.4606 4.73229 17.3952 5.17879 17.2692C6.62702 16.86 7.8544 15.8201 8.54601 14.416L12.3514 6.75466C13.2231 4.98449 14.7725 3.67171 16.6012 3.15512C17.1638 2.99618 17.7414 2.91382 18.318 2.91064L18.3257 4.39282C17.8693 4.39544 17.4115 4.4608 16.9649 4.58687C15.5165 4.99607 14.2892 6.03598 13.5975 7.44008L9.79216 15.1014C8.92042 16.8716 7.37106 18.1843 5.54257 18.7009C4.97989 18.8598 4.40216 18.9421 3.82549 18.9456Z"
        fill="#2369AD"
      />
    </SvgIcon>
  )
}
