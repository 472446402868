import { Button, Divider, InputAdornment, TableRow, TextField, Typography } from '@mui/material'
import { Box, styled } from '@mui/system'

export const Row = styled(Box)({
  marginBottom: '24px'
})

export const InputLabel = styled(Typography)({
  marginBottom: '12px'
})

export const AmountTextField = styled(TextField)(({ theme }) => ({
  '& .Mui-disabled, &': {
    color: theme.palette.text.primary,
    WebkitTextFillColor: theme.palette.text.primary
  }
}))

export const StyledInputAdornment = styled(InputAdornment)({
  color: 'inherit',
  fontSize: '1rem',
  fontWeight: 600,
  '& > .MuiSvgIcon-root': {
    marginRight: 8
  }
})

export const Summary = styled(Typography)({
  fontWeight: 500,
  marginBottom: 4
})

export const StyledTableRow = styled(TableRow)({
  '& td': {
    border: 0,
    padding: '4px 0'
  }
})

export const StyledDivider = styled(Divider)({
  margin: '28px 0'
})

export const WalletBalance = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  float: 'right',
  fontSize: '14px',
  marginTop: '2px'
}))

export const StyledButton = styled(Button)({
  boxSizing: 'border-box',
  height: '32px',
  border: '1px solid #FFFFFF',
  borderRadius: '8px',
  color: '#4F4F4F',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '13px',
  lineHeight: '16px'
})
