import React, { useRef, useState } from 'react'
import { OrderFilter, Query, SelectedFilters } from '../Orders'
import { ExpandMore } from '@mui/icons-material'
import * as S from './styles'
import { ClickAwayListener, Grow, MenuItem, MenuList, Typography } from '@mui/material'

interface FilterMenuProps {
  filter: OrderFilter
  selectedFilters: SelectedFilters
  setSelectedFilters: React.Dispatch<React.SetStateAction<SelectedFilters>>
  setQuery: React.Dispatch<React.SetStateAction<Query>>
}

const FilterMenu: React.FC<FilterMenuProps> = ({
  filter,
  selectedFilters,
  setSelectedFilters,
  setQuery
}: FilterMenuProps) => {
  const [open, setOpen] = useState(false)
  const anchorRef = useRef<HTMLButtonElement>(null)

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event?: Event | React.SyntheticEvent) => {
    if (event && anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return
    }
    anchorRef.current?.focus()
    setOpen(false)
  }

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault()
      handleClose()
    } else if (event.key === 'Escape') {
      handleClose()
    }
  }

  const chooseFilter = (value: string) => {
    setSelectedFilters({ ...selectedFilters, [filter.type.value]: value })
    setQuery((prevQuery) => ({ ...prevQuery, [filter.type.value]: value === 'all' ? undefined : value }))
    handleClose()
  }

  let currentSelectedItemLabel = filter.items.find(
    (item) => item.value === selectedFilters[filter.type.value as keyof SelectedFilters]
  )?.label

  if (currentSelectedItemLabel === 'All') currentSelectedItemLabel = filter.type.label

  const isFilterSortBy = filter.type.value === 'sortBy'

  return (
    <>
      <S.FilterButton
        variant="contained"
        color="inherit"
        endIcon={<ExpandMore />}
        onClick={handleToggle}
        ref={anchorRef}
      >
        {isFilterSortBy && 'Sort By: '}
        {currentSelectedItemLabel}
      </S.FilterButton>
      <S.StyledPopper
        sx={{ zIndex: 1 }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement={isFilterSortBy ? 'bottom-end' : 'bottom-start'}
        transition
        disablePortal
        modifiers={[
          {
            name: 'flip',
            enabled: false
          },
          {
            name: 'preventOverflow',
            enabled: false
          }
        ]}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom-start' ? 'left top' : 'right top'
            }}
          >
            <S.StyledPaper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="filter"
                  aria-labelledby="composition-button"
                  onKeyDown={handleListKeyDown}
                >
                  {filter.items.map(({ value, label }, i) => (
                    <MenuItem key={i} onClick={() => chooseFilter(value)}>
                      <Typography>{label}</Typography>
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </S.StyledPaper>
          </Grow>
        )}
      </S.StyledPopper>
    </>
  )
}

export default FilterMenu
