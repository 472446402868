import React, { useEffect, useState } from 'react'
import { OpenInNew } from '@mui/icons-material'
import { Button, Divider, Stack, Typography } from '@mui/material'
import { Container, alertNotification } from 'src/ui'
import { checkOrderEligibility, KYC_STATUS, updateUserKYC } from 'src/common/api'
import { ChainAsset } from 'src/common/static'
import { useWallet } from 'src/common/context'

const Custodial: React.FC = () => {
  const { selectedWallet } = useWallet()
  const [error, setError] = useState('Please wait while we check your KYC')
  const [disabledFormButton, setDisabledFormButton] = useState(true)

  const initiateKYC = () => {
    window.iComply.init(document.getElementById('icomply'), {
      icomplyPreview: true,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      callback: async (serverResponse: any) => {
        try {
          await updateUserKYC({ kycId: serverResponse.entityId })
        } catch (err) {
          alertNotification('Initiating KYC module failed. Please contact support.', 'error')
        }
      }
    })
  }

  const checkKYC = async () => {
    let walletError = ''
    try {
      const response = await checkOrderEligibility({
        asset: ChainAsset.USDC,
        address: selectedWallet.address as string,
        chain: selectedWallet.chain
      })
      const { kycStatus, walletScreening } = response.data
      if (walletScreening === 'fail') {
        walletError = "You can't use this wallet"
      } else {
        switch (kycStatus) {
          case KYC_STATUS.REJECTED: {
            walletError = 'Transaction declined.'
            break
          }
          case KYC_STATUS.PENDING:
          case KYC_STATUS.REVIEW:
          case KYC_STATUS.NEW: {
            walletError = 'Your KYC decision is still pending'
            break
          }
          case KYC_STATUS.UNINITIATED: {
            initiateKYC()
            break
          }
          case KYC_STATUS.APPROVED: {
            setError('')
            setDisabledFormButton(false)
            break
          }
        }
      }
    } catch (err) {
      alertNotification()
    }
    setError(walletError)
  }

  useEffect(() => {
    checkKYC()
  }, [])

  return (
    <Container error={error}>
      <Stack spacing={3}>
        <Typography variant="h5" fontWeight={600}>
          Send
        </Typography>
        <Typography variant="h6">Transfer Request Form - Digital assets</Typography>
        <Typography variant="body2">
          Please submit the following form to initiate an on-chain send from your Grapes digital wallet. Note that we
          are only able to send assets to a wallet address that is on the same chain as the sending wallet.
        </Typography>
        <Divider />
        <Button
          component="a"
          endIcon={<OpenInNew />}
          size="large"
          target="_blank"
          rel="noopener"
          disabled={disabledFormButton}
          href="https://secure.na4.adobesign.com/public/esignWidget?wid=CBFCIBAA3AAABLblqZhA4YekJxMlqV5A9qydiyyTS6L1DBHAx0Z_F0c2G8eOtJYwGzrmh8AUd_31WiMoxxAQ*"
        >
          On-Chain Transfer Form
        </Button>
        <Button variant="contained" size="large" href="/">
          Return to Dashboard
        </Button>
      </Stack>
    </Container>
  )
}

export default Custodial
