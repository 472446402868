import React, { useState } from 'react'
import Modal from '../Modal'

interface State {
  title: string
  message: string
  open: boolean
  allowClosing: boolean
}

// this will be used to control the state inside AlertDialog
let setDialogState: React.Dispatch<React.SetStateAction<State>>

const AlertDialog: React.FC = () => {
  const [state, setState] = useState<State>({
    title: '',
    message: '',
    open: false,
    allowClosing: true
  })

  setDialogState = setState

  const { title, message, open, allowClosing } = state

  return (
    <Modal title={title} open={open} onClose={allowClosing ? () => setState({ ...state, open: false }) : undefined}>
      {message}
    </Modal>
  )
}

export const alertDialog = (title: string, message: string, allowClosing = true) => {
  setDialogState({ title, message, open: true, allowClosing })
}

export default AlertDialog
