import { styled } from '@mui/material/styles'

export const Container = styled('div')({
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  padding: '16px'
})

export const Subtext = styled('span')(({ theme }) => ({
  color: theme.palette.primary.main
}))
