import { SvgIcon, SvgIconProps } from '@mui/material'
import React from 'react'

export const FlagIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 9.99997C0 13.4717 1.76933 16.5301 4.4552 18.3229V1.67705C1.76933 3.46985 0 6.52824 0 9.99997Z"
        fill="#FF0000"
      />
      <path
        d="M20.0001 9.99997C20.0001 6.52824 18.2308 3.46985 15.5449 1.67705V18.3229C18.2308 16.5301 20.0001 13.4717 20.0001 9.99997Z"
        fill="#FF0000"
      />
      <path
        d="M15.5447 18.3229V1.67706C13.9022 0.583483 11.9731 0 9.99988 0C8.02667 0 6.09752 0.583483 4.45508 1.67706V18.3229C6.09752 19.4165 8.02667 20 9.99988 20C11.9731 20 13.9022 19.4165 15.5447 18.3229Z"
        fill="white"
      />
      <path
        d="M9.78935 15.4399H10.1867L10.1216 12.8711C10.1407 12.8371 10.1676 12.808 10.2 12.7862C10.2324 12.7644 10.2695 12.7506 10.3083 12.7458C10.3784 12.7607 12.6016 13.0266 12.6016 13.0266C12.6016 13.0266 12.4509 12.4999 12.3992 12.2943C12.3499 12.0986 12.5213 11.9933 12.5213 11.9933L14.8192 10.0415C14.8192 10.0415 14.376 9.81754 14.2672 9.7554C14.1584 9.69327 14.2133 9.4706 14.2133 9.4706L14.6197 7.96554C14.6197 7.96554 13.3773 8.22687 13.3664 8.2322C13.0997 8.33594 13.0304 8.0866 13.0304 8.0866L12.8597 7.47967L11.8061 8.7138C11.8061 8.7138 11.6589 8.90954 11.5051 8.82927C11.3696 8.7226 11.44 8.47807 11.44 8.47807L11.8864 5.94474C11.8864 5.94474 11.4331 6.18607 11.2376 6.2914C11.0101 6.41514 10.8731 6.24447 10.8731 6.24447L10 4.55994L9.16722 6.1906C9.16722 6.1906 9.06562 6.45247 8.76082 6.2858L8.11869 5.9346L8.57522 8.49834C8.57522 8.49834 8.61869 8.78447 8.47975 8.8362C8.31762 8.90314 8.15975 8.65887 8.15975 8.65887L7.14643 7.4698L6.98083 8.06687C6.98083 8.06687 6.97416 8.28607 6.72483 8.2474C6.4755 8.20874 5.4003 7.95647 5.4003 7.95647L5.77177 9.3914C5.77177 9.3914 5.86537 9.69087 5.72137 9.77274C5.57737 9.8546 5.18457 10.0394 5.18457 10.0394L7.54269 12.0463C7.54269 12.0463 7.64936 12.197 7.59789 12.3663L7.40216 13.0237C7.40216 13.0237 9.62668 12.7535 9.72908 12.7466C9.85948 12.7533 9.89975 12.897 9.89975 12.897L9.78935 15.4399Z"
        fill="#FF0000"
      />
    </SvgIcon>
  )
}
