import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const EthIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="24" height="24" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0C4.92487 0 0 4.92487 0 11C0 17.0751 4.92487 22 11 22Z"
        fill="#627EEA"
      />
      <path d="M11 2.75V8.84812L16.1542 11.1512L11 2.75Z" fill="white" fillOpacity="0.602" />
      <path d="M11.3424 2.75L6.1875 11.1512L11.3424 8.84812V2.75Z" fill="white" />
      <path d="M11 15.367V19.5106L16.1576 12.375L11 15.367Z" fill="white" fillOpacity="0.602" />
      <path d="M11.3424 19.5106V15.3663L6.1875 12.375L11.3424 19.5106Z" fill="white" />
      <path d="M11 14.2319L16.1542 11.2392L11 8.9375V14.2319Z" fill="white" fillOpacity="0.2" />
      <path d="M6.1875 11.2392L11.3424 14.2319V8.9375L6.1875 11.2392Z" fill="white" fillOpacity="0.602" />
    </SvgIcon>
  )
}
