import { LoadingButton } from '@mui/lab'
import { Stack, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useState } from 'react'
import { saveKycQuestions } from 'src/common/api'
import { LogoSecondary } from 'src/common/assets'
import { alertNotification } from 'src/ui'

interface InputErrors {
  occupation?: string
  purpose?: string
}

interface ComplianceProps {
  showIcomply: () => void
}

const Compliance: React.FC<ComplianceProps> = ({ showIcomply }: ComplianceProps) => {
  const [values, setValues] = useState({ occupation: '', purpose: '' })
  const [errors, setErrors] = useState<InputErrors>({
    occupation: undefined,
    purpose: undefined
  })
  const [loading, setLoading] = useState(false)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name
    const newValue = event.target.value
    if (newValue) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: undefined }))
    }
    setValues({ ...values, [name]: newValue })
  }

  const saveQuestions = async () => {
    try {
      setLoading(true)
      await saveKycQuestions(values)
      setLoading(false)
      showIcomply()
    } catch (err) {
      alertNotification('Updating occupation on profile failed. Please contact support.', 'error')
    }
  }

  const handleOnClick = async () => {
    const errors: InputErrors = {}

    if (!values.occupation) {
      errors.occupation = 'Required'
    }
    if (!values.purpose) {
      errors.purpose = 'Required'
    }
    setErrors(errors)
    if (!Object.keys(errors).length) {
      await saveQuestions()
    }
  }

  return (
    <Box p="16px">
      <Stack spacing={3} alignItems="center" mb="32px">
        <LogoSecondary height={64} />
        <Typography variant="h5" fontWeight={600}>
          Grapes KYC Portal
        </Typography>
      </Stack>
      <Stack spacing={3}>
        <Box>
          <Typography mb="12px">What is your occupation?</Typography>
          <TextField
            value={values.occupation}
            onChange={handleChange}
            name="occupation"
            placeholder="Occupation"
            error={Boolean(errors.occupation)}
            helperText={errors.occupation}
            fullWidth
          />
        </Box>
        <Box>
          <Typography mb="12px">Reason for using Grapes?</Typography>
          <TextField
            value={values.purpose}
            onChange={handleChange}
            name="purpose"
            placeholder="Reason"
            error={Boolean(errors.purpose)}
            helperText={errors.purpose}
            fullWidth
          />
        </Box>
        <Box alignSelf="center" pt="16px" width={150}>
          <LoadingButton variant="contained" fullWidth size="large" onClick={handleOnClick} loading={loading}>
            Next
          </LoadingButton>
        </Box>
      </Stack>
    </Box>
  )
}

export default Compliance
