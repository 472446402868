import { Paper } from '@mui/material'
import { Box, styled } from '@mui/system'

interface DashboardContainerProps {
  notification: boolean
}

export const DashboardContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'notification'
})<DashboardContainerProps>(({ notification }) => ({
  display: 'flex',
  alignItems: 'stretch',
  height: `calc(100vh - ${notification ? 130 : 75}px)`
}))

export const WalletsSideBar = styled(Paper)(({ theme }) => ({
  flex: '1 1 25%',
  // maxWidth: '400px',
  minWidth: 360,
  backgroundColor: theme.palette.background.paper,
  padding: '60px 24px',
  overflowY: 'auto'
}))

export const LeftSection = styled('div')({
  flex: '1 1 75%',
  flexGrow: 1,
  overflowY: 'auto'
})

export const SummaryContainer = styled('div')({
  maxWidth: '1000px',
  marginRight: 'auto',
  marginLeft: 'auto'
})

export const EmptyWallets = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: 'calc(100vh - 300px)',
  textAlign: 'center'
})
