import { IconButton, Table, TableBody, TableCell, Tooltip } from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'
import React from 'react'
import * as S from './styles'

interface Row {
  key: string | React.ReactNode
  value: string | React.ReactNode
}

interface SummaryTableProps {
  data: Row[]
  padding?: number | string
}

const indicativeRateTooltipText =
  'The indicative rate shown is a floating exchange rate. We use the indicative rate to approximate \
  the amount of fiat or digital assets you will get. The indicative rate may vary slightly from the \
  final exchange rate which is only provided after your order confirmation on Grapes. The actual amount \
  may also vary if your fiat or digital asset deposits are received after the 24-hour cut-off period.'

const SummaryTable: React.FC<SummaryTableProps> = ({ data, padding }: SummaryTableProps) => {
  return (
    <Table size="small">
      <TableBody>
        {data.map((row, i) => {
          if (row.key !== 'Indicative FX Rate') {
            return (
              <S.StyledTableRow key={i} padding={padding}>
                <TableCell>{row.key}</TableCell>
                <TableCell align="right">{row.value}</TableCell>
              </S.StyledTableRow>
            )
          }
          return (
            <S.StyledTableRow key={i} padding={padding}>
              <TableCell>
                {row.key}
                <Tooltip title={indicativeRateTooltipText} sx={{ padding: 0.3 }}>
                  <IconButton>
                    <InfoIcon sx={{ fontSize: 'small' }} />
                  </IconButton>
                </Tooltip>
              </TableCell>
              <TableCell align="right">{row.value}</TableCell>
            </S.StyledTableRow>
          )
        })}
      </TableBody>
    </Table>
  )
}

export default SummaryTable
