import { useState } from 'react'
import { calculatePasswordStrength, isEmailValid } from 'src/common/helpers'
import { FormikHelpers } from 'formik'
import { SignUpParams } from '@aws-amplify/auth'
import { useAuth } from 'src/common/context'

interface Values {
  fname: string
  lname: string
  businessName?: string
  email: string
  password: string
  confirmPassword: string
}

interface State {
  error?: string
  success?: string
  bar: {
    strengthColor: string
    value: number
  }
}

export const initialValues: Values = {
  fname: '',
  lname: '',
  businessName: '',
  email: '',
  password: '',
  confirmPassword: ''
}

export const validate = (values: Values, typeOfUser: string) => {
  const errors: Partial<Values> = {}
  let bar = {
    strengthColor: 'disabled',
    value: 0
  }
  if (!values.fname.trim()) {
    errors.fname = 'Required'
  }
  if (!values.lname.trim()) {
    errors.lname = 'Required'
  }
  if (typeOfUser === 'business' && !values.businessName?.trim()) {
    errors.businessName = 'Required'
  }
  if (!values.email) {
    errors.email = 'Required'
  } else if (!isEmailValid(values.email)) {
    errors.email = 'Invalid email address'
  }
  if (!values.password) {
    errors.password = 'Required'
  } else {
    const { error, bar: barState } = calculatePasswordStrength(values.password)
    if (error) {
      errors.password = error
    }
    bar = barState
  }
  if (!values.confirmPassword) {
    errors.confirmPassword = 'Required'
  } else if (values.confirmPassword !== values.password) {
    errors.confirmPassword = 'Password mismatch'
  }
  return { errors, bar }
}

const useSignup = () => {
  const { signup } = useAuth()

  const [typeOfUser, setTypeOfUser] = useState('')
  const [state, setState] = useState<State>({
    error: undefined,
    success: undefined,
    bar: {
      strengthColor: 'disabled',
      value: 0
    }
  })

  const onSubmit = (values: Values, { setSubmitting, resetForm }: FormikHelpers<Values>) => {
    const { email, password, businessName, fname, lname } = values

    const signupParams: SignUpParams = {
      username: email.toLowerCase(),
      password: password,
      attributes: {
        given_name: fname.trim(),
        family_name: lname.trim(),
        'custom:businessName': typeOfUser === 'business' ? businessName : undefined
      }
    }

    signup(signupParams, (authSuccess, response) => {
      if (authSuccess) {
        setState({
          ...state,
          success: response,
          error: undefined,
          bar: { strengthColor: 'disabled', value: 0 }
        })
        resetForm()
        setSubmitting(false)
      } else {
        setState({
          ...state,
          error: response,
          success: undefined
        })
        setSubmitting(false)
      }
    })
  }

  return { state, typeOfUser, setTypeOfUser, setState, onSubmit }
}

export default useSignup
