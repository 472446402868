import { Container, Paper } from '@mui/material'
import { styled } from '@mui/system'

export const StyledContainer = styled(Container)({
  marginTop: 60,
  paddingBottom: 60
})

export const StyledPaper = styled(Paper)({
  padding: 40
})
