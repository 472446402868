import * as React from 'react'
import { Typography, Grid, Link, Stack, Button } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import * as S from './styles'
import { Container, PasswordInputField } from 'src/ui'
import { Formik, Field, Form } from 'formik'
import { TextField } from 'formik-mui'
import useSignup, { initialValues, validate } from './Signup.State'
import VerifyEmail from '../VerifyEmail'
import MobileDesktopNotification from '../MobileDesktopNotification'

const Signup: React.FC = () => {
  const { state, typeOfUser, setTypeOfUser, setState, onSubmit } = useSignup()

  // check if user is on a mobile device
  const details = navigator.userAgent
  const regexp = /android|iphone|kindle|ipad/i
  const isMobileDevice = regexp.test(details)

  if (isMobileDevice) {
    return <MobileDesktopNotification />
  } else {
    return !state.success ? (
      !typeOfUser ? (
        <Container title="Create your account">
          <Stack spacing={3}>
            <Typography variant="h6">What type of user are you?</Typography>
            <Button variant="contained" size="large" onClick={() => setTypeOfUser('individual')}>
              Individual
            </Button>
            <Button variant="contained" size="large" onClick={() => setTypeOfUser('business')}>
              Business
            </Button>
          </Stack>
        </Container>
      ) : (
        <Formik
          initialValues={initialValues}
          validate={(values) => {
            const { errors, bar } = validate(values, typeOfUser)
            setState({ ...state, bar })
            return errors
          }}
          onSubmit={onSubmit}
        >
          {({ submitForm, isSubmitting }) => (
            <Form>
              <Container title="Create your account" error={state.error}>
                <S.Row>
                  <Typography variant="body2">Please fill the required fields *</Typography>
                </S.Row>
                <S.Row>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <S.InputLabel>First name *</S.InputLabel>
                      <Field component={TextField} name="fname" placeholder="First Name" fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <S.InputLabel>Last name *</S.InputLabel>
                      <Field component={TextField} name="lname" placeholder="Last Name" fullWidth />
                    </Grid>
                  </Grid>
                </S.Row>
                {typeOfUser === 'business' && (
                  <S.Row>
                    <S.InputLabel>Business name *</S.InputLabel>
                    <Field component={TextField} name="businessName" placeholder="Business Name" fullWidth />
                  </S.Row>
                )}
                <S.Row>
                  <S.InputLabel>Email *</S.InputLabel>
                  <Field component={TextField} name="email" placeholder="Email" fullWidth />
                </S.Row>
                <S.Row>
                  <PasswordInputField state={{ strengthColor: state.bar.strengthColor, value: state.bar.value }} />
                  <S.PasswordHelper variant="subtitle2">
                    Your password should have at least 8 characters, one uppercase, one lowercase, one number and one
                    special character
                  </S.PasswordHelper>
                </S.Row>
                <S.Row>
                  <S.InputLabel>Confirm password *</S.InputLabel>
                  <Field
                    component={TextField}
                    type="password"
                    name="confirmPassword"
                    placeholder="Password"
                    fullWidth
                  />
                </S.Row>
                <S.Row>
                  <LoadingButton
                    variant="contained"
                    size="large"
                    // disabled={!(dirty && isValid)}
                    loading={isSubmitting}
                    onClick={submitForm}
                    fullWidth
                  >
                    Create Account
                  </LoadingButton>
                </S.Row>
                <Typography variant="subtitle2" textAlign="center">
                  By checking &Prime;Create Account&Prime; you confirm that you have read and agree to the{' '}
                  <Link component="a" href="https://www.grapesfinance.com/tnc/" target="_blank" rel="noopener">
                    Terms of Use
                  </Link>{' '}
                  &{' '}
                  <Link
                    component="a"
                    href="https://www.grapesfinance.com/privacypolicy/"
                    target="_blank"
                    rel="noopener"
                  >
                    Privacy Policy
                  </Link>
                  .
                </Typography>
              </Container>
            </Form>
          )}
        </Formik>
      )
    ) : (
      <VerifyEmail email={state.success} />
    )
  }
}

export default Signup
