import React, { useEffect, useState } from 'react'
import { Alert, AlertTitle, Box, Stack, Typography } from '@mui/material'
import { ActionItem, Container } from 'src/ui'
import flagsmith from 'flagsmith'
import { DISPLAY_SEND_ALERT_FLAG } from 'src/common/api/flagsmith/grapesFeatureFlags'
import { AlertFlag } from 'src/common/api/flagsmith/flagInterface'

const actions = [
  {
    header: 'Send fiat to my bank account',
    subtitle: 'Initiate a transfer to my bank account using digital assets',
    link: '/send/fiat-to-my-bank-account',
    comingSoon: false
  },
  {
    header: 'Send digital assets to a blockchain wallet',
    subtitle: 'Transfer digital assets to a blockchain address',
    link: '/send/digital-assets-to-blockchain-wallet',
    comingSoon: false
  },
  {
    header: 'Send fiat to a third-party bank account',
    subtitle: 'Initiate a transfer to a third-party bank account using digital assets',
    link: '/send/fiat-to-third-party-bank-account',
    comingSoon: false
  }
]

const Send: React.FC = () => {
  const [isFlagEnabled, setIsFlagEnabled] = useState(false)
  const [flagValue, setFlagValue] = useState<AlertFlag | null | undefined>(null)

  const getSendAlertFlag = async () => {
    const flag = flagsmith.getAllFlags()[DISPLAY_SEND_ALERT_FLAG]
    if (flag.enabled) {
      setIsFlagEnabled(flag.enabled)
      setFlagValue(JSON.parse(flag.value as string))
    }
  }

  useEffect(() => {
    getSendAlertFlag()
  }, [])

  return (
    <Container>
      <Box paddingBottom={'10px'}>
        {isFlagEnabled && (
          <Alert severity={flagValue?.alertType}>
            {' '}
            <AlertTitle> {flagValue?.alertTitle}</AlertTitle>
            {flagValue?.alertMessage && (
              <Typography variant="body1" dangerouslySetInnerHTML={{ __html: flagValue.alertMessage }} />
            )}
          </Alert>
        )}
      </Box>
      <Box mb="32px">
        <Typography variant="h5" fontWeight={600}>
          Where do you want to send funds?
        </Typography>
      </Box>
      <Stack spacing={3}>
        {actions.map(({ header, subtitle, link, comingSoon }, i) => (
          <ActionItem key={i} header={header} subtitle={subtitle} link={link} disabled={comingSoon} />
        ))}
      </Stack>
    </Container>
  )
}

export default Send
