import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import ReactGA from 'react-ga4'

const ScrollToTop: React.FC = () => {
  const { pathname, search } = useLocation()

  useEffect(() => {
    if (!ReactGA.isInitialized) {
      ReactGA.initialize(process.env.REACT_APP_GA4 as string)
    }
    ReactGA.send({ hitType: 'pageview', page: location + search })
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}

export default ScrollToTop
