import { Chip, ChipProps, Paper } from '@mui/material'
import { styled } from '@mui/system'

interface FilterProps extends ChipProps {
  active?: boolean
}

export const Filter = styled(Chip, {
  shouldForwardProp: (prop) => prop !== 'active'
})<FilterProps>(({ active, theme }) => ({
  backgroundColor: theme.palette.primary[99],
  borderRadius: 6,
  marginLeft: 24,
  ':hover, :active': {
    backgroundColor: theme.palette.primary[98],
    boxShadow: 'none'
  },
  ...(active && { backgroundColor: theme.palette.primary[98] })
}))

export const Tooltip = styled(Paper)({
  padding: '5px 9px'
})
