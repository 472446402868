import { Box, CircularProgress, Pagination, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import * as S from './styles'
import { getOrders, OrderDetails } from 'src/common/api'
import { Order } from 'src/common/components'
import FilterMenu from './FilterMenu'
import { useCancelToken } from 'src/common/hooks'
import { alertNotification } from 'src/ui'

export interface OrderFilter {
  type: {
    value: string
    label: string
  }
  items: {
    value: string
    label: string
  }[]
}

export const orderFilters: OrderFilter[] = [
  {
    type: {
      value: 'type',
      label: 'Type'
    },
    items: [
      {
        value: 'PURCHASE',
        label: 'Purchase'
      },
      {
        value: 'REDEEM',
        label: 'Redeem'
      },
      {
        value: 'all',
        label: 'All'
      }
    ]
  },
  {
    type: {
      value: 'status',
      label: 'Status'
    },
    items: [
      {
        value: 'INITIATED',
        label: 'Initiated'
      },
      {
        value: 'PROCESSED',
        label: 'Processed'
      },
      {
        value: 'DECLINED',
        label: 'Declined'
      },
      {
        value: 'FAILED',
        label: 'Failed'
      },
      {
        value: 'all',
        label: 'All'
      }
    ]
  },
  {
    type: {
      value: 'chain',
      label: 'Chain'
    },
    items: [
      {
        value: 'ALGORAND',
        label: 'Algorand'
      },
      {
        value: 'ETHEREUM',
        label: 'Ethereum'
      },
      {
        value: 'all',
        label: 'All'
      }
    ]
  },
  {
    type: {
      value: 'asset',
      label: 'Asset'
    },
    items: [
      {
        value: 'QCAD',
        label: 'QCAD'
      },
      {
        value: 'USDC',
        label: 'USDC'
      },
      {
        value: 'all',
        label: 'All'
      }
    ]
  },
  {
    type: {
      value: 'fiatCurrency',
      label: 'Currency'
    },
    items: [
      {
        value: 'USD',
        label: 'USD'
      },
      {
        value: 'CAD',
        label: 'CAD'
      },
      {
        value: 'all',
        label: 'All'
      }
    ]
  },
  {
    type: {
      value: 'sortBy',
      label: 'Sort By: Newest'
    },
    items: [
      {
        value: 'NEWEST',
        label: 'Newest'
      },
      {
        value: 'OLDEST',
        label: 'Oldest'
      },
      {
        value: 'HIGHEST',
        label: 'Highest'
      },
      {
        value: 'LOWEST',
        label: 'Lowest'
      }
    ]
  }
]

export interface Query {
  type?: string
  status?: string
  chain?: string
  asset?: string
  fiatCurrency?: string
  sortBy: string
}

export interface SelectedFilters {
  type: string
  status: string
  chain: string
  asset: string
  fiatCurrency: string
  sortBy: string
}

const Orders: React.FC = () => {
  const { newCancelToken, isRequestCancel } = useCancelToken()
  const [loading, setLoading] = useState(true)
  const [orders, setOrders] = useState<(OrderDetails | null)[]>([])
  const [expanded, setExpanded] = useState<number | false>(false)
  const [query, setQuery] = useState<Query>({ sortBy: 'NEWEST' })
  const [selectedFilters, setSelectedFilters] = useState<SelectedFilters>({
    type: 'all',
    status: 'all',
    chain: 'all',
    asset: 'all',
    fiatCurrency: 'all',
    sortBy: 'NEWEST'
  })

  const LIMIT = 10
  const [page, setPage] = React.useState({ current: 1, total: LIMIT, loading: true })

  const currentPageOffset = (page.current - 1) * LIMIT

  const fetchOrders = async (isQueryChanged = false) => {
    try {
      setLoading(true)
      const response = await getOrders({ ...query, page: isQueryChanged ? undefined : page.current }, newCancelToken())
      const { orders: fetchedOrders, numOfPages } = response.data
      if (!fetchedOrders.length) {
        setOrders([])
      } else if (isQueryChanged) {
        const newOrders = Array(numOfPages * LIMIT).fill(null)
        newOrders.splice(0, fetchedOrders.length, ...fetchedOrders)
        // add orders to the start of the array
        setOrders(newOrders)
        setPage({ current: 1, total: numOfPages, loading: false })
      } else {
        setOrders((prevOrders) => {
          const newOrders = prevOrders.slice()
          // add fetched orders to the orders array starting from the right page offset
          newOrders.splice(currentPageOffset, fetchedOrders.length, ...fetchedOrders)
          return newOrders
        })
      }
      setLoading(false)
    } catch (err) {
      if (!isRequestCancel(err)) {
        alertNotification('Fetching orders history failed. Please contact support.', 'error')
      }
    }
  }

  useEffect(() => {
    setPage((prevPage) => ({ ...prevPage, loading: true }))
    fetchOrders(true)
  }, [query])

  useEffect(() => {
    if (page.current > 1 && !orders[currentPageOffset]) {
      fetchOrders()
    }
  }, [page.current])

  const handlePageChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    if (page.current !== value) {
      setPage((prevPage) => ({ ...prevPage, current: value }))
    }
  }

  const handleExpandChange = (panel: number) => (_event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
  }

  return (
    <S.StyledContainer maxWidth="xl" fixed>
      <Typography variant="h5" p="0 40px" mb="24px">
        Your Orders
      </Typography>
      <S.StyledPaper variant="card">
        <Box display="flex" alignItems="center" mb="64px">
          {orderFilters.map((filter) => (
            <FilterMenu
              key={filter.type.value}
              filter={filter}
              selectedFilters={selectedFilters}
              setSelectedFilters={setSelectedFilters}
              setQuery={setQuery}
            />
          ))}
        </Box>
        <Box mb="64px">
          {!loading &&
            Boolean(orders.length) &&
            orders
              .slice(currentPageOffset, currentPageOffset + LIMIT)
              .map(
                (order) =>
                  order && (
                    <Order
                      key={order.id}
                      order={order}
                      expanded={expanded === order.id}
                      handleChange={handleExpandChange}
                    />
                  )
              )}
          {!(loading || Boolean(orders.length)) && (
            <Box display="flex" justifyContent="center">
              <Typography variant="h6">No Orders</Typography>
            </Box>
          )}
          {loading && (
            <Box display="flex" justifyContent="center">
              <CircularProgress disableShrink />
            </Box>
          )}
        </Box>
        {Boolean(orders.length) && (
          <Box display="flex" justifyContent="center">
            <Pagination
              count={page.total}
              page={page.current}
              onChange={handlePageChange}
              color="primary"
              disabled={page.loading}
            />
          </Box>
        )}
      </S.StyledPaper>
    </S.StyledContainer>
  )
}

export default Orders
