import { Typography, Divider, Box, Button, IconButton, Alert, Link } from '@mui/material'
import { Container } from 'src/ui'
import React, { useEffect, useState } from 'react'
import { useWallet } from 'src/common/context'
import { ChainName } from 'src/common/static'
import { Modal } from 'src/ui'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import * as S from './styles'
import { WarningIcon } from '../../common/assets/WarningIcon'

const AddFundsFromBlockchainWallet: React.FC = () => {
  const { selectedWallet } = useWallet()

  interface WalletDetails {
    address: string | null
    chain: ChainName
  }

  const [open, setOpen] = useState(false)
  const [copied, setCopied] = useState(false)
  const [walletDetails, setWalletDetails] = useState<WalletDetails>({
    address: '',
    chain: ChainName.ALGORAND
  })

  const error = selectedWallet.address ? '' : 'Please choose a wallet first'

  const copyToClipboard = (address: string | null) => {
    navigator.clipboard.writeText(address ? address : '')
    setCopied(true)
  }

  const handleQROpen = (address: string | null, chain: ChainName) => {
    setWalletDetails({
      address: address,
      chain: chain
    })
    setOpen(true)
  }

  const handleQRClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    setTimeout(() => {
      setCopied(false)
    }, 5000)
  }, [copied])

  return (
    <>
      <Container error={error}>
        <S.Row>
          <Typography variant="h5" fontWeight={600}>
            Add funds
          </Typography>
        </S.Row>
        <Typography variant="h6" fontWeight={800} mb={2}>
          {selectedWallet.chain === ChainName.ALGORAND
            ? 'Algorand'
            : selectedWallet.chain === ChainName.ETHEREUM
            ? 'Ethereum'
            : 'Stellar'}{' '}
          network
        </Typography>
        <Typography variant="subtitle2" fontWeight={400} mb={2}>
          To transfer digital assets from an external wallet, please use the public address of your receiving wallet. To
          avoid errors, copy and paste the address or scan the QR code.
        </Typography>
        <Divider />
        <S.Row display={'flex'} justifyContent={'center'} m={2}>
          <WarningIcon style={{ height: '200px', width: '200px' }} />
        </S.Row>
        <Typography variant="subtitle2" fontWeight={400} mb={2}>
          Please note, Grapes will only display and provide transfer of assets supported on Grapes. Deposit supported
          assets on the selected network only. Deposits sent from unsupported networks may be lost.
        </Typography>
        <Button
          variant="contained"
          size="large"
          fullWidth
          onClick={() => handleQROpen(selectedWallet.address, selectedWallet.chain)}
          disabled={Boolean(error)}
        >
          I understand
        </Button>
        <Typography variant="subtitle2" textAlign="center" color="grey.500" mt={2}>
          For more information on supported assets and networks, please visit{' '}
          <Link
            component="a"
            href="https://grapesfinance.gitbook.io/grapes-academy/learn-web3/supported-assets"
            target="_blank"
            rel="noopener"
          >
            Grapes Academy.
          </Link>
        </Typography>
      </Container>
      <Modal open={open} onClose={() => handleQRClose()} title="Add funds" keepMounted>
        <Typography variant="h6" fontWeight={600} mb={2}>
          {walletDetails.chain === ChainName.ALGORAND
            ? 'Algorand'
            : walletDetails.chain === ChainName.ETHEREUM
            ? 'Ethereum'
            : 'Stellar'}{' '}
          network
        </Typography>
        <Typography variant="subtitle2" fontWeight={400} mb={2}>
          To transfer digital assets from an external wallet, please use the public address of your receiving wallet. To
          avoid errors, copy and paste the address or scan the QR code.
        </Typography>
        <Divider />
        <Box display="flex" justifyContent="center">
          {walletDetails.address && <S.CustomQRCode value={walletDetails.address} size={200} />}
        </Box>
        <Box display="flex" alignItems="baseline" justifyContent="space-between" pb="14px">
          <Typography fontWeight={500}>
            {walletDetails.chain === 'ALGORAND' ? 'ALGO' : walletDetails.chain === ChainName.ETHEREUM ? 'ETH' : 'XLM'}{' '}
            address:
          </Typography>
          {copied && (
            <Alert
              iconMapping={{
                success: <CheckCircleOutlineIcon fontSize="small" />
              }}
            >
              Copied!
            </Alert>
          )}
          {!copied && (
            <IconButton
              aria-label="copy"
              onClick={() => copyToClipboard(walletDetails.address ? walletDetails.address : null)}
            >
              <ContentCopyIcon />
            </IconButton>
          )}
        </Box>
        <Box>
          <S.StyledWalletAddress mb={2}>{walletDetails.address}</S.StyledWalletAddress>
        </Box>
        <S.Row>
          <Button href="/" variant="contained" fullWidth size="large">
            Return to Dashboard
          </Button>
        </S.Row>
        <Typography variant="subtitle2" textAlign="center" color="grey.500">
          Please note, Grapes will only display and provide transfer of supported digital assets on Grapes. Deposit
          assets on the selected network only. Deposits sent from unsupported networks may be lost.
        </Typography>
      </Modal>
    </>
  )
}

export default AddFundsFromBlockchainWallet
