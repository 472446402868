import React from 'react'
import {
  Box,
  Button,
  Divider,
  InputBaseComponentProps,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  Typography,
  IconButton
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { Container, Modal, SummaryTable } from 'src/ui'
import NumberFormat from 'react-number-format'
import useNonCustodial from './NonCustodial.state'
import * as S from './styles'
import { ExpandMore, NavigateNext, RemoveCircleOutline } from '@mui/icons-material'
import { ChainAsset, ChainName } from 'src/common/static'
import { CHAIN_ASSET_ICON } from 'src/common/static/chain/asset-icon'
import { NewContactIcon, QCADIcon, USDCIcon } from 'src/common/assets'
import { formatNumber, trimId } from 'src/common/helpers'

const assets = [
  {
    name: ChainAsset.QCAD,
    Icon: QCADIcon
  },
  {
    name: ChainAsset.USDC,
    Icon: USDCIcon
  }
]

const NonCustodial: React.FC = () => {
  const {
    value,
    recipient,
    search,
    filteredList,
    selectedContact,
    errors,
    asset,
    selectedWallet,
    selectedWalletBalance,
    anchorElAsset,
    loading,
    success,
    open,
    manual,
    handleRequest,
    handleCloseAssetMenu,
    handleChangeRecipient,
    handleSelectRecipient,
    handleRemoveRecipient,
    handleAmountChange,
    chooseAsset,
    handleOpenAssetMenu,
    handleSearch,
    handleOpenBlockchain,
    handleCloseBlockchain,
    handleManual,
    handleContacts
  } = useNonCustodial()

  const CurrentAssetIcon = CHAIN_ASSET_ICON[asset]
  const assetBalance =
    selectedWallet.chain == ChainName.ETHEREUM
      ? selectedWalletBalance && selectedWalletBalance[asset === ChainAsset.QCAD ? 'qcad' : 'usdc']
      : selectedWalletBalance && selectedWalletBalance[asset === ChainAsset.QCAD ? 'qcad_xlm' : 'usdc_xlm']

  return (
    <Container success={success}>
      <Stack spacing={3}>
        <Typography variant="h5" fontWeight={600}>
          {manual || recipient ? 'Send' : 'Please Select a Recipient'}
        </Typography>
        {!manual && !recipient && (
          <Box>
            <S.StyledButton startIcon={<NewContactIcon />} variant="outlined" href="/address-book">
              Add a New Recipient
            </S.StyledButton>
            <Divider sx={{ my: 2 }} />
            <TextField
              value={search}
              onChange={handleSearch}
              name="filter"
              placeholder="Search for a recipient..."
              fullWidth
              size="small"
            />
            <List
              sx={{
                position: 'relative',
                overflow: 'auto',
                maxHeight: 400
              }}
            >
              {filteredList.map((recipient, idx) => (
                <ListItem key={idx}>
                  <ListItemButton onClick={() => handleOpenBlockchain(recipient.contactDetails.id)}>
                    <ListItemText
                      primary={`${recipient.contactDetails.firstName} ${recipient.contactDetails.lastName}`}
                    />
                    <NavigateNext />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
            <Box display="flex" justifyContent="center">
              <S.StyledButton variant="outlined" onClick={handleManual}>
                Manually input blockchain address
              </S.StyledButton>
            </Box>
          </Box>
        )}
        {(manual || (recipient && selectedContact)) && (
          <Stack spacing={3}>
            <Box>
              <S.WalletBalance>
                Balance: {assetBalance?.toString() ? formatNumber(assetBalance as number) : 'not opted-in'}
              </S.WalletBalance>
              <Typography mb="12px">Asset</Typography>
              <TextField
                value={value}
                onChange={handleAmountChange}
                name="asset"
                placeholder="0"
                error={Boolean(errors.inputError)}
                helperText={errors.inputError}
                InputProps={{
                  inputComponent: NumberFormatCustom as unknown as React.ElementType<InputBaseComponentProps>,
                  endAdornment: (
                    <S.StyledInputAdornment position="end">
                      <Button
                        size="small"
                        startIcon={<CurrentAssetIcon />}
                        endIcon={<ExpandMore />}
                        color="inherit"
                        onClick={handleOpenAssetMenu}
                      >
                        {ChainAsset[asset]}
                      </Button>
                      <Menu
                        id="menu-asset-type"
                        anchorEl={anchorElAsset}
                        keepMounted
                        open={Boolean(anchorElAsset)}
                        onClose={handleCloseAssetMenu}
                      >
                        {assets.map(({ name, Icon }) => (
                          <MenuItem
                            key={name}
                            onClick={() => chooseAsset(name)}
                            disabled={name === ChainAsset.QCAD && selectedWallet.chain === ChainName.ALGORAND}
                          >
                            <ListItemIcon>
                              <Icon fontSize="small" />
                            </ListItemIcon>
                            <Typography>{name}</Typography>
                          </MenuItem>
                        ))}
                      </Menu>
                    </S.StyledInputAdornment>
                  )
                }}
                fullWidth
                size="medium"
              />
            </Box>
            {manual && (
              <Box>
                <Typography mb="12px">Recipient</Typography>
                <TextField
                  value={recipient}
                  name="recipient"
                  fullWidth
                  size="medium"
                  placeholder="Blockchain Address"
                  onChange={handleChangeRecipient}
                />
              </Box>
            )}
            {!manual && (
              <Box>
                <Typography mb="12px">Recipient</Typography>
                <TextField
                  value={`${selectedContact.contactDetails.firstName} ${
                    selectedContact.contactDetails.lastName
                  } (${trimId(recipient)})`}
                  name="recipient"
                  fullWidth
                  size="medium"
                  disabled
                  InputProps={{
                    endAdornment: (
                      <S.StyledInputAdornment position="end">
                        <IconButton onClick={handleRemoveRecipient}>
                          <RemoveCircleOutline color="error" />
                        </IconButton>
                      </S.StyledInputAdornment>
                    )
                  }}
                />
              </Box>
            )}
            {!manual && (
              <Box>
                <S.Summary>Recipient Details</S.Summary>
                <SummaryTable
                  data={[
                    {
                      key: 'Recipient',
                      value: `${selectedContact.contactDetails.firstName} ${selectedContact.contactDetails.lastName}`
                    },
                    {
                      key: 'Address Nickname',
                      value: `${
                        selectedContact.blockchainDetails.filter((wallet) => wallet.address === recipient)[0].name
                      }`
                    },
                    {
                      key: 'Blockchain Address',
                      value: trimId(recipient)
                    },
                    {
                      key: 'Chain',
                      value: selectedContact.blockchainDetails.filter((wallet) => wallet.address === recipient)[0].chain
                    }
                  ]}
                />
              </Box>
            )}
            <Box>
              <S.Summary>Summary</S.Summary>
              <SummaryTable
                data={[
                  {
                    key: 'Sender Blockchain Address',
                    value: trimId(selectedWallet.address)
                  },
                  {
                    key: 'Chain',
                    value: selectedWallet.chain
                  },
                  {
                    key: 'Order fee',
                    value: '$0.00'
                  },
                  {
                    key: 'Total',
                    value: `${value}  ${asset}`
                  }
                ]}
              />
            </Box>
            <LoadingButton
              variant="contained"
              size="large"
              loading={loading}
              fullWidth
              onClick={handleRequest}
              disabled={Boolean(errors.inputError) || Number(value) === 0 || !recipient}
            >
              Send Assets
            </LoadingButton>
            {manual && (
              <Box display="flex" justifyContent="center">
                <S.StyledButton variant="outlined" onClick={handleContacts}>
                  Choose recipient from address book
                </S.StyledButton>
              </Box>
            )}
          </Stack>
        )}
      </Stack>
      <Modal
        open={open}
        onClose={handleCloseBlockchain}
        title="Beneficiary Blockchain Addresses"
        maxWidth="md"
        fullWidth
        keepMounted
      >
        <Typography variant="subtitle2" color="grey.500" mb={2}>
          Please choose from the following addresses to send to
        </Typography>
        <Divider sx={{ marginBottom: '24px' }} />
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Address Nickname</TableCell>
                <TableCell>Blockchain Address</TableCell>
                <TableCell>Blockchain Network</TableCell>
                <TableCell>Supported Assets</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            {selectedContact &&
              selectedContact.blockchainDetails.map((wallet, idx) => (
                <TableBody key={idx}>
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell component="th" scope="row">
                      {wallet.name}
                    </TableCell>
                    <TableCell>{trimId(wallet.address)}</TableCell>
                    <TableCell>{wallet.chain}</TableCell>
                    <TableCell>
                      <QCADIcon fontSize="small" /> <USDCIcon fontSize="small" />
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleSelectRecipient(wallet.address)}>
                        <NavigateNext />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                </TableBody>
              ))}
          </Table>
          {selectedContact && !selectedContact.blockchainDetails.length && (
            <Box display="flex" alignItems="center" justifyContent="center" mt={4}>
              <Typography variant="h6">Recipient has no blockchain addresses</Typography>
            </Box>
          )}
        </TableContainer>
      </Modal>
    </Container>
  )
}
interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
}

const NumberFormatCustom = React.forwardRef<NumberFormat<Record<string, unknown>>, CustomProps>((props, ref) => {
  const { onChange, ...other } = props

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        })
      }}
      thousandSeparator
      isNumericString
      allowNegative={false}
      decimalScale={2}
      fixedDecimalScale
    />
  )
})

NumberFormatCustom.displayName = 'NumberFormatCustom'

export default NonCustodial
