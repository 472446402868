import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import QRCodeCanvas from 'qrcode.react'

export const Row = styled('div')({
  marginBottom: '24px'
})

export const Step = styled('li')({})

export const StepsList = styled('ol')({
  fontWeight: 'normal',
  fontSize: '14px',
  lineHeight: '115%',
  textAlign: 'left',
  marginTop: '22px',
  listStyleType: 'decimal',
  listStylePosition: 'inside'
})

export const CustomQRCode = styled(QRCodeCanvas)({
  margin: '30px',
  marginLeft: '124px'
})

export const InputLabel = styled(Typography)({
  marginBottom: '12px',
  fontWeight: 600
})
