import { Avatar, Button, DialogProps, Divider, Stack, Typography } from '@mui/material'
import React from 'react'
import { DollarIcon, TransferIcon } from 'src/common/assets'
import { Modal } from 'src/ui'

interface ConfirmProps extends DialogProps {
  handleConfirm: () => void
}

const Confirm: React.FC<ConfirmProps> = ({ handleConfirm, ...props }: ConfirmProps) => {
  return (
    <Modal {...props} title="Please transfer your funds" maxWidth="sm">
      <Divider sx={{ mb: 4 }} />
      <Stack spacing={3}>
        <Stack direction="row" spacing={3}>
          <Stack spacing={3}>
            <Avatar sx={(theme) => ({ backgroundColor: theme.palette.primary[99], width: 56, height: 56 })}>
              <TransferIcon />
            </Avatar>
            <Typography fontWeight={500}>Initiate a Bill Payment</Typography>
            <Typography color="grey.500">
              If you haven’t already, please initiate a Bill Payment to the bank account details listed on the previous
              steps.
            </Typography>
          </Stack>
          <Stack spacing={3}>
            <Avatar sx={(theme) => ({ backgroundColor: theme.palette.primary[99], width: 56, height: 56 })}>
              <DollarIcon />
            </Avatar>
            <Typography fontWeight={500}>Receive digital currencies</Typography>
            <Typography color="grey.500">
              Once we receive the funds, we will create the corresponding amount of digital currency and add to your
              grapes account.
            </Typography>
          </Stack>
        </Stack>
        <Typography variant="subtitle2" color="grey.500">
          Note: Canadian Imperial Bank of Commerce (CIBC) is currently not supported with BillPay
        </Typography>
        <Button variant="contained" size="large" onClick={handleConfirm}>
          Done
        </Button>
        <Typography variant="subtitle2" textAlign="center">
          Your bank (and perhaps intermediary banks) might charge fees for processing Bill Payment. Those fees are out
          of our control and you are responsible for them. If you are transferring funds from a non-Canadian Dollar bank
          account, you may also incur FX fees. Grapes also doesn&apos;t control the FX rates practiced by banks in the
          case of cross-currency Bill Payments. For information about converting into digital dollars, including
          estimated timelines, please visit Grapes Academy.
        </Typography>
      </Stack>
    </Modal>
  )
}

export default Confirm
