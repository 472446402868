import { Button, DialogProps, Divider, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { getProfile } from 'src/common/api'
import { alertNotification, Modal, SummaryTable } from 'src/ui'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { FiatCurrency } from '../../../../static'

interface DetailsProps extends DialogProps {
  handleDetails: () => void
  amount: string
  fiatCurrency: FiatCurrency
}

const Details: React.FC<DetailsProps> = ({ handleDetails, amount, fiatCurrency, ...props }: DetailsProps) => {
  const [apayloId, setApayloId] = useState('')
  const [padOpen, setPadOpen] = useState(false)

  const getApayloId = async () => {
    try {
      const response = await getProfile()
      setApayloId(`APL3939${response.data.accountId}`)
    } catch (err) {
      alertNotification('Fetching Apaylo account ID failed. Please contact support.', 'error')
    }
  }

  useEffect(() => {
    getApayloId()
  }, [])

  const displayDetails = () => {
    const details = [
      {
        key: 'Payee Name',
        value: 'APAYLO FINANCE TECHNOLOGY'
      },
      {
        key: 'Per Transaction Limit',
        value: '$10,000 CAD'
      },
      {
        key: 'Account Number',
        value: apayloId
      },
      {
        key: 'Deposit Amount',
        value: `$${amount} ${fiatCurrency}`
      }
    ]

    return (
      <SummaryTable
        data={details.map((detail) => ({
          key: <Typography fontWeight={500}>{detail.key}</Typography>,
          value: <Typography>{detail.value || 'Pending'}</Typography>
        }))}
        padding="16px 0"
      />
    )
  }

  return (
    <Modal {...props} title="BillPay">
      <Stack spacing={3}>
        <Typography variant="subtitle2" color="grey.500">
          Please deposit the amount to the following payee account. Make sure the funds match the stated CAD amount.
        </Typography>
        <div style={{ display: 'flex' }}>
          <Typography variant="subtitle2">Instructions on how to conduct a bill pay</Typography>
          <Button
            onClick={() => setPadOpen(!padOpen)}
            style={{ background: 'transparent', color: 'black', padding: '0px' }}
          >
            {padOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </Button>
        </div>
        {padOpen ? (
          <Typography variant="subtitle2" fontWeight={400}>
            1. Login to their financial instituion and navigate to the Bill Payment Section
            <br />
            <br />
            2. Search for and add <b>&quot;APAYLO FINANCE TECHNOLOGY&quot;</b>
            <br />
            <br />
            3. Add a nickname, so they remember who they are sending funds to. <b>(Optional)</b>
            <br />
            <br />
            4. Insert their Account Number and save the Bill Payee information.
            <br />
            <br />
            5. When going to send funds, they will select this Payee and insert the funds they wish to send.
          </Typography>
        ) : null}
        <Divider />
        {displayDetails()}
        <Typography variant="subtitle2" color="grey.500">
          Note: Canadian Imperial Bank of Commerce (CIBC) is currently not support with BillPay
        </Typography>
        <Button variant="contained" size="large" onClick={() => handleDetails()}>
          Next
        </Button>
        <Typography variant="subtitle2" textAlign="center" color="grey.500">
          Please note that the quoted deposited amount and conversion rate is only valid for 24 hours. Deposits made
          past the 24 hour period will be subject to different FX currency rates. For more information, please visit{' '}
          <a href="https://grapesfinance.gitbook.io/grapes-academy">Grapes Academy.</a>
        </Typography>
      </Stack>
    </Modal>
  )
}

export default Details
