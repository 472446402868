import * as React from 'react'
import { Typography } from '@mui/material'
import * as S from './styles'

const ConnectWalletNotification: React.FC = () => {
  return (
    <S.OuterContainer>
      <S.StyledToolbar>
        <Typography variant="h6" flexGrow={1} textAlign="center">
          To be able to purchase/redeem digital currencies, please connect a wallet.
        </Typography>
      </S.StyledToolbar>
    </S.OuterContainer>
  )
}

export default ConnectWalletNotification
