import React, { useEffect, useState } from 'react'
import { Box, Button, Divider, InputBaseComponentProps, Stack, TextField, Typography } from '@mui/material'
import * as S from './styles'
import { getProfile } from 'src/common/api'
import { alertNotification } from 'src/ui'
import { IMaskInput } from 'react-imask'
import { isEmailValid } from 'src/common/helpers'
import { LoadingButton } from '@mui/lab'

interface InputErrors {
  email?: string
  phone?: string
}

const PaymentMethod: React.FC = () => {
  const [etransfer, setEtransfer] = useState({ email: '', phone: '' })
  const [isEdit, setIsEdit] = useState(false)
  const [values, setValues] = useState({ email: '', phone: '' })
  const [errors, setErrors] = useState<InputErrors>({
    email: undefined,
    phone: undefined
  })
  const [loading, setLoading] = useState(true)

  const getEtransferDetails = async () => {
    try {
      const response = await getProfile()
      const details = response.data.bankDetails
      if (details.length) {
        const accountNum = details.filter((b) => b.isPrimary)[0].accountNum
        setEtransfer({ email: accountNum, phone: accountNum })
        setValues({ email: accountNum, phone: accountNum })
      }
      setLoading(false)
    } catch (err) {
      alertNotification('Fetching e-transfer details failed. Please contact support.', 'error')
    }
  }

  useEffect(() => {
    getEtransferDetails()
  }, [])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name
    const newValue = event.target.value
    if (name === 'email' && isEmailValid(newValue)) {
      setErrors((prevErrors) => ({ ...prevErrors, email: undefined }))
    }
    if (name === 'phone' && newValue.length === 10) {
      setErrors((prevErrors) => ({ ...prevErrors, phone: undefined }))
    }
    setValues({ ...values, [name]: newValue })
  }

  const saveDetails = async () => {
    try {
      setLoading(true)
      // await saveProfile({ accountId: values.email })
      setEtransfer({ ...values, phone: `+1${values.phone}` })
      setLoading(false)
    } catch (err) {
      alertNotification()
    }
  }

  const handleOnClick = async () => {
    const err: InputErrors = {}

    if (!isEmailValid(values.email)) {
      err.email = 'Invalid Email Address'
    }
    if (values.phone.length !== 10) {
      err.phone = 'Invalid Phone Number'
    }
    setErrors(err)
    if (!Object.keys(err).length) {
      await saveDetails()
    }
    setIsEdit(false)
  }

  return (
    <>
      <Stack direction="row" my="48px" justifyContent="space-between" alignItems="center">
        <Typography variant="h5" fontWeight={500}>
          Payment Methods
        </Typography>
        {isEdit ? (
          <Stack direction="row" alignItems="center" spacing={2}>
            <Button size="small" onClick={() => setIsEdit(false)}>
              Cancel
            </Button>
            <LoadingButton variant="contained" size="small" onClick={handleOnClick} loading={loading}>
              Save
            </LoadingButton>
          </Stack>
        ) : (
          <Button size="small" onClick={() => setIsEdit(true)}>
            Edit
          </Button>
        )}
      </Stack>
      <Divider />
      <S.Flex>
        <Box>
          <Typography fontWeight={500}>E-transfer Email</Typography>
        </Box>
        {isEdit ? (
          <TextField
            value={values.email}
            onChange={handleChange}
            name="email"
            placeholder="Email"
            error={Boolean(errors.email)}
            helperText={errors.email}
            disabled={loading}
          />
        ) : (
          <Typography>{etransfer.email || 'unprovided'}</Typography>
        )}
      </S.Flex>
      <Divider />
      <S.Flex>
        <Box>
          <Typography fontWeight={500}>Phone Number</Typography>
        </Box>
        {isEdit ? (
          <TextField
            value={values.phone}
            onChange={handleChange}
            name="phone"
            placeholder="Phone Number"
            error={Boolean(errors.phone)}
            helperText={errors.phone}
            disabled={loading}
            InputProps={{ inputComponent: TextMaskCustom as unknown as React.ElementType<InputBaseComponentProps> }}
          />
        ) : (
          <Typography>{etransfer.phone || 'unprovided'}</Typography>
        )}
      </S.Flex>
      <Divider />
    </>
  )
}

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
}

const TextMaskCustom = React.forwardRef<HTMLInputElement, CustomProps>((props, ref) => {
  const { onChange, ...other } = props
  return (
    <IMaskInput
      {...other}
      mask="+1 (#00) 000-0000"
      definitions={{
        '#': /[1-9]/
      }}
      unmask
      inputRef={ref as never}
      onAccept={(value) => onChange({ target: { name: props.name, value: value as string } })}
      overwrite
    />
  )
})

TextMaskCustom.displayName = 'TextMaskCustom'

export default PaymentMethod
