import { LoadingButton } from '@mui/lab'
import { Button, DialogProps, Menu, MenuItem, Stack, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { createUserNewBank, getProfile } from 'src/common/api'
import { BankDetailsModel } from 'src/common/api/contacts'
import { FlagIcon, USFlagIcon } from 'src/common/assets'
import {
  ValidationError,
  isAchCodeValid,
  isInstitutionNumValid,
  isSwiftBicCodeValid,
  isTransitNumValid
} from 'src/common/helpers'
import { BankingCountry, RequestType } from 'src/common/static'
import { alertNotification, Modal, SummaryTable } from 'src/ui'

interface DetailsProps extends DialogProps {
  handleDetails: (selectedBank: BankDetailsModel) => void
  type: RequestType
  bankCountry: BankingCountry
}

const Details: React.FC<DetailsProps> = ({ handleDetails, type, bankCountry, ...props }: DetailsProps) => {
  const [values, setValues] = useState({
    country: bankCountry as string,
    name: '',
    accHolderName: '',
    institutionNum: '',
    transitNum: '',
    routingNum: '',
    accountNum: '',
    achCode: '',
    swiftBicCode: '',
    isPrimary: true
  })
  const noErrors = {
    name: false,
    accHolderName: false,
    institutionNum: false,
    transitNum: false,
    accountNum: false,
    achCode: false,
    swiftBicCode: false
  }
  const [errors, setErrors] = useState(noErrors)
  const [anchorElCountry, setAnchorElCountry] = useState<null | HTMLElement>(null)
  const [detailsNotSaved, setDetailsNotSaved] = useState(false)
  const [loading, setLoading] = useState(true)
  const [selectedBank, setSelectedBank] = useState<BankDetailsModel | undefined>(undefined)

  const isPurchase = type === RequestType.PURCHASE
  const isCanadaBank = values.country === BankingCountry.CAN
  const isUSBank = values.country === BankingCountry.US
  const isValid =
    values.accHolderName &&
    values.name &&
    ((isCanadaBank && values.institutionNum && values.transitNum) ||
      (isUSBank && values.achCode && values.swiftBicCode)) &&
    values.accountNum

  const getDetails = async () => {
    try {
      const response = await getProfile()
      const details = response.data.bankDetails
      if (details.length) {
        const bank = details.find((b) => b.isPrimary)
        if (bank) {
          setValues({
            country: bank.country,
            name: bank.name,
            accHolderName: bank.accHolderName,
            institutionNum: bank.routingNum.substring(0, 3),
            transitNum: bank.routingNum.substring(4),
            routingNum: bank.routingNum,
            accountNum: bank.accountNum,
            achCode: bank.achCode,
            swiftBicCode: bank.swiftBicCode,
            isPrimary: bank.isPrimary
          })
          setSelectedBank(bank)
        }
      } else {
        setDetailsNotSaved(true)
      }
      setLoading(false)
    } catch (err) {
      alertNotification('Fetching banking details failed. Please contact support.', 'error')
    }
  }

  useEffect(() => {
    getDetails()
  }, [])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name
    const newValue = event.target.value
    setValues({ ...values, [name]: newValue })
  }

  const validate = () => {
    if (!isValid) {
      setErrors({
        ...noErrors,
        name: !values.name,
        accHolderName: !values.accHolderName,
        institutionNum: !values.institutionNum,
        transitNum: !values.transitNum,
        accountNum: !values.accountNum,
        achCode: !values.achCode,
        swiftBicCode: !values.swiftBicCode
      })
      throw new ValidationError('Please fill in required fields.')
    } else if (isCanadaBank && !isInstitutionNumValid(values.institutionNum)) {
      setErrors({
        ...noErrors,
        institutionNum: true
      })
      throw new ValidationError('Invalid institution num. Format: 3 digits')
    } else if (isCanadaBank && !isTransitNumValid(values.transitNum)) {
      setErrors({
        ...noErrors,
        transitNum: true
      })
      throw new ValidationError('Invalid transit num. Format: 5 digits')
    } else if (isUSBank && !isAchCodeValid(values.achCode)) {
      setErrors({
        ...noErrors,
        achCode: true
      })
      throw new ValidationError('Invalid ACH code. Format: 9 digits')
    } else if (isUSBank && !isSwiftBicCodeValid(values.swiftBicCode)) {
      setErrors({
        ...noErrors,
        swiftBicCode: true
      })
      throw new ValidationError('Invalid Swift BIC code. Format: min 8 digits, max 11 digits')
    }
  }

  const saveDetails = async () => {
    try {
      setLoading(true)
      validate()
      const response = await createUserNewBank({
        bankDetails: [
          {
            country: values.country,
            accHolderName: values.accHolderName,
            routingNum: `${values.institutionNum}-${values.transitNum}`,
            accountNum: values.accountNum,
            achCode: values.achCode,
            swiftBicCode: values.swiftBicCode
          }
        ]
      })
      setLoading(false)
      const bank = response.data.bankDetails.find((b) => b.accountNum === values.accountNum)
      if (bank) {
        setSelectedBank(bank)
        handleDetails(bank)
      }
      if (response) {
        alertNotification('Bank details saved to the user profile.', 'success')
      }
    } catch (err) {
      if (err instanceof ValidationError) {
        alertNotification(err.message, 'error')
      } else {
        alertNotification('Updating banking details on profile failed. Please contact support.', 'error')
      }
      setLoading(false)
    }
  }

  const handleOnClick = async () => {
    if (detailsNotSaved) {
      await saveDetails()
    } else if (selectedBank) {
      handleDetails(selectedBank)
    }
  }

  const handleOpenCountryMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElCountry(event.currentTarget)
  }

  const handleCloseCountryMenu = () => {
    setAnchorElCountry(null)
  }

  const chooseCountry = (country: BankingCountry) => {
    setValues({
      ...values,
      country: country
    })
    handleCloseCountryMenu()
  }

  return (
    <Modal {...props} title="Banking Details">
      <Stack spacing={3}>
        <Typography variant="subtitle2" color="grey.500">
          This information will be required to complete the {isPurchase ? 'purchase' : 'send'} via Electronic Funds
          Transfer.
        </Typography>
        <SummaryTable
          data={[
            {
              key: <Typography fontWeight={500}>Country</Typography>,
              value: (
                <Button
                  startIcon={
                    values.country === BankingCountry.CAN ? (
                      <FlagIcon fontSize="small" />
                    ) : (
                      <USFlagIcon fontSize="small" />
                    )
                  }
                  color="primary"
                  onClick={(e) => handleOpenCountryMenu(e)}
                  size="small"
                  disabled
                >
                  {values.country}
                </Button>
              )
            },
            {
              key: <Typography fontWeight={500}>Account Holder Name:</Typography>,
              value: (
                <TextField
                  value={values.accHolderName}
                  onChange={handleChange}
                  name="accHolderName"
                  placeholder="Account Holder Name"
                  error={errors.accHolderName}
                />
              )
            },
            {
              key: <Typography fontWeight={500}>Bank Name:</Typography>,
              value: <TextField value={values.name} onChange={handleChange} name="name" placeholder="Bank Name" />
            },
            {
              key: <Typography fontWeight={500}>{isCanadaBank ? 'Institution Number:' : 'ACH Code:'}</Typography>,
              value: (
                <TextField
                  value={isCanadaBank ? values.institutionNum : values.achCode}
                  onChange={handleChange}
                  name={isCanadaBank ? 'institutionNum' : 'achCode'}
                  placeholder={isCanadaBank ? 'ex: 010' : 'ex: 123456789'}
                  error={isCanadaBank ? errors.institutionNum : errors.achCode}
                />
              )
            },
            {
              key: <Typography fontWeight={500}>{isCanadaBank ? 'Transit Number:' : 'SWIFT/BIC Code:'}</Typography>,
              value: (
                <TextField
                  value={isCanadaBank ? values.transitNum : values.swiftBicCode}
                  onChange={handleChange}
                  name={isCanadaBank ? 'transitNum' : 'swiftBicCode'}
                  placeholder={isCanadaBank ? 'ex: 00002' : 'ex: 12345678'}
                  error={isCanadaBank ? errors.transitNum : errors.swiftBicCode}
                />
              )
            },
            {
              key: <Typography fontWeight={500}>Account Number:</Typography>,
              value: (
                <TextField
                  value={values.accountNum}
                  onChange={handleChange}
                  name="accountNum"
                  placeholder="ex: 1234567"
                />
              )
            }
          ]}
          padding="16px 0"
        />
        <Typography variant="subtitle2" color="grey.500">
          If you exceed your limits, your account will be suspended and funds returned.
        </Typography>
        <LoadingButton variant="contained" size="large" disabled={!isValid} onClick={handleOnClick} loading={loading}>
          {detailsNotSaved ? 'Save & Continue' : 'Next'}
        </LoadingButton>
        <Typography variant="subtitle2" textAlign="center">
          Please ensure all bank account information is accurate. Non-accurate information can result in a delay of your{' '}
          {isPurchase ? 'purchase' : 'send'} and may incur bank fees.
        </Typography>
      </Stack>
      <Menu
        sx={{ mt: '7px' }}
        id="menu-country"
        anchorEl={anchorElCountry}
        keepMounted
        open={Boolean(anchorElCountry)}
        onClose={handleCloseCountryMenu}
      >
        {Object.values(BankingCountry).map((country) => (
          <MenuItem key={country} onClick={() => chooseCountry(country)}>
            <Typography>{country}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </Modal>
  )
}

export default Details
