import httpClient from 'src/common/axios/config'
import { UserProfileDTO, KycQuestionsDTO, UserProfileNewBankDTO, UserProfileDeleteBankDTO } from './dto'
import { UserModel } from './models'

export const createUser = () => httpClient.post('/users')

export const updateUserKYC = (params: { kycId: string }) => httpClient.put<{ kycId: string }>('/users', params)

export const requestLimitIncrease = () => httpClient.post<boolean>('/users/transaction-limit', { newLimit: 500000 })

export const saveProfile = (params: UserProfileDTO) => httpClient.put('/users/profile', params)

export const createUserNewBank = (params: UserProfileNewBankDTO) =>
  httpClient.post<UserModel>('/users/profile/bankDetails', params)

export const deleteUserBank = (params: UserProfileDeleteBankDTO) =>
  httpClient.delete('/users/profile/bankDetails', { params })

export const getProfile = () => httpClient.get<UserModel>('/users/profile')

export const saveKycQuestions = (params: KycQuestionsDTO) => httpClient.put('/users/occupation', params)

export const sendMFAEnabledNotification = () => httpClient.post<boolean>('/users/mfa-enabled')
