import { Typography } from '@mui/material'
import { Box, styled } from '@mui/system'
import QRCodeCanvas from 'qrcode.react'

export const Flex = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
})

export const CustomQRCode = styled(QRCodeCanvas)({
  margin: '50px'
})

export const Row = styled(Box)({
  marginBottom: '24px'
})

export const StyledWalletAddress = styled(Typography)({
  overflowWrap: 'anywhere'
})
