import * as lab from '@mui/lab'
import { Link, Typography, DialogProps, Alert } from '@mui/material'
import { useAuth } from '../../common/context/auth'
import React, { useEffect, useState } from 'react'
import { Modal } from 'src/ui'
import Mfa from './Mfa'
import { convertUserAttributesListToLabels } from '../../common/static'
import * as S from './styles'

interface State {
  error?: string
  success?: string
  verifyMfa: boolean
}

interface SetupMfaProps extends DialogProps {
  open: boolean
}

const SetupMfa: React.FC<SetupMfaProps> = ({ open, ...props }: SetupMfaProps) => {
  const [state, setState] = useState<State>({
    error: undefined,
    success: undefined,
    verifyMfa: false
  })
  const [qrcode, setQRCode] = useState('')
  const { tempUser, setupTOTP } = useAuth()

  const [modals, setModals] = useState({
    verifyMfa: false
  })

  const handleOpen = (modal: string) => {
    setModals((s) => ({ ...s, [modal]: true }))
  }

  useEffect(() => {
    const getQRCode = async (email: string): Promise<void> => {
      try {
        if (!tempUser) return
        const code = await setupTOTP()
        setQRCode(
          `otpauth://totp/${decodeURI(
            process.env.REACT_APP_API_URL?.includes('staging') || process.env.REACT_APP_API_URL?.includes('localhost')
              ? 'Grapes Staging'
              : 'Grapes'
          )}:${decodeURI(email)}?secret=${code}&issuer=${
            process.env.REACT_APP_API_URL?.includes('staging') || process.env.REACT_APP_API_URL?.includes('localhost')
              ? 'GrapesStaging'
              : 'Grapes'
          }`
        )
      } catch (error) {
        setState({ error: (error as Error).message, verifyMfa: false })
      }
    }
    tempUser?.getUserAttributes((err, result) => {
      if (result) {
        const email = convertUserAttributesListToLabels(result).email
        getQRCode(email)
      }
    })
  }, [tempUser])

  const handleNextMfaStep = (): void => {
    handleOpen('verifyMfa')
    setState({ verifyMfa: true })
  }

  return open ? (
    <>
      <Modal open={!state.verifyMfa} {...props} title="Setup 2-factor Authentication">
        {state.error && <Alert severity="error">{state.error}</Alert>}
        <S.Row>
          <Typography variant="body2">
            For better security please follow the instructions below to set up your 2-factor authentication
          </Typography>
        </S.Row>
        <S.StepsList>
          <S.Step>
            Download{' '}
            <Link
              component="a"
              href="https://support.google.com/accounts/answer/1066447"
              target="_blank"
              rel="noopener"
            >
              authenticator
            </Link>{' '}
            to your phone
          </S.Step>
          <S.Step>
            Open the app and click <strong>&quot;begin setup&quot;</strong>
          </S.Step>
          <S.Step>
            Choose <strong>Scan QR code</strong>
          </S.Step>
        </S.StepsList>
        {qrcode && <S.CustomQRCode value={qrcode} size={150} />}
        <S.Row>
          <lab.LoadingButton variant="contained" size="large" onClick={handleNextMfaStep} fullWidth>
            Continue
          </lab.LoadingButton>
        </S.Row>
      </Modal>
      {modals.verifyMfa && <Mfa open={modals.verifyMfa} />}
    </>
  ) : (
    <></>
  )
}

export default SetupMfa
